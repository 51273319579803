import React, { useEffect, useState } from "react";
import "@smile_identity/smart-camera-web";
import axios from "axios";
import CryptoJS from "crypto-js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useAuth } from "../../core/Auth";
import { getAllData, getErrorMessage, reset, uploadSelfieDoc } from "./api";
import { useNavigate } from "react-router-dom";
import { useFormCompletion } from "./Stepper";
import { converBase64ToImage } from "convert-base64-to-image";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

const SmileId = () => {
  const API_URL = process.env.REACT_APP_CALLBACK;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cameraKey, setCameraKey] = useState(Date.now());

  const { regUser, setRegUser } = useAuth();
  const { markFormCompleted } = useFormCompletion();

  useEffect(() => {
    reset(regUser?.token ?? "");
    getAllData(regUser?.token ?? "").then((res) => {
      setRegUser((prevState) => ({
        ...prevState,
        ...res.data.message,
      }));
    });
  }, []);

  const app = document.querySelector("smart-camera-web");

  const prepUpload = async (convertedFile: any) => {
    const data = {
      callback_url: `${API_URL}/b2b/webhook/smileid/updateLivelinessCheck`,
      // callback_url:"https://webhook.site/429605db-95e9-4585-b7ae-8a2e39d9a13f",
      model_parameters: {},
      partner_params: {
        job_id: (regUser?.appId ?? +new Date().getTime()).toString(),
        job_type: 1,
        user_id: `${regUser?.businessGuid}_${Date.now()}`,
        business_guid: regUser?.businessGuid,
        isd_code: regUser?.isdCode?.toString(),
        mobile_number: regUser?.mobile,
        tenant_id: regUser?.tenantId,
        app_type: "WEB",
      },
      signature: generateSignature(),
      smile_client_id: "2457",
      source_sdk_version: "1.0.0",
      source_sdk: "rest_api",
      timestamp: `${new Date().toISOString().split("T")[0]}T17:57:00.614879`,
    };

    try {
      const response = await axios.post(
        "https://testapi.smileidentity.com/v1/upload",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.data?.code === "2202") {
        postContent(response?.data?.upload_url, convertedFile);
      }
    } catch (e: any) {
      console.error(e);
      const { code } = e.response.data;
      await getErrorMessage(regUser?.token ?? "", code, "smileId").then(
        (res) => {
          Swal.fire({
            icon: "error",
            title: res?.data?.message?.errorTitle || "Error",
            html:
              `<strong style="display: block; margin-bottom: 10px;">${
                res?.data?.message?.messageDescription || ""
              }</strong>` +
              (res?.data?.message?.suggestion
                ? `<span>${res?.data?.message?.suggestion}</span><br>
                  <a href="${res.data?.message?.refUrl}" target="_blank" rel="noopener noreferrer" style="display: block; margin-top: 10px;">
                    ${res?.data?.message?.refUrl ? `Reference: ${res.data?.message?.refUrl}` : ""}
                  </a>`
                : res?.data?.status?.refUrl
                  ? `<a href="${res?.data?.status?.refUrl}" target="_blank" rel="noopener noreferrer">${res?.data?.status?.refUrl}</a>`
                  : ""),
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
      );

      setCameraKey(Date.now());
      setLoading(false);
    }
  };
  const converBase64ToImage = (
    base64: string,
    fileName: string
  ): File | null => {
    try {
      // Remove data URL prefix if present
      const base64Data = base64.replace(/^data:image\/\w+;base64,/, "");
      const byteString = atob(base64Data);
      const mimeString = base64.match(/^data:(image\/\w+);base64,/)?.[1] || "";

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([ab], { type: mimeString });
      const file = new File([blob], fileName, { type: mimeString });

      return file;
    } catch (error) {
      console.error("Failed to convert base64 to image:", error);
      return null;
    }
  };

  const handleImagesComputed = async (e: any) => {
    try {
      // Map and update image data
      const updData = e?.detail?.images?.map((item: any) => ({
        ...item,
        file_name: "",
      }));

      const base64 = e?.detail?.images[0]?.image;
      const pathToSaveImage = "selfie.png";

      if (!base64) throw new Error("Base64 image data is missing.");

      // Convert base64 to File
      const file = await converBase64ToImage(base64, pathToSaveImage);

      setLoading(true);

      if (file) {
        console.log("file Converted");
      } else {
        throw new Error("Failed to convert base64 to image.");
      }

      // Update jsonData with updated images and prepare for upload
      jsonData.images = updData;
      await prepUpload(file);
    } catch (error) {
      console.error("Error in handleImagesComputed:", error);
    } 
  };

  useEffect(() => {
    const app = document.querySelector("smart-camera-web");

    if (app) {
      app.addEventListener("imagesComputed", handleImagesComputed);
    }

    return () => {
      if (app) {
        app.removeEventListener("imagesComputed", handleImagesComputed);
      }
    };
  }, [cameraKey]);

  const generateSignature = () => {
    const api_key = "1588d4cf-a8e6-469b-aca4-3a15cbe0a806";
    const partner_id = "2457";
    const timestamp = `${
      new Date().toISOString().split("T")[0]
    }T17:57:00.614879`;

    const hmac = CryptoJS.HmacSHA256(
      timestamp + partner_id + "sid_request",
      api_key
    );
    const signature = CryptoJS.enc.Base64.stringify(hmac);
    return signature;
  };

  const jsonData = {
    package_information: {
      apiVersion: {
        buildNumber: 0,
        majorVersion: 2,
        minorVersion: 0,
      },
    },
    id_info: {
      dob: regUser?.dob,
      country: "ZA",
      entered: true,
      id_type: "NATIONAL_ID_NO_PHOTO",
      id_number: "0000000000000",
      last_name: regUser?.firstName,
      first_name: regUser?.lastName,
      middle_name: "",
    },
    images: [],
  };

  const generateZipFile = () => {
    const zip = new JSZip();

    const jsonString = JSON.stringify(jsonData, null, 2);

    // Add the JSON string directly to the zip file
    zip.file("info.json", jsonString);

    // Generate the zip file as a blob
    return zip.generateAsync({ type: "blob" });
  };

  const postContent = async (url: string, convertedFile: any) => {
    const resetProcess = () => {
      reset(regUser?.token ?? "");
      setCameraKey(Date.now());
    };
    try {
      const zipBlob = await generateZipFile();
      const formData = new FormData();
      formData.append("file", zipBlob, "attachments.zip");

      // Upload the ZIP file
      const uploadResponse = await axios.put(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "json",
      });

      if (uploadResponse.status === 200) {
        const maxRetries = 3;
        let retryCount = 0;

        // Function to handle liveness status
        const checkLivenessStatus = async () => {
          try {
            const statusResponse = await axios.post(
              `${API_URL}/smemw/getSmileIdLivelinessStatus`,
              {},
              {
                headers: {
                  "Content-Type": "application/json",
                  tenant_id: "eazy_bank",
                  Authorization: `Bearer ${regUser?.token ?? ""}`,
                },
              }
            );

            const { livenessCheckPassed, livenessResult } =
              statusResponse.data.message;

            if (livenessCheckPassed) {
              handleLivenessSuccess();
              return true;
            }

            retryCount += 1;

            if (retryCount >= maxRetries) {
              handleLivenessFailure(livenessResult);
              return true;
            }

            return false;
          } catch (error) {
            console.error("Error fetching liveness status:", error);
            retryCount += 1;

            if (retryCount >= maxRetries) {
              handleLivenessFailure(
                "Verification could not be completed. Restarting the process."
              );
              return true;
            }

            return false;
          }
        };

        // Function to handle liveness success
        const handleLivenessSuccess = async () => {
          setLoading(false);

          if (convertedFile) {
            await uploadSelfieDoc(convertedFile, regUser?.token ?? "");
          }
          markFormCompleted("smile");
          navigate("/auth/signature");
        };

        // Function to handle liveness failure
        const handleLivenessFailure = (message: string) => {
          setLoading(false);
          toast.error(`${message}, Retry Again`, {
            position: "top-center",
            theme: "colored",
          });
          resetProcess();
        };

        // Start interval to check liveness
        const intervalId = setInterval(async () => {
          const isComplete = await checkLivenessStatus();
          if (isComplete) clearInterval(intervalId);
        }, 8000);
      }

      return uploadResponse.data;
    } catch (error) {
      console.error("Error uploading file or handling liveness:", error);
      resetProcess();
      throw error;
    }
  };

  return (
    <div>
      <ToastContainer limit={1}></ToastContainer>
      {loading ? (
        <div
          className="alert bg-light-warning border border-warning d-flex align-items-center p-5 mb-10 mt-10"
          style={{ maxWidth: "500px" }}
        >
          <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
            <i className="bi bi-person-badge-fill text-warning fs-2hx"></i>
          </span>

          <div className="d-flex flex-column">
            <h5 className="mb-1">We are verifying your identity...</h5>
            <span>
              {" "}
              This process might take a few moments. Please do not refresh or
              close the page while we complete your verification.
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      <smart-camera-web capture-id key={cameraKey}>
        {" "}
      </smart-camera-web>
      <div className="d-flex flex-row justify-content-center align-items-center mt-5">
        {loading ? (
          <div className="spinner-border text-primary" role="status">
            <span className="" role="status" aria-hidden="true"></span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SmileId;
