import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Swal from "sweetalert2";
import folder from "../../../_metronic/assets/all-serviceIcons/folder.svg";
import gridImg from "../../../_metronic/assets/all-serviceIcons/grid-square.svg";
import listImg from "../../../_metronic/assets/all-serviceIcons/List_view.svg";
import { MenuComponent } from "../../../_metronic/assets/ts/components";
import { useFormik } from "formik";
import { Modal } from "bootstrap";
import pdf from "../../../_metronic/assets/all-serviceIcons/pdf-file.svg";
import docx from "../../../_metronic/assets/all-serviceIcons/docx-file.svg";
import jpeg from "../../../_metronic/assets/all-serviceIcons/image-files.svg";
import ShareLinkModal from "./ShareLinkModal";
import { OverlayTrigger, Popover } from "react-bootstrap";

const Workspace: FC = () => {
  const [add, setAdd] = useState(false);
  const [list, setList] = useState(true);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [file, setFile] = useState<File | null>();

  const [expandedFolders, setExpandedFolders] = useState(new Set());
  const [checkedFolders, setCheckedFolders] = useState(new Set());

  const editFormik = useFormik({
    initialValues: {
      userStatus: "",
    },
    validationSchema: Yup.object().shape({
      userStatus: Yup.string().required("Action is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log("submit called");

      const modal = Modal.getInstance("#kt_edit_customer_modal");
      if (modal) {
        modal.hide();
      }
      const confirmationResult = await Swal.fire({
        icon: "question",
        text: "Are you sure you want to change the status?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      });

      if (confirmationResult.isConfirmed) {
        try {
          // await dispatch1(
          //   editCustomerStatus({
          //     url: values.userStatus, // Pass the dynamic URL
          //     tenantId: tenantId,
          //     // businessGuid: customer?.businessGuid || "",
          //     userId: userId,
          //   })
          // );
          //   await dispatch(fetchCustomer(fetchCustomersPayload as any) as any);
        } catch (error) {
          // Handle any errors that might occur during the asynchronous operations
          console.error("Error:", error);
        }
      } else if (confirmationResult.isDenied) {
        const modal = Modal.getInstance("#kt_edit_customer_modal");
        if (modal) {
          modal.show();
        }
      }
    },
    onReset: async (values, formikHelpers) => {
      // setFilter(false);
      // await dispatch(fetchCustomer(fetchCustomersPayload as any) as any);
    },
  });

  const handleFileRemove = () => {
    setFile(null);
  };

  const handleAddFile = (uploadedFile: File | null) => {
    if (uploadedFile) {
      setFile(uploadedFile);
    } else {
      console.error("No file selected.");
    }
  };

  const handleUpload = () => {
    const modalElement = document.getElementById("kt_modal_upload_file");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  const handleOpenModal = () => {
    const modalElement = document.getElementById("date_range");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const location = useLocation();

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // Calculate pagination
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   const currentData = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();
  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(array?.length / itemsPerPage);
    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // Handler for item selection
  const handleItemSelect = (fileGuid: string) => {
    setSelectedItems([fileGuid]);
  };

  const array = [
    {
      name: "invoices",
      size: "folder - 2, file - 25",
      type: "System Generated",
      email:
        "akhil@yopmail.com,ruthra@yopmail.com,sathya@yopmail.com,ajinkya@yopmail.com",
      lastModified: "19 Aug 2024, 5:20 pm",
      fileGuid: "asdr23454esdf1",
      status: "Yet to access",
    },
    {
      name: "remittance",
      size: "folder - 4, file - 20",
      type: "User Generated",
      email: "-",
      lastModified: "22 Sep 2024, 10:30 am",
      fileGuid: "asdr23454esdf2",
      status: "Yet to access",
    },
    {
      name: "bill payment",
      size: "folder - 5, file - 50",
      type: "System Generated",
      email: "-",
      lastModified: "24 Jun 2024, 11:30 am",
      fileGuid: "asdr23454esdf3",
      status: "Link Expired",
    },
    {
      name: "accounts",
      size: "folder - 8, file - 45",
      type: "System Generated",
      email: "ruthra@yopmail.com",
      lastModified: "21 Feb 2024, 2:40 pm",
      fileGuid: "asdr23454esdf4",
      status: "Link Expired",
    },
    {
      name: "apps",
      size: "folder - 12, file - 120",
      type: "System Generated",
      email: "ruthra@yopmail.com",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf5",
      status: "Accessed",
    },
    {
      name: "remittance",
      size: "folder - 12, file - 40",
      type: "User Generated",
      email: "ruthra@yopmail.com",
      lastModified: "22 Sep 2024, 10:30 am",
      fileGuid: "asdr23454esdf2",
      status: "Accessed",
    },
    {
      name: "bill payment",
      size: "folder - 2, file - 25",
      type: "User Generated",
      email: "-",
      lastModified: "24 Jun 2024, 11:30 am",
      fileGuid: "asdr23454esdf3",
      status: "Link Expired",
    },
  ];

  const items = [
    "akhil@yopmail.com",
    "ruthra@yopmail.com",
    "sathya@yopmail.com",
    "ajinkya@yopmail.com",
  ];

  const handleOpenAccessModal = () => {
    const modalElement = document.getElementById("access_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  const [checked, setChecked] = useState(0);
  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      setChecked((prev) => prev + 1);
    } else {
      setChecked((prev) => prev - 1);
    }
  };

  const nestedFolderData = [
    {
      userId: "2effcbfa20b44b389a7db721cb93f5a9",
      tenantId: "eazy_bank",
      businessGuid: "2d7b46f8356a4cbd805bf7246d84dd82",
      folders: [
        {
          folderGuid: "c394a032-9d41-4ed4-a208-b82847094534",
          folderName: "New Folder 1",
          parentFolderGuid: "207ef576-37cd-4842-b365-6e314b8b7bc9",
          type: "FOLDER",
          size: "0 B",
          lastModified: "25 Nov 2024 14:59:26 pm",
          path: "\\data_room\\eazy_bank\\2d7b46f8356a4cbd805bf7246d84dd82\\New Folder 1",
          createdDate: "2024-11-25T09:28:23.000+00:00",
          updatedDate: "2024-11-25T09:28:23.000+00:00",
          root: true,
        },
        {
          folderGuid: "7c1e7a74-41a8-4a91-9485-4ea1eaea5aaa",
          folderName: "New Folder 2",
          parentFolderGuid: "207ef576-37cd-4842-b365-6e314b8b7bc9",
          type: "FOLDER",
          size: "0 B",
          lastModified: "25 Nov 2024 14:59:07 pm",
          path: "\\data_room\\eazy_bank\\2d7b46f8356a4cbd805bf7246d84dd82\\New Folder 2",
          createdDate: "2024-11-25T09:29:08.000+00:00",
          updatedDate: "2024-11-25T09:29:08.000+00:00",
          root: true,
          // Level 2
          subFolders: [
            {
              folderGuid: "e0a1b2c3-d4e5-6789-0abc-dfghijklmno1",
              folderName: "Level 2 Folder 1",
              parentFolderGuid: "7c1e7a74-41a8-4a91-9485-4ea1eaea5aaa",
              type: "FOLDER",
              size: "10 MB",
              lastModified: "24 Nov 2024 12:00:00 pm",
              path: "\\data_room\\eazy_bank\\2d7b46f8356a4cbd805bf7246d84dd82\\New Folder 2\\Level 2 Folder 1",
              createdDate: "2024-11-24T10:00:00.000+00:00",
              updatedDate: "2024-11-24T12:00:00.000+00:00",
              root: false,
              // Level 3
              subFolders: [
                {
                  folderGuid: "h0i1j2k3-l4m5-6789-0abc-opqrstuvwxy1",
                  folderName: "Level 3 Folder 1",
                  parentFolderGuid: "e0a1b2c3-d4e5-6789-0abc-dfghijklmno1",
                  type: "FOLDER",
                  size: "200 MB",
                  lastModified: "23 Nov 2024 10:00:00 am",
                  path: "\\data_room\\eazy_bank\\2d7b46f8356a4cbd805bf7246d84dd82\\New Folder 2\\Level 2 Folder 1\\Level 3 Folder 1",
                  createdDate: "2024-11-23T09:00:00.000+00:00",
                  updatedDate: "2024-11-23T10:00:00.000+00:00",
                  root: false,
                  // Level 4
                  subFolders: [
                    {
                      folderGuid: "a1b2c3d4-e5f6-7890-ghij-klmnopqrstuv",
                      folderName: "Level 4 Folder 1",
                      parentFolderGuid: "h0i1j2k3-l4m5-6789-0abc-opqrstuvwxy1",
                      type: "FOLDER",
                      size: "500 MB",
                      lastModified: "22 Nov 2024 09:00:00 am",
                      path: "\\data_room\\eazy_bank\\2d7b46f8356a4cbd805bf7246d84dd82\\New Folder 2\\Level 2 Folder 1\\Level 3 Folder 1\\Level 4 Folder 1",
                      createdDate: "2024-11-22T08:00:00.000+00:00",
                      updatedDate: "2024-11-22T09:00:00.000+00:00",
                      root: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      parentFolderGuid: "207ef576-37cd-4842-b365-6e314b8b7bc9",
    },
    {
      userId: "2effcbfa20b44b389a7db721cb93f5a9",
      tenantId: "eazy_bank",
      businessGuid: "2d7b46f8356a4cbd805bf7246d84dd82",
      folders: [
        {
          folderGuid: "c394a032-9d41-4ed4-a208-b82847094534",
          folderName: "New Folder 1",
          parentFolderGuid: "207ef576-37cd-4842-b365-6e314b8b7bc9",
          type: "FOLDER",
          size: "0 B",
          lastModified: "25 Nov 2024 14:59:26 pm",
          path: "\\data_room\\eazy_bank\\2d7b46f8356a4cbd805bf7246d84dd82\\New Folder 1",
          createdDate: "2024-11-25T09:28:23.000+00:00",
          updatedDate: "2024-11-25T09:28:23.000+00:00",
          root: true,
        },
        {
          folderGuid: "7c1e7a74-41a8-4a91-9485-4ea1eaea5aaa",
          folderName: "New Folder 2",
          parentFolderGuid: "207ef576-37cd-4842-b365-6e314b8b7bc9",
          type: "FOLDER",
          size: "0 B",
          lastModified: "25 Nov 2024 14:59:07 pm",
          path: "\\data_room\\eazy_bank\\2d7b46f8356a4cbd805bf7246d84dd82\\New Folder 2",
          createdDate: "2024-11-25T09:29:08.000+00:00",
          updatedDate: "2024-11-25T09:29:08.000+00:00",
          root: true,
        },
      ],
      parentFolderGuid: "207ef576-37cd-4842-b365-6e314b8b7bc9",
    },
    {
      userId: "3abf123e45g67h8910ijklmnopqrs890",
      tenantId: "finance_bank",
      businessGuid: "1a2b3c4d5e6f7g8h9i0jklmnopqrstuv",
      folders: [
        {
          folderGuid: "e1f2g3h4-5678-90ab-cdef-ghijklmnopqr",
          folderName: "Reports",
          parentFolderGuid: "z1x2c3v4-bn5m-6789-0abc-defghijklmno",
          type: "FOLDER",
          size: "1.2 MB",
          lastModified: "24 Nov 2024 12:00:00 pm",
          path: "\\data_room\\finance_bank\\1a2b3c4d5e6f7g8h9i0jklmnopqrstuv\\Reports",
          createdDate: "2024-11-24T10:00:00.000+00:00",
          updatedDate: "2024-11-24T12:00:00.000+00:00",
          root: true,
        },
        {
          folderGuid: "x1y2z3a4-5678-90ab-cdef-ghijklmnopqr",
          folderName: "Archives",
          parentFolderGuid: "z1x2c3v4-bn5m-6789-0abc-defghijklmno",
          type: "FOLDER",
          size: "3.4 GB",
          lastModified: "23 Nov 2024 09:30:00 am",
          path: "\\data_room\\finance_bank\\1a2b3c4d5e6f7g8h9i0jklmnopqrstuv\\Archives",
          createdDate: "2024-11-23T08:00:00.000+00:00",
          updatedDate: "2024-11-23T09:30:00.000+00:00",
          root: true,
        },
      ],
      parentFolderGuid: "z1x2c3v4-bn5m-6789-0abc-defghijklmno",
    },
  ];

  const toggleExpand = (folderId: string) => {
    setExpandedFolders((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(folderId)) {
        newSet.delete(folderId);
      } else {
        newSet.add(folderId);
      }
      return newSet;
    });
  };

  const toggleCheck = (folderId: string) => {
    setCheckedFolders((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(folderId)) {
        newSet.delete(folderId);
      } else {
        newSet.add(folderId);
      }
      return newSet;
    });
  };

  const renderFolder = (folder: any, level = 0) => {
    const hasSubFolders = folder.subFolders && folder.subFolders.length > 0;
    const isExpanded = expandedFolders.has(folder.folderGuid);
    const isChecked = checkedFolders.has(folder.folderGuid);

    return (
      <div key={folder.folderGuid} className="w-full">
        <div
          className="d-flex align-items-center py-2"
          style={{ paddingLeft: `${level * 20}px` }}
        >
          {/* {!hasSubFolders && <div style={{ width: "24px" }} />} */}
          <div className="form-check form-check-custom form-check-solid me-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={isChecked}
              onChange={() => toggleCheck(folder.folderGuid)}
            />
          </div>
          <span className="fs-7 me-3">{folder.folderName}</span>
          {hasSubFolders && (
            <>
              {isExpanded ? (
                <i
                  className="bi bi-chevron-down text-dark"
                  onClick={() => toggleExpand(folder.folderGuid)}
                ></i>
              ) : (
                <i
                  className="bi bi-chevron-up  text-dark"
                  onClick={() => toggleExpand(folder.folderGuid)}
                ></i>
              )}
            </>
          )}
        </div>

        {hasSubFolders && isExpanded && (
          <div className="ms-4">
            {folder.subFolders.map((subFolder: any) =>
              renderFolder(subFolder, level + 1)
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {" "}
      <h4 className="mb-4">Document Room - Workspace</h4>{" "}
      <div className="card p-2">
        <div className="d-flex justify-content-between mb-5">
          <div className="d-flex align-items-center  position-relative my-1">
            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              data-kt-docs-table-filter="search"
              className="form-control p-3 border-secondary w-250px ps-15"
              placeholder="Search Files & Folders"
              // defaultValue={searchTerm}
              // onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div>
          <div className="d-flex flex-1 align-items-center p-3 gap-10 flex-end">
            <div className="dropdown">
              <button
                className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#ebe9eb",
                }}
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-funnel text-dark fs-2 p-0 m-0"></i>
              </button>

              <ul
                className="dropdown-menu dropdown-menu-end p-10 w-350px"
                aria-labelledby="dropdownMenuButton"
                onClick={(e) => e.stopPropagation()}
              >
                <li>
                  <p className="fs-4 ">Filter options</p>
                  <hr />
                </li>
                <li className="mb-3">
                  <label className="fs-5 mb-2">Item name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter name"
                  />
                </li>

                <li className="mb-5">
                  <label className="fs-5 mb-2">Created date</label>
                  <input type="date" className="form-control" />
                </li>
                <li>
                  <div className="d-flex justify-content-end">
                    {" "}
                    <button
                      className="btn btn-md"
                      type="button"
                      // onClick={() => setToggle(true)}
                    >
                      Apply
                    </button>
                  </div>
                </li>
              </ul>
            </div>

            <div className="d-flex">
              <div
                className="d-flex border border-dark px-2 py-1 rounded-start-5 border-end-0 align-items-center"
                onClick={() => setList(true)}
              >
                {list && (
                  <i className="bi bi-check2 fs-3 fw-bold text-dark"></i>
                )}
                <img
                  src={listImg}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
              </div>
              <div
                className="d-flex border border-dark px-2 py-1 rounded-end-5 align-items-center"
                onClick={() => setList(false)}
              >
                {!list && (
                  <i className="bi bi-check2 fs-3 fw-bold text-dark"></i>
                )}
                <img
                  src={gridImg}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
              </div>
            </div>

            {checked > 0 ? (
              <button
                className={`btn btn-danger fs-7 p-3 w-100px`}
                onClick={() =>
                  Swal.fire({
                    icon: "warning",
                    text: "Are you sure you want to delete the selected folders?",
                  })
                }
                //   id="kt_modal_toggle_domestic"
                //   disabled={payAmount < 1}
              >
                Delete ({checked})
              </button>
            ) : (
              <>
                {/* <Authorize hasAnyPermission={["MENU_DOMESTIC_VENDOR_PAY|ADD"]}> */}
                {/* <button
                  className={`btn fs-7 p-3 w-100px`}
                  onClick={() => setAdd(true)}
                  //   id="kt_modal_toggle_domestic"
                  //   disabled={payAmount < 1}
                >
                  New Folder
                </button> */}
                {/* </Authorize> */}
                {/* <Authorize hasAnyPermission={["MENU_DOMESTIC_VENDOR_PAY|ADD"]}> */}
                <button
                  className={`btn fs-7 p-3 w-100px`}
                  onClick={() => handleUpload()}
                >
                  Upload Files
                </button>
                {/* </Authorize> */}
                <div className="dropdown">
                  <button
                    className="btn px-5"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Add Workspace
                  </button>

                  <ul
                    className="dropdown-menu dropdown-menu-end p-2 w-120px"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => setAdd(true)}
                      >
                        New Workspace
                      </button>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>

        {array?.length > 0 ? (
          !list ? (
            // Grid View
            <div className="ms-10 row g-2 gap-10">
              {array?.map((item, index) => (
                <div className="col-lg-2 col-md-4" key={index}>
                  <div
                    className="border rounded p-4 h-100 bg-light bg-hover-secondary cursor-pointer"
                    onMouseOver={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "#f1f1f2";
                    }}
                    onMouseOut={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "white";
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-start mb-3">
                      <div className="form-check form-check-custom form-check-sm">
                        <input
                          className="form-check-input cursor-pointer border border-dark"
                          type="checkbox"
                          onClick={(e) => handleCheckClick(e)}
                        />
                      </div>
                      <div className="d-flex gap-2">
                        {/* Action buttons */}

                        <div className="dropdown">
                          <button
                            className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "25%",
                              backgroundColor: "#ebe9eb",
                            }}
                            type="button"
                            data-bs-toggle="dropdown"
                          >
                            <i className="bi bi-three-dots text-dark fs-6 p-0 m-0"></i>
                          </button>
                          <ul
                            className="dropdown-menu dropdown-menu-end p-1"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                // onClick={() => setToggle(true)}
                              >
                                View
                              </button>
                            </li>
                            {item.type === "User generated" && (
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => setEditingId(item.fileGuid)}
                                >
                                  Rename
                                </button>
                              </li>
                            )}
                            {item.type !== "FOLDER" && (
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    navigate("/documentRoom/pdfView", {
                                      state: {
                                        pdfUrl:
                                          "https://dev-test.xfin.digital/document/document/downloadDocument/a4c46070-6944-463a-b950-52d01e4fa8bb",
                                      },
                                    });
                                    // handleOpenModal();
                                  }}
                                >
                                  Self Sign
                                </button>
                              </li>
                            )}
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                // onClick={() => setToggle(true)}
                              >
                                Paste
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                // onClick={() => setEditingId(item.fileGuid)}
                              >
                                Copy
                              </button>
                            </li>
                            {item.type === "User generated" && (
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  // onClick={() => setToggle(true)}
                                >
                                  Cut
                                </button>
                              </li>
                            )}
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                // onClick={() => setEditingId(item.fileGuid)}
                              >
                                Mirror to workspace
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                // onClick={() => setToggle(true)}
                              >
                                Download
                              </button>
                            </li>
                            {item.type === "User generated" && (
                              <li>
                                <button
                                  className="dropdown-item text-danger"
                                  type="button"
                                  onClick={() =>
                                    Swal.fire({
                                      icon: "question",
                                      text: "Are you sure you want to delete documents?",
                                      showCancelButton: true,
                                      confirmButtonText: "Yes, delete",
                                    })
                                  }
                                >
                                  Delete
                                </button>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="text-center mb-3">
                      {item.type === "folder" ? (
                        <img
                          src={folder}
                          alt=""
                          style={{ width: "100px", height: "100px" }}
                        />
                      ) : (
                        <img
                          src={
                            item?.type === "pdf"
                              ? pdf
                              : item?.type === "jpeg"
                                ? jpeg
                                : docx
                          }
                          alt=""
                          style={{ width: "100px", height: "100px" }}
                        />
                      )}
                    </div>

                    {editingId === item.fileGuid ? (
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Workspace name"
                          defaultValue={item.name}
                        />
                        <div className="d-flex gap-1">
                          <i
                            className="bi bi-check-circle text-primary cursor-pointer"
                            onClick={() => setEditingId(null)}
                          ></i>
                          <i
                            className="bi bi-x-circle text-danger cursor-pointer"
                            onClick={() => setEditingId(null)}
                          ></i>
                        </div>
                      </div>
                    ) : (
                      <h5 className="text-center text-gray-800 mb-2">
                        {item.name} ({item.size})
                      </h5>
                    )}

                    <div className="text-center text-gray-600 small">
                      <div>{item.lastModified}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <table
              className="table align-middle border rounded table-row-dashed fs-6 g-5"
              style={{
                height: "440px",
                overflowY: "scroll",
              }}
            >
              <thead className="bg-gray-200">
                <tr className="text-start text-dark-600 fw-bold fs-6 ">
                  <th className="px-3">
                    {" "}
                    <label className="form-check form-check-custom form-check-sm fs-7">
                      <input
                        className="form-check-input cursor-pointer border border-dark"
                        type="checkbox"
                        // checked={selectAll}
                        // onClick={handleSelectAll}
                      />{" "}
                    </label>
                  </th>

                  <th className="min-w-200px p-5">Name</th>
                  <th className="min-w-100px p-5">Size</th>
                  <th className="min-w-100px p-5">Shared with</th>
                  <th className="min-w-100px p-5">Last Modified</th>
                  <th className="min-w-100px p-5">Action</th>
                </tr>
              </thead>

              <tbody>
                {add && (
                  <tr>
                    <td></td>
                    <td>
                      <div className="d-flex align-items-center gap-5">
                        <input
                          type="text"
                          className="form-control w-300px"
                          placeholder="Workspace name"
                        />
                        <div className="d-flex gap-3">
                          <i className="bi bi-check-circle fw-bold fs-2x text-primary cursor-pointer"></i>
                          <i
                            className="bi bi-x-circle fw-bold fs-2x text-danger cursor-pointer"
                            onClick={() => setAdd(false)}
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {array?.length > 0 ? (
                  array?.map((item, index) => (
                    <tr
                      key={index}
                      onMouseOver={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "#f1f1f2";
                      }}
                      onMouseOut={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "white";
                      }}
                    >
                      <td className="px-3">
                        <label className="form-check form-check-custom form-check-sm fs-7">
                          <input
                            className="form-check-input cursor-pointer border border-dark"
                            type="checkbox"
                            // checked={invoiceGuid.includes(item.invoiceGuid)}
                            onClick={(e) => handleCheckClick(e)}
                          />{" "}
                        </label>
                      </td>

                      <td className="p-5 text-gray-600 cursor-pointer fw-hover-bold fs-6">
                        <div className=" d-flex flex-row gap-3 align-items-center">
                          {item?.type === "folder" ? (
                            <i
                              className={`bi bi-folder2-open fs-2 text-${
                                item.size === "System Generated"
                                  ? "primary"
                                  : "dark"
                              }`}
                            ></i>
                          ) : (
                            <img
                              src={
                                item?.type === "pdf"
                                  ? pdf
                                  : item?.type === "jpeg"
                                    ? jpeg
                                    : docx
                              }
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                          )}
                          {editingId === item.fileGuid ? (
                            <div className="d-flex align-items-center gap-5">
                              <input
                                type="text"
                                className="form-control w-300px"
                                placeholder="Folder name"
                                defaultValue={item.name}
                              />
                              <div className="d-flex gap-3">
                                <i
                                  className="bi bi-check-circle fw-bold fs-2x text-primary cursor-pointer"
                                  onClick={() => setEditingId(null)}
                                ></i>
                                <i
                                  className="bi bi-x-circle fw-bold fs-2x text-danger cursor-pointer"
                                  onClick={() => setEditingId(null)}
                                ></i>
                              </div>
                            </div>
                          ) : (
                            <p
                              className={`text-${item.type === "System Generated" ? "primary" : "gray-600"} fw-hover-bold fs-6 mb-0`}
                            >
                              {item.name}
                            </p>
                          )}
                        </div>
                      </td>
                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                        <p className="mb-0"> {item.size}</p>
                      </td>
                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                        <p className="mb-0">
                          {item.email.length > 20 ? (
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement="top"
                              overlay={
                                <Popover
                                  className="px-7 py-3 bg-light-secondary"
                                  id="popover-trigger-focus"
                                >
                                  {item.email.split(",").map((status, idx) => (
                                    <div key={idx}>{status.trim()}</div>
                                  ))}
                                </Popover>
                              }
                            >
                              <div>
                                {item.email?.length > 20
                                  ? `${item.email.slice(0, 20)}...`
                                  : item.email}
                              </div>
                            </OverlayTrigger>
                          ) : (
                            <p className="text-gray-600 text-hover-primary fw-hover-bold fs-6 mb-0">
                              {item.email}
                            </p>
                          )}
                        </p>
                      </td>
                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                        <p className="mb-0"> {item.lastModified}</p>
                      </td>
                      <td className="p-5 text-gray-600 p-2">
                        <div className="dropdown" style={{ width: "20px" }}>
                          <button
                            className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "25%",
                              backgroundColor: "#ebe9eb",
                            }}
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-three-dots text-dark fs-6 p-0 m-0"></i>
                          </button>

                          <ul
                            className="dropdown-menu dropdown-menu-end p-1"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={handleOpenModal}
                              >
                                Share
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => setEditingId(item.fileGuid)}
                              >
                                Rename
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item text-danger"
                                type="button"
                                onClick={() =>
                                  Swal.fire({
                                    icon: "question",
                                    text: "Are you sure you want to delete workspace?",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, delete",
                                  })
                                }
                              >
                                Delete
                              </button>
                            </li>
                            {item?.status !== "Link Expired" && (
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={handleOpenAccessModal}
                                >
                                  Manage access
                                </button>
                              </li>
                            )}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )
        ) : (
          <div className="text-center py-5">
            <p className="text-gray-600">No Data Found</p>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <div className="p-5">
            <ul className="pagination">
              <li
                className={`page-item double-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={0}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(1)}
                >
                  <i className="previous"></i>
                  <i className="previous"></i>
                </a>
              </li>
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={1}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <i className="previous"></i>
                </a>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item next ${
                  currentPage === Math.ceil(array?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={2}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <i className="next"></i>
                </a>
              </li>
              <li
                className={`page-item double-arrow ${
                  currentPage === Math.ceil(array?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={3}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() =>
                    handlePageChange(Math.ceil(array?.length / itemsPerPage))
                  }
                >
                  <i className="next"></i>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="p-5 d-flex align-items-center">
            <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
              Total: {array?.length}&nbsp;&nbsp;
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              //   onChange={handleItemsPerPageChange}
              style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="kt_modal_upload_file"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header" id="kt_modal_upload_file_header">
              <h2 className="fw-bold">Upload File</h2>
              {/* <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-bs-dismiss="modal"
              >
                <i className="ki-duotone ki-cross fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div> */}
            </div>

            <div
              className="modal-body scroll-y mx-5 mx-xl-15 my-7 "
              style={{
                maxHeight: "400px",
                overflow: "scroll",
              }}
            >
              {nestedFolderData.map((tenant) => (
                <div key={tenant.tenantId} className="">
                  {/* <h3 className="fs-6 fw-semibold mb-3">{tenant.tenantId}</h3> */}
                  {/* <div className="border rounded-3 p-4"> */}
                  <div className="">
                    {tenant.folders.map((folder) => renderFolder(folder))}
                  </div>
                </div>
              ))}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                <span className="indicator-label">Upload</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="access_modal">
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          // style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              {" "}
              <h4 className="mb-0">Update Link access</h4>
            </div>
            <div className="modal-body p-10">
              <div className="d-flex flex-column">
                <div className="card card-body max-h-300px overflow-scroll pt-0">
                  <div className="d-flex justify-content-between mb-3">
                    <p className="m-0 fw-bold fs-5">Shared with</p>
                    {/* <label className="form-check form-check-custom form-check-sm">
                      <input
                        className="form-check-input cursor-pointer border border-dark"
                        type="checkbox"
                        // checked
                        // onClick={(e) => handleCheckClick(e)}
                      />{" "}
                    </label> */}
                  </div>
                  <hr
                    className="mt-0"
                    style={{
                      borderStyle: "dashed",
                    }}
                  />
                  {items?.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between mb-3 align-items-center"
                    >
                      <p className="m-0">{item}</p>
                      <i className="bi bi-trash fs-6 text-danger cursor-pointer"></i>
                    </div>
                  ))}
                  {add && (
                    <div className="d-flex gap-3 align-items-center mb-3">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter email"
                      />
                      <i className="bi bi-check-circle fs-1"></i>
                    </div>
                  )}
                  <div>
                    {/* <button
                      type="button"
                      className="btn btn-sm"
                      onClick={() => setAdd(!add)}
                    >
                      Add
                    </button> */}
                    <p
                      className="text-decoration-underline text-primary cursor-pointer"
                      onClick={() => setAdd(!add)}
                    >
                      Add View user
                    </p>
                  </div>
                </div>

                <button
                  type="button"
                  className={`w-100 rounded btn`}
                  style={{
                    // width: "150px",
                    color: "#ffff",
                  }}
                  onClick={() => {
                    // setCurrentPage(1);
                  }}
                  //   disabled={!toDate}
                >
                  Update access
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareLinkModal />
    </>
  );
};

export { Workspace };
