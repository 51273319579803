/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { defaultCreateAppData, ICreateAppData } from "./EditPayrollAppModel";
import { PayrollStepperModal, resetApi } from "./EditPayrollComponent";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { SteppperModal2 } from "./EditPayrollStepperModal2";
import { SteppperModal3 } from "./EditPayroll";
import { SteppperModal4 } from "./EditPayrollStepperModal4";
import { KTIcon } from "../../../../_metronic/helpers";
import { employeeData } from "./EditData";
import { TaxData } from "./EditData";
import { parsePhoneNumber } from "react-phone-number-input";
import { taxResults } from "./EditData";
import Swal from "sweetalert2";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { Api } from "../PayrollDashboard";
import { isPhoneValid } from "../../../modules/auth/components/Login";

type Props = {
  show: boolean;
  handleClose: () => void;
  Data: MyData | null;
};

interface MyData {
  tenantId: string;
  id: number;
  employeeCode: string;
  firstName: string;
  dob: string;
  address: string;
  isdCode: number;
  phoneNumber: string;
  emergencyContactIsdCode: number;
  emergencyContactNo: string;
  bloodGroup: string;
  email: string;
  joiningDate: string;
  designation: string;
  department: string;
  location: string;
  bankCode: string;
  bankName: string;
  empStatus: number;
  netPayMonthly: string;
}

export interface MyDocument {
  tenantId: string;
  documentId: number;
  documentGuid: string;
  documentExtention: string;
  documentName: string;
  documentPath: string;
  fileUploadType: number;
  documentUploadedByType: number;
  documentStatus: number;
  referenceType: number;
  referenceId: string;
  documentTypes: number[];
  cloneDocument: boolean;
}

export interface UpdatedDocument {
  documentExtention: string;
  documentName: string;
  documentTypes: number[];
  documentUploadedByType: number;
  documentStatus: number;
  fileUploadType: number;
  documentData: string;
  tenantId: string;
}

interface EmployeeData {
  tenantId: string;
  id: number;
  employeeCode: string;
  firstName: string;
  dob: string;
  address: string;
  isdCode: number;
  phoneNumber: string;
  emergencyContactIsdCode: number;
  emergencyContactNo: string;
  bloodGroup: string;
  email: string;
  joiningDate: string;
  designation: string;
  department: string;
  location: string;
  bankCode: string;
  bankName: string;
  bankAccountNumber: string;
  empStatus: number;
  basicMonthlySalary: string;
  basicPensionDeduction: string;
  travelAllowance: string;
  medicalDependants: string;
  otherAllowances: string;
  annualBonus: string;
  documents: MyDocument[];
}

const modalsRoot = document.getElementById("root-modals") || document.body;

export const EditPayrollModal = ({ show, handleClose, Data }: Props) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData?.token : null;

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
  const [hasError, setHasError] = useState(false);
  const [viewData, setViewData] = useState<EmployeeData | null>(null);
  const [idUpdate, setIdUpdate] = useState(false);
  const [addressUpdate, setAddressUpdate] = useState(false);

  const getEmployeeDataa = async () => {
    try {
      const url = `${API_URL}/employee/getEmployeeByCode`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        employeeCode: Data?.employeeCode,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      if (data?.status?.statusCode === 0) {
        console.log(data);
        setViewData(data.message);
        // if (apiCall != "edit") {
        updateData({
          empAddressProof: {
            addressProof: data
              ? data?.message?.documents?.find(
                  (doc: any) =>
                    doc?.documentName === "address proof" &&
                    doc?.documentStatus === 1
                )?.documentPath
              : "",
          },
          idProof: {
            idProof: data
              ? data?.message?.documents?.find(
                  (doc: any) =>
                    doc?.documentName === "idproof" && doc?.documentStatus === 1
                )?.documentPath
              : "",
          },
        });
        // }
      }

      resetApi.api();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Data?.employeeCode) getEmployeeDataa();
  }, [Data]);

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      empPhone: {
        ...prevData?.empPhone,
        phone: `+${viewData?.isdCode || ""} ${
          viewData?.phoneNumber || ""
        }`.trim(),
      },
      emeContact: {
        ...prevData?.emeContact,
        emeContact: `+${viewData?.emergencyContactIsdCode || ""} ${
          viewData?.emergencyContactNo || ""
        }`,
      },
    }));
  }, [viewData, Data]);

  type TaxResultsType = typeof taxResults;
  const [result, setResults] = useState<TaxResultsType>({} as TaxResultsType);

  TaxData.employeeCode = Data ? Data?.employeeCode : "";
  // Helper function to get document data
  const getDocumentData = (
    documents: any[],
    index: number,
    documentName: number
  ) => {
    const document = documents[index];
    return document && document.documentTypes[0] === documentName
      ? document
      : {};
  };

  // Update defaultCreateAppData
  const updateDefaultCreateAppData = (viewData: any) => {
    // Basic information
    defaultCreateAppData.appBasic.empName = viewData?.firstName || "";
    defaultCreateAppData.empDate.date = viewData?.dob || "";
    defaultCreateAppData.empEmail.email = viewData?.email || "";
    defaultCreateAppData.empPhone.phone = `+${viewData?.isdCode || ""} ${
      viewData?.phoneNumber || ""
    }`.trim();
    defaultCreateAppData.emeContact.emeContact = `+${
      viewData?.emergencyContactIsdCode || ""
    } ${viewData?.emergencyContactNo || ""}`;

    // Address
    defaultCreateAppData.empAddress.address = viewData?.address || "";
    const addressDoc = getDocumentData(viewData?.documents || [], 0, 12);
    defaultCreateAppData.empAddressDocId.addDocId = addressDoc.documentId || 0;

    defaultCreateAppData.empAddressProofImg.addressProofImg =
      addressDoc.documentPath || "";
    defaultCreateAppData.empAddressDocExt.addDocExt =
      addressDoc.documentExtention || "";
    defaultCreateAppData.empAddressDocGuid.addDocGuid =
      addressDoc.documentGuid || "";
    defaultCreateAppData.empAddressRefId.addRefId =
      addressDoc.referenceId || "";

    // Blood Group
    defaultCreateAppData.empBloodGroup.bloodGroup = viewData?.bloodGroup || "";

    // ID Proof
    const idProofDoc = getDocumentData(viewData?.documents || [], 1, 13);

    defaultCreateAppData.idProofImg.idProofImg = idProofDoc.documentPath || "";
    defaultCreateAppData.empIDProofDocId.idproofDocId =
      idProofDoc.documentId || 0;
    defaultCreateAppData.empIDProofDocExt.idproofDocExt =
      idProofDoc.documentExtention || "";
    defaultCreateAppData.empIDProofDocGuid.idproofDocGuid =
      idProofDoc.documentGuid || "";
    defaultCreateAppData.empIDProofRefId.idproofRefId =
      idProofDoc.referenceId || "";

    // Financial Details
    defaultCreateAppData.monthlySalary.monthlySalary =
      viewData?.basicMonthlySalary?.toString() || "";
    defaultCreateAppData.pensionDeductions.pensionDeductions =
      viewData?.basicPensionDeduction?.toString() || "";
    defaultCreateAppData.travelAllowance.travelAllowance =
      viewData?.travelAllowance?.toString() || "";
    defaultCreateAppData.medicalDependents.medicalDependents =
      viewData?.medicalDependants?.toString() || "";
    defaultCreateAppData.otherAllowance.otherAllowance =
      viewData?.otherAllowances?.toString() || "";
    defaultCreateAppData.annualBonus.annualBonus =
      viewData?.annualBonus?.toString() || "";

    // Additional Information
    defaultCreateAppData.doj.doj = viewData?.joiningDate || "";
    defaultCreateAppData.employeeID.employeeID = viewData?.employeeCode || "";
    defaultCreateAppData.designation.designation = viewData?.designation || "";
    defaultCreateAppData.department.department = viewData?.department || "";
    defaultCreateAppData.location.location = viewData?.location || "";
    defaultCreateAppData.accNumber.accNumber =
      viewData?.bankAccountNumber || "";
    defaultCreateAppData.accIdentifier.accIdetifier = viewData?.bankCode || "";
    defaultCreateAppData.accName.accName = viewData?.bankName || "";
  };

  // Update taxResults
  const updateTaxResults = (result: any) => {
    taxResults.basicMonthlySalary = result?.basicMonthlySalary;
    taxResults.basicPensionDeduction = result?.basicPensionDeduction;
    taxResults.travelAllowance = result?.travelAllowance;
    taxResults.medicalDependants = result?.medicalDependants;
    taxResults.otherAllowances = result?.otherAllowances;
    taxResults.annualBonus = result?.annualBonus;

    const incomeSummary = result?.incomeSummary || {};
    taxResults.incomeSummary.basicSalary = incomeSummary.basicSalary;
    taxResults.incomeSummary.travelAllowance = incomeSummary.travelAllowance;
    taxResults.incomeSummary.otherAllowances = incomeSummary.otherAllowances;
    taxResults.incomeSummary.bonus = incomeSummary.bonus;
    taxResults.incomeSummary.totalTaxableIncome =
      incomeSummary.totalTaxableIncome;
    taxResults.incomeSummary.pension = incomeSummary.pension;
    taxResults.incomeSummary.taxableIncome = incomeSummary.taxableIncome;
    taxResults.incomeSummary.totalIncomeTax = incomeSummary.totalIncomeTax;
    taxResults.incomeSummary.taxRebate = incomeSummary.taxRebate;
    taxResults.incomeSummary.medicalTaxCredits =
      incomeSummary.medicalTaxCredits;
    taxResults.incomeSummary.annualTaxPayable = incomeSummary.annualTaxPayable;
    taxResults.incomeSummary.basicSalaryMonthly =
      incomeSummary.basicSalaryMonthly;
    taxResults.incomeSummary.travelAllowanceMonthly =
      incomeSummary.travelAllowanceMonthly;
    taxResults.incomeSummary.otherAllowancesMonthly =
      incomeSummary.otherAllowancesMonthly;
    taxResults.incomeSummary.grossPayMonthly = incomeSummary.grossPayMonthly;
    taxResults.incomeSummary.totalDeductionsMonthly =
      incomeSummary.totalDeductionsMonthly;
    taxResults.incomeSummary.incomeTaxMonthly = incomeSummary.incomeTaxMonthly;
    taxResults.incomeSummary.uifMonthly = incomeSummary.uifMonthly;
    taxResults.incomeSummary.pensionMonthly = incomeSummary.pensionMonthly;
    taxResults.incomeSummary.netPayMonthly = incomeSummary.netPayMonthly;
  };

  // Update employeeData
  const updateEmployeeData = (
    data: any,
    addressUpdate: boolean,
    idUpdate: boolean
  ) => {
    employeeData.employeeCode = Data ? Data?.employeeCode : "";
    employeeData.firstName = data?.appBasic?.empName;
    employeeData.dob = data?.empDate?.date?.split("T")[0];
    employeeData.email = data?.empEmail?.email;
    employeeData.isdCode = data?.empPhone?.phone
      ? Number(parsePhoneNumber(data?.empPhone?.phone)?.countryCallingCode)
      : 0;
    employeeData.phoneNumber = data?.empPhone?.phone
      ? Number(parsePhoneNumber(data?.empPhone?.phone)?.nationalNumber)
      : 0;
    employeeData.address = data?.empAddress?.address;

    employeeData.bloodGroup = data?.empBloodGroup?.bloodGroup;

    employeeData.emergencyContactIsdCode = data?.emeContact?.emeContact
      ? Number(
          parsePhoneNumber(data?.emeContact?.emeContact)?.countryCallingCode
        )
      : 0;
    employeeData.emergencyContactNo = data?.emeContact?.emeContact
      ? Number(parsePhoneNumber(data?.emeContact?.emeContact)?.nationalNumber)
      : 0;
    employeeData.joiningDate = data?.doj?.doj?.split("T")[0];
    employeeData.designation = data?.designation?.designation;
    employeeData.department = data?.department?.department;
    employeeData.location = data?.location?.location;
    employeeData.bankAccountNumber = data?.accNumber?.accNumber;
    employeeData.bankName = data?.accName?.accName;
    employeeData.bankCode = data?.accIdentifier?.accIdetifier;

    if (addressUpdate) {
      const newDocument: UpdatedDocument = {
        documentExtention: "jpeg",
        documentName: "address proof",
        documentTypes: [12], // example: [1, 2, 3]
        documentUploadedByType: 1, // example: 1
        documentStatus: 4,
        fileUploadType: 1,
        documentData: data?.empAddressProof?.addressProof,
        tenantId: viewData ? viewData?.tenantId : "",
      };
      employeeData.documents.push(newDocument);
      setAddressUpdate(false);
    }

    if (idUpdate) {
      const newDocument: UpdatedDocument = {
        documentExtention: "jpeg",
        documentName: "idproof",
        documentTypes: [13], // example: [1, 2, 3]
        documentUploadedByType: 1, // example: 1
        documentStatus: 4,
        fileUploadType: 1,
        documentData: data.idProof.idProof,
        tenantId: viewData ? viewData?.tenantId : "",
      };
      employeeData.documents.push(newDocument);
      setIdUpdate(false);
    }
  };

  // useEffect(()=>{
  updateDefaultCreateAppData(viewData);
  updateTaxResults(result);
  updateEmployeeData(data, addressUpdate, idUpdate);

  // },[viewData])

  const UpdateEmployeeDataa = async (data: any) => {
    console.log(data);

    try {
      const url = `${API_URL}/employee/update`;
      const headers = {
        // 'Content-Type' should not be set to 'application/json' when using FormData
        Authorization: `Bearer ${token}`,
      };

      // Create a FormData object
      const formData = new FormData();
      formData.append("employeeCode", employeeData.employeeCode || "");
      formData.append("firstName", employeeData.firstName || "");
      formData.append("dob", employeeData.dob || "");
      formData.append("email", employeeData.email || "");
      formData.append("isdCode", employeeData.isdCode.toString() || "0");
      formData.append(
        "phoneNumber",
        employeeData.phoneNumber.toString() || "0"
      );
      formData.append("address", employeeData.address || "");
      formData.append("bloodGroup", employeeData.bloodGroup || "");
      formData.append(
        "emergencyContactIsdCode",
        employeeData.emergencyContactIsdCode.toString() || "0"
      );
      formData.append(
        "emergencyContactNo",
        employeeData.emergencyContactNo.toString() || "0"
      );
      formData.append("joiningDate", employeeData.joiningDate || "");
      formData.append("designation", employeeData.designation || "");
      formData.append("department", employeeData.department || "");
      formData.append("location", employeeData.location || "");
      formData.append(
        "bankAccountNumber",
        employeeData.bankAccountNumber || ""
      );
      formData.append("bankName", employeeData.bankName || "");
      formData.append("bankCode", employeeData.bankCode || "");

      if (data?.empAddressProof?.addressProof) {
        formData.append("addressProof", data.empAddressProof.addressProof);
      }
      if (data?.idProof?.idProof) {
        formData.append("idProof", data.idProof.idProof);
      }

      const options = {
        method: "POST",
        headers, // Do not include Content-Type; it will be set automatically
        body: formData, // Set the body to the FormData object
      };

      let response = await fetch(url, options);
      let responseData = await response.json(); // Renamed to avoid shadowing

      if (responseData.status.statusCode === 0) {
        setIdUpdate(false);
        setAddressUpdate(false);
        getEmployeeDataa();
        employeeData.documents = employeeData.documents.filter((document) => {
          return document.documentStatus !== 4;
        });
      } 
      else {
        Swal.fire({
          icon: "error",
          title: responseData?.status?.errorTitle || "Error",
          html:
            `<strong style="display: block; margin-bottom: 10px;">${responseData?.status?.messageDescription || ""
            }</strong>` +
            (responseData?.status?.suggestion
              ? `<span>${responseData.status.suggestion}</span><br>
                  <a href="${responseData.status?.refUrl}" target="_blank" rel="noopener noreferrer" style="display: block; margin-top: 10px;">
                    ${responseData?.status?.refUrl ? `Reference: ${responseData.status?.refUrl}` : ''}
                  </a>`
              : responseData?.status?.refUrl
                ? `<a href="${responseData.status?.refUrl}" target="_blank" rel="noopener noreferrer">${responseData.status?.refUrl}</a>`
                : ""
            ), 
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      // setEmployeeCode(data.message.employeeCode)
    } catch (error) {
      console.log(error);
    }
  };

  const TaxDataa = async () => {
    try {
      const url = `${API_URL}/employee/managePayrollStructure`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...TaxData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setResults(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const checkAppBasic = (): boolean => {
    if (!data.appBasic.empName) {
      return false;
    }
    if (!data.empDate.date) {
      return false;
    }

    if (
      !data.empEmail.email ||
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        data.empEmail.email
      )
    ) {
      return false;
    }

    if (!data.empPhone.phone || !isPhoneValid(data.empPhone.phone)) {
      return false;
    }
    // if (!data.empAddressProof.addressProof) {
    //   return false;
    // }
    // if (!data.idProof.idProof) {
    //   return false;
    // }
    if (!data.empAddress.address) {
      return false;
    }
    if (!data.empBloodGroup.bloodGroup) {
      return false;
    }
    if (
      !data.emeContact.emeContact ||
      !isPhoneValid(data.emeContact.emeContact)
    ) {
      return false;
    }
    if (!data.doj.doj) {
      return false;
    }

    return true;
  };

  const checkAppData = (): boolean => {
    if (!data.employeeID.employeeID) {
      return false;
    }
    if (!data.designation.designation) {
      return false;
    }
    if (!data.department.department) {
      return false;
    }
    if (!data.location.location) {
      return false;
    }
    if (!data.accNumber.accNumber || data.accNumber.accNumber.length < 10) {
      return false;
    }
    if (!data.accName.accName) {
      return false;
    }
    if (!data.accIdentifier.accIdetifier) {
      return false;
    }
    return true;
  };

  const checkPayroll = (): boolean => {
    if (
      data.monthlySalary.monthlySalary &&
      data.monthlySalary.monthlySalary === "0"
    ) {
      return false;
    }
    if (!data.pensionDeductions.pensionDeductions) {
      return false;
    }
    if (!data.travelAllowance.travelAllowance) {
      return false;
    }
    if (!data.medicalDependents.medicalDependents) {
        return false;
    }
    if (!data.otherAllowance.otherAllowance) {
      return false;
    }
    // if (!data.age.age) {
    // return false
    // }
    if (!data.annualBonus.annualBonus) {
      return false;
    }
    return true;
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
  };

  const nextStep = () => {
    setHasError(false);
    if (!stepper.current) {
      return;
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkAppBasic()) {
        setHasError(true);
        return;
      }
    }
    if (stepper.current.getCurrentStepIndex() === 2) {
      if (!checkAppData()) {
        setHasError(true);
        return;
      }
      UpdateEmployeeDataa(data);
    }
    if (stepper.current.getCurrentStepIndex() === 3) {
      if (!checkPayroll()) {
        setHasError(true);
        return;
      }
      TaxDataa();
    }

    stepper.current.goNext();
  };

  const submit = () => {
    Api.api();
    reset();
    handleClose();
  };
  const reset = () => {
    updateData(defaultCreateAppData);
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={() => {
        handleClose();
        updateData(defaultCreateAppData);
        reset();
      }}
      onEntered={loadStepper}
    >
      <div className="modal-header">
        <h3 className="m-0 fw-bold">Edit Employee</h3>
        {/* begin::Close */}
        {/* <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
          onClick={() => {
            handleClose();

            reset();

            updateData(defaultCreateAppData);
          }}
        >
          <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
        </div> */}
        {/* end::Close */}
      </div>

      <div className="modal-body py-lg-10 px-lg-10 mb-10">
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
          id="kt_modal_create_app_stepper"
        >
          {/* begin::Aside*/}
          <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
            {/* begin::Nav*/}
            <div className="stepper-nav ps-lg-10">
              {/* begin::Step 1*/}
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                {/* begin::Wrapper*/}
                <div className="stepper-wrapper">
                  {/* begin::Icon*/}
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className="stepper-label">
                    <h3 className="stepper-title">Enter employee data </h3>

                    {/* <div className='stepper-desc'></div> */}
                  </div>
                  {/* end::Label*/}
                </div>

                <div className="stepper-line h-40px"></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className="stepper-item" data-kt-stepper-element="nav">
                {/* begin::Wrapper*/}
                <div className="stepper-wrapper">
                  {/* begin::Icon*/}
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">2</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className="stepper-label">
                    <h3 className="stepper-title">Enter Company data </h3>

                    {/* <div className='stepper-desc'>Define your app framework</div> */}
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className="stepper-line h-40px"></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className="stepper-item" data-kt-stepper-element="nav">
                {/* begin::Wrapper*/}
                <div className="stepper-wrapper">
                  {/* begin::Icon*/}
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className="stepper-label">
                    <h3 className="stepper-title">Enter Payroll data </h3>

                    {/* <div className='stepper-desc'>Select the app database type</div> */}
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className="stepper-line h-40px"></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className="stepper-item" data-kt-stepper-element="nav">
                {/* begin::Wrapper*/}
                <div className="stepper-wrapper">
                  {/* begin::Icon*/}
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className="stepper-label">
                    <h3 className="stepper-title">Confirmation</h3>

                    {/* <div className='stepper-desc'>Provide storage details</div> */}
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                {/* <div className='stepper-line h-40px'></div> */}
                {/* end::Line*/}
              </div>
              {/* end::Step 4*/}

              {/* begin::Step 5*/}
              {/* <div className='stepper-item' data-kt-stepper-element='nav'> */}
              {/* begin::Wrapper*/}
              {/* <div className='stepper-wrapper'> */}
              {/* begin::Icon*/}
              {/* <div className='stepper-icon w-40px h-40px'> */}
              {/* <i className='stepper-check fas fa-check'></i> */}
              {/* <span className='stepper-number'>5</span> */}
              {/* </div> */}
              {/* end::Icon*/}

              {/* begin::Label*/}
              {/* <div className='stepper-label'> */}
              {/* <h3 className='stepper-title'></h3> */}

              {/* <div className='stepper-desc'>Review and Submit</div> */}
              {/* </div> */}
              {/* end::Label*/}
              {/* </div> */}
              {/* end::Wrapper*/}
              {/* </div> */}
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className="flex-row-fluid py-lg-5 px-lg-15">
            {/*begin::Form */}
            <form noValidate id="kt_modal_create_app_form">
              <PayrollStepperModal
                data={data}
                updateData={updateData}
                setAddressUpdate={setAddressUpdate}
                setIdUpdate={setIdUpdate}
                hasError={hasError}
              />
              <SteppperModal2
                data={data}
                updateData={updateData}
                setAddressUpdate={setAddressUpdate}
                setIdUpdate={setIdUpdate}
                hasError={hasError}
              />
              <SteppperModal3
                data={data}
                updateData={updateData}
                setAddressUpdate={setAddressUpdate}
                setIdUpdate={setIdUpdate}
                hasError={hasError}
              />
              <SteppperModal4
                data={data}
                updateData={updateData}
                setAddressUpdate={setAddressUpdate}
                setIdUpdate={setIdUpdate}
                hasError={hasError}
              />

              {/*begin::Actions */}
              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  <button
                    type="button"
                    className="btn btn-lg btn-light-primary me-3"
                    data-kt-stepper-action="previous"
                    onClick={prevStep}
                  >
                    <KTIcon iconName="arrow-left" className="fs-3 me-1" />{" "}
                    Previous
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn"
                    data-kt-stepper-action="submit"
                    onClick={submit}
                  >
                    Finish{" "}
                    <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                  </button>

                  <button
                    type="button"
                    className="btn btn-lg btn"
                    data-kt-stepper-action="next"
                    onClick={nextStep}
                  >
                    Next{" "}
                    <KTIcon iconName="arrow-right" className="fs-3 ms-1 me-0" />
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  );
};
