/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from "../IAppModels";
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Assuming you're using FontAwesome for the cloud icon
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons"; // Assuming you're using FontAwesome's Cloud Upload Alt icon
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PinInput from "react-pin-input";
import { ApiData } from "../../bulkPaymentDashboard";
import dayjs from "dayjs";
import { enhancedFetch } from "../../../../modules/auth/core/_requests";
import { RuleMessage } from "../../../TransferMoney/TransferModal";
import { ThreeDots } from "react-loader-spinner";
import { Accounts } from "../../../../interface/Interface";
const fileTypes = ["xlsx"];

export const dataOutcome = {
  category: "",
  name: "",
  description: "",
  schedule: "",
  date: "",
  acc: "",
  amount: "",
  uploadType: "Upload",
  fileUploadId: "",
  cancel: false,
  fileExist: false,
};

export interface Step3Ref {
  handleFileRemove: () => void;
}

const Step3 = forwardRef<Step3Ref, Omit<StepProps, 'setButton'> & { setButton: (label: string) => void; setIsNextDisabled?: (value: boolean) => void; }>(
  ({ data, updateData, hasError, txnPin, setTxnPin, setButton, setIsNextDisabled }, ref) => {


    const API_URL = process.env.REACT_APP_WEB_API_URL;
    const baseCurrency = sessionStorage.getItem("currency");

    const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
    const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
    const token = tokenData ? tokenData.token : null;
    const [file, setFile] = useState<File | null>(null);
    const [valid, setValid] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [id, setId] = useState("");
    const [ruleData, setRuleData] = useState<RuleMessage | null>(null)
    const [loading, setLoading] = useState(false)
    let pindata: string;
    dataOutcome.category = data?.category.category;
    dataOutcome.name = data?.name.name;
    dataOutcome.description = data?.description.description;
    dataOutcome.schedule = data?.schedule.schedule;
    dataOutcome.date = dayjs(data?.date.date)
      .utcOffset(330)
      .format("YYYY-MM-DD HH:mm:ss");
    dataOutcome.acc = data?.account.account;

    useEffect(() => {
      setFile(null);
      setValid(false);
      dataOutcome.cancel = false;
      dataOutcome.fileExist = false;
      setDataList([]);
    }, [dataOutcome.cancel]);

    const handleChange = (uploadedFile: File | null) => {
      if (uploadedFile) {
        setLoading(true)
        setFile(uploadedFile);
        const formData = new FormData();
        formData.append("channel", "WEB");
        formData.append("bulkFile", uploadedFile);
        formData.append("name", dataOutcome?.name);
        formData.append("description", dataOutcome?.description);
        formData.append("accountNumber", dataOutcome?.acc);
        if (dataOutcome.schedule === "Later") {
          formData.append("jobScheduledTime", dataOutcome?.date);
        }
        // if(dataOutcome.schedule==="Now"){
        //   formData.append("jobScheduledTime","2023-11-10 15:59:00")
        // }
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        // Create the POST request options
        const requestOptions = {
          method: "POST",
          headers: headers,
          body: formData,
        };

        let apiUrlDAta = "";
        if (data.category.category === "Employee Payment") {
          apiUrlDAta = "uploadAndValidateEmployeePaymentFile";
        } else {
          apiUrlDAta = "uploadAndValidateVendorPaymentFile";
        }
        // Send the POST request
        fetch(`${API_URL}/bulk/${apiUrlDAta}`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result?.status?.statusCode === 0) {
              setValid(true);
              setDataList(result?.message.validationMsgList);
              dataOutcome.amount = result.message.amount;
              dataOutcome.fileUploadId = result?.message?.fileUploadId;
              dataOutcome.fileExist = true;
              getRules()
              setLoading(false)
            }
            else {
              handleFileRemove()
              Swal.fire({
                icon: "error",
                title: result?.status?.errorTitle || "Error",
                html:
                  `<strong style="display: block; margin-bottom: 10px;">${result?.status?.messageDescription || ""
                  }</strong>` +
                  (result?.status?.suggestion
                    ? `<span>${result.status.suggestion}</span><br>
                        <a href="${result.status?.refUrl}" target="_blank" rel="noopener noreferrer" style="display: block; margin-top: 10px;">
                          ${result?.status?.refUrl ? `Reference: ${result.status?.refUrl}` : ''}
                        </a>`
                    : result?.status?.refUrl
                      ? `<a href="${result.status?.refUrl}" target="_blank" rel="noopener noreferrer">${result.status?.refUrl}</a>`
                      : ""
                  ),
                allowEscapeKey: true,
                allowEnterKey: true,
              });
              setLoading(false)
            }
          })
          .catch((error) => console.error("error", error));
      } else {
        console.error("No file selected.");
      }
    };

    const [accounts, setAccounts] = useState<Accounts[]>([]);

    const getAccounts = async () => {
      try {
        const url = `${API_URL}/transaction/getAccounts`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({});

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        setAccounts(data.results);
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getAccounts();
    }, []);

    const handleFileRemove = () => {
      setFile(null);
      setValid(false);
      dataOutcome.fileExist = false;
    };

    useImperativeHandle(ref, () => ({
      handleFileRemove,
    }));

    const navigate = useNavigate();

    const showPinInputModal = async () => {
      const MySwal = withReactContent(Swal);

      const handlePinChange = (value: string) => {
        if (value.length <= 4) {
          pindata = value;
        }
      };

      // Customize the Swal modal
      await MySwal.fire({
        title: "Enter Your Pin",
        customClass: {
          input: "pin-input-class",
        },
        confirmButtonColor: "#007bff",
        html: (
          <>
            <p className="mb-4">Enter your PIN to confirm</p>
            <PinInput
              length={4}
              inputStyle={{ borderRadius: "5px", marginLeft: "10px" }}
              // ref={pinInputRef}
              onChange={handlePinChange}
              focus={true}
              autoSelect={false}
              secret
            />
          </>
        ),
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        // Prevent dismissing by clicking outside
        preConfirm: () => {
          if (pindata.length === 4) {
            return;
          } else {
            Swal.resetValidationMessage();
            Swal.showValidationMessage("Please enter a 4-digit PIN");
            return false;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          TransactionPin();
        }
      });
    };

    data.submit.submit = showPinInputModal;

    const TransactionPin = async () => {
      let apiUrlDAta = "";
      if (data.category.category === "Employee Payment") {
        apiUrlDAta = "processEmployeePaymentFile";
      } else {
        apiUrlDAta = "processVendorPaymentFile";
      }

      try {
        const url = `${API_URL}/bulk/${apiUrlDAta}`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          fileUploadId: dataOutcome?.fileUploadId,
          transactionPIN: pindata,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data.status.statusCode === 0) {
          if (data.status.statusCode === 0) {
            const hasJobScheduledTime = !!data?.message?.jobScheduledTime;

            Swal.fire({
              icon: "success",
              title: hasJobScheduledTime
                ? "Bulk Payment Scheduled!"
                : "Transfer Processing!",
              text: hasJobScheduledTime
                ? "Your payment has been scheduled!"
                : "Payment transfer successfully processing!",
              showCancelButton: false,
              confirmButtonText: "Ok",
              cancelButtonText: "Close",
              allowEscapeKey: true,
              allowEnterKey: true,
            });
            setTimeout(() => {
              ApiData.api();
            }, 1000);
          }
        } else {
          // Swal.fire({
          //   icon: "warning",
          //   title: "Error",
          //   text: data?.status?.messageDescription,
          //   showCancelButton: false,
          //   confirmButtonText: "Ok",
          //   allowEscapeKey: true,
          //   allowEnterKey: true,
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     showPinInputModal();
          //   }
          // });
          showPinInputModal();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getRules = async () => {
      try {
        const url = `${API_URL}/transaction/checkTxnDetails`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };

        const body = JSON.stringify({
          amount: dataOutcome.amount,
          currency: baseCurrency,
          transactionType: "TRANSFER_INTRA_BANK"
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let dataa = await response.json();
        setRuleData(dataa?.message)
        if (dataa.message?.processingType === "PROCESS") {
          if (data?.schedule?.schedule === "Later") {
            setButton("Schedule");
          }
          else {
            setButton("Pay Now");
          }
        }

        if (dataa.message?.processingType === "SEND_FOR_APPROVAL") {
          setButton("Initiate");
        }

        const isDisabled =
          ((dataa.message?.amount ?? 0)) >
          (Number(accounts.find(account => account.accountNumber === data.account.account)?.balance) || 0);


        await new Promise((resolve) => {
          setIsNextDisabled?.(isDisabled);
          resolve(true);
        });


        setLoading(false)
      } catch (error) {
        console.log(error);
      }
    };








    return (
      <>
        {/*begin::Step 3 */}
        <div className="pb-5" data-kt-stepper-element="content">
          <div className="w-100  mb-20">
            {data.category.category === "Employee Payment" && (
              <div className="d-flex flex-column ">
                {/* <button className='btn' onClick={()=>{dataOutcome.uploadType="Upload";navigate('/money-movement/create-bulkpayments')}} >Upload excel file</button>
                 */}
                <a
                  href="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/link/employee_pay.xlsx"
                  className="p-2"
                  download="Sample_File.xlsx"
                >
                  Download Sample File
                </a>
                {file ? (
                  <div>
                    <div className="d-flex flex-column gap-5">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "2px dashed #888",
                          borderRadius: "8px",
                          padding: "20px",
                        }}
                      >
                        <div
                          className="card p-5 bg-secondary d-flex flex-column align-items-center justify-content-center "
                          style={{ width: "250px" }}
                        >
                          <div className="d-flex flex-row">
                            <p className="fw-bold">{file.size}&nbsp;</p>
                            <span>KB</span>
                          </div>
                          <div className="text-container my-3">
                            <p
                              className="text-truncate"
                              style={{ maxWidth: "15rem" }}
                            >
                              {file.name}
                            </p>
                          </div>
                          <p
                            className="text-decoration-underline text-danger cursor-pointer"
                            onClick={handleFileRemove}
                          >
                            Remove
                          </p>
                        </div>
                      </div>
                    </div>
                    {loading &&
                      <div className="d-flex flex-row justify-content-center">
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#0000FF"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    }
                    {/* {ruleData && !loading && <div className="card shadow-sm p-5 mt-2">
                      <p> <span className="fw-bold">Service Charge :</span>&nbsp;<span >{ruleData?.serviceCharge}&nbsp;{baseCurrency}</span></p>

                      <p><span className="fw-bold">Service Charge Tax : </span>&nbsp;<span >{ruleData?.serviceChargeTax}&nbsp;{baseCurrency}</span></p>
                      <p><span className="fw-bold">Service Charge Amount : </span>&nbsp;<span >{ruleData?.serviceChargeAmt}&nbsp;{baseCurrency}</span></p>
                      <p><span className="fw-bold">Total : </span>&nbsp;<span >{Number(dataOutcome.amount) + Number(ruleData?.serviceChargeAmt)}&nbsp;{baseCurrency}</span></p>
                      {ruleData?.processingType === "SEND_FOR_APPROVAL" &&
                        <p> <span className="fw-bold">This will be sent to {ruleData?.actorName}&nbsp;for approval</span></p>

                      }
                    </div>} */}

                    <div>
                      {(Number(dataOutcome.amount)) > (Number(accounts.find(account => account.accountNumber === data.account.account)?.balance) || 0) && (
                        <p className="text-danger mt-2">Amount exceeds available balance</p>
                      )}
                    </div>


                  </div>
                ) : (
                  <>
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "2px dashed #888",
                          borderRadius: "8px",
                          padding: "20px",
                          // marginBottom: "100px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCloudUploadAlt}
                          size="4x"
                          style={{ color: "#888" }}
                        />
                        <p style={{ marginTop: "10px", color: "#888" }}>
                          Drag & drop files here, or click to select files
                        </p>
                      </div>
                    </FileUploader>
                    <p className="required text-primary mt-2 ms-2">
                      Only .xlsx files are allowed
                    </p>
                    {!dataOutcome.fileExist && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      File is required
                    </div>
                  </div>
                )}
                  </>

                )}
            

                {valid && (
                  <div className="p-5">
                    {dataList.map((item, index) => (
                      <div className="p-2" key={index}>
                        <div className="form-check form-check-custom form-check-solid form-check-sm">
                          <input
                            className="form-check-input bg-success"
                            type="checkbox"
                            value=""
                            id={`flexRadioLg${index}`} // Ensure a unique ID for each checkbox
                            checked={valid} // Update this based on the item's validity if needed
                            disabled={!valid} // Update this based on the item's validity if needed
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexRadioLg${index}`}
                          >
                            {item}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {data.category.category === "Vendor Payment" && (
              <div className="d-flex flex-column">
                <a
                  href="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/link/vendorPay.xlsx"
                  className=""
                  download="Sample_File.xlsx"
                >
                  Download Sample File
                </a>
                {file ? (
                  <div>
                    <div className="d-flex flex-column gap-5">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "2px dashed #888",
                          borderRadius: "8px",
                          padding: "20px",
                        }}
                      >
                        <div
                          className="card p-5 bg-secondary d-flex flex-column align-items-center justify-content-center "
                          style={{ width: "250px" }}
                        >
                          <div className="d-flex flex-row">
                            <p className="fw-bold">{file.size}&nbsp;</p>
                            <span>KB</span>
                          </div>
                          <div className="text-container my-3">
                            <p
                              className="text-truncate"
                              style={{ maxWidth: "15rem" }}
                            >
                              {file.name}
                            </p>
                          </div>
                          <p
                            className="text-decoration-underline text-danger cursor-pointer"
                            onClick={handleFileRemove}
                          >
                            Remove
                          </p>
                        </div>
                      </div>
                    </div>
                    {loading &&
                      <div className="d-flex flex-row justify-content-center">
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#0000FF"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    }
                    {/* {ruleData && !loading && <div className="card shadow-sm p-5 mt-2">
                      <p> <span className="fw-bold">Service Charge :</span>&nbsp;<span >{ruleData?.serviceCharge}&nbsp;{baseCurrency}</span></p>

                      <p><span className="fw-bold">Service Charge Tax : </span>&nbsp;<span >{ruleData?.serviceChargeTax}&nbsp;{baseCurrency}</span></p>
                      <p><span className="fw-bold">Service Charge Amount : </span>&nbsp;<span >{ruleData?.serviceChargeAmt}&nbsp;{baseCurrency}</span></p>
                      <p><span className="fw-bold">Total : </span>&nbsp;<span >{Number(dataOutcome.amount) + Number(ruleData?.serviceChargeAmt)}&nbsp;{baseCurrency}</span></p>
                      {ruleData?.processingType === "SEND_FOR_APPROVAL" &&
                        <p> <span className="fw-bold">This will be sent to {ruleData?.actorName}&nbsp;for approval</span></p>

                      }
                    </div>} */}
                    <div>
                      {(Number(dataOutcome.amount)) > (Number(accounts.find(account => account.accountNumber === data.account.account)?.balance) || 0) && (
                        <p className="text-danger mt-2">Amount exceeds available balance</p>
                      )}
                    </div>
                  </div>

                ) : (
                  <>
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "2px dashed #888",
                          borderRadius: "8px",
                          padding: "20px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCloudUploadAlt}
                          size="4x"
                          style={{ color: "#888" }}
                        />
                        <p style={{ marginTop: "10px", color: "#888" }}>
                          Drag & drop files here, or click to select files
                        </p>
                      </div>
                    </FileUploader>
                    <p className="required text-primary mt-2 ms-2">
                      Only .xlsx files are allowed
                    </p>
                    {!dataOutcome.fileExist && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      File is required
                    </div>
                  </div>
                )}
                  </>
                )}
                
                {valid && (
                  <div className="p-5">
                    {dataList.map((item, index) => (
                      <div className="p-2" key={index}>
                        <div className="form-check form-check-custom form-check-solid form-check-sm">
                          <input
                            className="form-check-input bg-success"
                            type="checkbox"
                            value=""
                            id={`flexRadioLg${index}`} // Ensure a unique ID for each checkbox
                            checked={valid} // Update this based on the item's validity if needed
                            disabled={!valid} // Update this based on the item's validity if needed
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexRadioLg${index}`}
                          >
                            {item}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {/* <div className="container">
                  <div className="row">
                    <div className="col-5">
                      <hr />
                    </div>
                    <div className="col-2 text-center">
                      <span>OR</span>
                    </div>
                    <div className="col-5">
                      <hr />
                    </div>
                  </div>
                </div> */}
                {/* <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    dataOutcome.uploadType = "Manual";
                    navigate("/money-movement/create-bulkpayments", {
                      state: {
                        data: data.schedule.schedule,
                        accountBalance: accounts.find(
                          (account) => account.accountNumber === data.account.account
                        )?.balance,
                      },
                    });


                  }}
                  disabled={valid}
                >
                  Add recipients manually
                </button> */}
              </div>
            )}
          </div>
        </div>

        {/*end::Step 3 */}
      </>
    );
  }
);

export { Step3 };
