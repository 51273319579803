import { FC } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { ToastContainer } from "react-toastify";
import XLogo from "../../../_metronic/assets/Icons/xfin.png";
import { useNavigate } from "react-router";

const ViewLinks: FC = () => {
  const navigate = useNavigate();
  const validate = useFormik({
    initialValues: {
      token: "",
    },
    validationSchema: Yup.object().shape({
      token: Yup.string()
        .matches(
          /^[A-Za-z0-9]+$/,
          "Only letters (A-Z) (a-z), digits (0-9) are allowed"
        )
        .required("Verification code is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const data = {
        token: values?.token,
      };

      try {
        setSubmitting(true);
        navigate("/view-documents/list");
        // await withdrawMoney(values?.token);
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
        resetForm();
      }
    },
  });

  return (
    <>
      <style>
        {`
        .responsive-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

.responsive-card {
  width: 100%;
  max-width: 600px;
  height: auto;
  min-height: 300px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .responsive-card {
    padding: 1rem;
    min-height: 200px;
  }
}
        `}
      </style>
      <div className="responsive-container">
        <div className="responsive-card">
          <img alt="Logo" src={XLogo} className="h-50px mb-4" />
          <div className="w-75">
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <p>Verification Code has been sent to tes***@yopmail.com</p>
            </div>
            <form onSubmit={validate.handleSubmit}>
              <div className="mt-3 p-10">
                <div className="mb-3">
                  <label className="form-label required">
                    Enter Verification Code
                  </label>
                  <input
                    type="text"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          validate.touched.token && validate.errors.token,
                      },
                      {
                        "is-valid":
                          validate.touched.token && !validate.errors.token,
                      }
                    )}
                    placeholder="Enter code"
                    name="token"
                    value={validate.values.token}
                    onChange={validate.handleChange}
                    onBlur={validate.handleBlur}
                    maxLength={10}
                  />
                  {validate.touched.token && validate.errors.token ? (
                    <div className="text-danger mt-1">
                      {validate.errors.token}
                    </div>
                  ) : null}
                </div>
                <div className="d-flex justify-content-center gap-5 mt-10">
                  <button
                    className="btn btn-primary"
                    type="button"
                    // disabled={validate.isSubmitting}
                  >
                    Resend
                  </button>
                  <button
                    className="btn"
                    type="submit"
                    disabled={validate.isSubmitting}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} />
    </>
  );
};

export { ViewLinks };
