import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { Modal } from "bootstrap";
import { array } from "./Folder";
import folder from "../../../_metronic/assets/all-serviceIcons/folder.svg";
import pdf from "../../../_metronic/assets/all-serviceIcons/pdf-file.svg";
import docx from "../../../_metronic/assets/all-serviceIcons/docx-file.svg";
import jpeg from "../../../_metronic/assets/all-serviceIcons/image-files.svg";

const AddWorkspace: FC = () => {
  const [files, setFiles] = useState<typeof array>([]);
  const navigate = useNavigate();

  const handleUpload = () => {
    const modalElement = document.getElementById("kt_modal_upload_file");

    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    } else {
      console.error("Modal element not found");
    }
  };

  const addFormik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      //   files:Yup.array().required('Choose at least 1 File/Folder')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log("submit called");
    },
  });

  const handleCheck = (file: (typeof array)[number]) => {
    setFiles((prev) =>
      prev.some((f) => f.fileGuid === file.fileGuid)
        ? prev.filter((f) => f.fileGuid !== file.fileGuid)
        : [...prev, file]
    );
  };

  const handleRemove = (file: (typeof array)[number]) => {
    setFiles((prev) => prev.filter((f) => f.fileGuid !== file.fileGuid));
  };

  return (
    <>
      {" "}
      <h4 className="mb-4">Document Room - Add Workspace</h4>{" "}
      <div className="card p-10">
        <div className="">
          <div className="d-flex mb-5">
            <label className="w-25 fs-5">Name</label>
            <input
              placeholder="Enter name"
              maxLength={50}
              {...addFormik.getFieldProps("name")}
              className="form-control w-75"
              type="text"
              name="name"
              onChange={addFormik.handleChange}
              onBlur={addFormik.handleBlur}
              value={addFormik.values.name}
              autoComplete="off"
            />

            {addFormik.touched.name && addFormik.errors.name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{addFormik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex">
            <label className="w-25">File/Folder</label>
            <div className="card p-5 shadow-sm w-75 max-h-300px overflow-scroll flex-column gap-5">
              {files?.map((file, index) => (
                <div key={index} className="d-flex align-items-center">
                  {file.type === "folder" ? (
                    <img
                      src={folder}
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                  ) : (
                    <img
                      src={
                        file?.type === "pdf"
                          ? pdf
                          : file?.type === "jpeg"
                            ? jpeg
                            : docx
                      }
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                  )}
                  <p className="ms-5 fs-5 d-flex mb-0 w-50">{file?.name}</p>
                  <i
                    className="bi bi-trash text-danger fs-5 fw-bold cursor-pointer"
                    onClick={() => handleRemove(file)}
                  ></i>
                </div>
              ))}
              <div>
                <button className="btn btn-sm" onClick={handleUpload}>
                  Add File/Folder
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button
              className="btn"
              onClick={() => navigate("/documentRoom/workspace")}
            >
              Create
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="kt_modal_upload_file"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div
              className="modal-header d-flex justify-content-between"
              id="kt_modal_upload_file_header"
            >
              <h2 className="fw-bold">Add File/Folder</h2>
            </div>

            <div className="modal-body scroll-y mx-5 mx-xl-15">
              <div
                className="card flex-column p-5 shadow-sm mb-5"
                style={{ maxHeight: "300px", overflow: "scroll" }}
              >
                {array?.map((file, index) => (
                  <div key={index} className="d-flex gap-5 p-3">
                    <div className="w-75 text-dark text-hover-primary cursor-pointer gap-2 d-flex">
                      {file.type === "folder" ? (
                        <img
                          src={folder}
                          alt=""
                          style={{ width: "18px", height: "18px" }}
                        />
                      ) : (
                        <img
                          src={
                            file?.type === "pdf"
                              ? pdf
                              : file?.type === "jpeg"
                                ? jpeg
                                : docx
                          }
                          alt=""
                          style={{ width: "18px", height: "18px" }}
                        />
                      )}
                      <p>{file.name}</p>
                    </div>
                    <label className="form-check form-check-custom form-check-sm fs-7 w-25">
                      <input
                        className="form-check-input cursor-pointer border border-dark"
                        type="checkbox"
                        checked={files.some(
                          (f) => f.fileGuid === file.fileGuid
                        )}
                        onChange={() => handleCheck(file)}
                      />{" "}
                    </label>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center">
                <button type="button" data-bs-dismiss="modal" className="btn">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AddWorkspace };
