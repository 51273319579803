import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  DrawerComponent,
  MenuComponent,
} from "../../../_metronic/assets/ts/components";
import { useFormik } from "formik";
import { Modal } from "bootstrap";
import { OverlayTrigger, Popover } from "react-bootstrap";

const SharingHistory: FC = () => {
  const [add, setAdd] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [file, setFile] = useState<File | null>();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const location = useLocation();

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // Calculate pagination
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   const currentData = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();
  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(array?.length / itemsPerPage);
    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const handleOpenModal = () => {
    const modalElement = document.getElementById("change_exp_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  const handleOpenAccessModal = () => {
    const modalElement = document.getElementById("access_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  const array = [
    {
      name: "invoices",
      type: "Workspace",
      createdDate: "14 Aug 2024, 5:20 pm",
      expiryDate: "19 Aug 2024, 5:20 pm",
      sharedwith: "Akhil,Ruthra,Sathya,Ajinkya",
      email:
        "akhil@yopmail.com,ruthra@yopmail.com,sathya@yopmail.com,ajinkya@yopmail.com",
      accessedTime: "17 Aug 2024 - 4:00 pm,-,15 Aug 2024 - 1:00 pm,-",
      status: "Accessed,Yet to Access,Accessed,Yet to Access",
    },
    {
      name: "payslips.pdf",
      type: "File",
      sharedwith: "Ruthra",
      email: "ruthra@yopmail.com",
      status: "Yet to access",
      accessedTime: "-",
      createdDate: "12 Sep 2024, 10:30 am",
      expiryDate: "22 Sep 2024, 10:30 am",
    },
    {
      name: "AOA.docx",
      type: "Workspace",
      sharedwith: "Sambit",
      email: "sambit@yopmail.com",
      status: "Link Expired",
      accessedTime: "14 Jun 2024, 4:00 pm",
      createdDate: "4 Jun 2024, 11:30 am",
      expiryDate: "24 Jun 2024, 11:30 am",
    },
    {
      name: "License.jpeg",
      type: "File",
      sharedwith: "Ajinkya",
      email: "ajinkya@yopmail.com",
      status: "Link Expired",
      accessedTime: "-",
      createdDate: "11 Feb 2024, 2:40 pm",
      expiryDate: "21 Feb 2024, 2:40 pm",
      fileGuid: "asdr23454esdf4",
    },
    {
      name: "ID.jpeg",
      type: "File",
      sharedwith: "Akhil",
      email: "akhil@yopmail.com",
      status: "Accessed",
      accessedTime: "17 Aug 2024, 4:00 pm",
      createdDate: "1 Jun 2024, 11:05 am",
      expiryDate: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf5",
    },
    {
      name: "business_list.pdf",
      type: "Workspace",
      sharedwith: "Akhil",
      email: "akhil@yopmail.com",
      status: "Accessed",
      accessedTime: "17 Aug 2024, 4:00 pm",
      createdDate: "20 May 2024, 11:05 am",
      expiryDate: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf6",
    },
    {
      name: "vendor_list.docx",
      type: "Workspace",
      sharedwith: "Akhil",
      email: "akhil@yopmail.com",
      status: "Accessed",
      accessedTime: "17 Aug 2024, 4:00 pm",
      createdDate: "20 May 2024, 11:05 am",
      expiryDate: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf7",
    },
    {
      name: "invoices.jpeg",
      type: "Workspace",
      sharedwith: "Akhil",
      email: "akhil@yopmail.com",
      status: "Accessed",
      accessedTime: "17 Aug 2024, 4:00 pm",
      createdDate: "19 Jun 2024, 11:05 am",
      expiryDate: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf8",
    },
  ];

  const items = [
    "akhil@yopmail.com",
    "ruthra@yopmail.com",
    "sathya@yopmail.com",
    "ajinkya@yopmail.com",
  ];

  const [checked, setChecked] = useState(0);
  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      setChecked((prev) => prev + 1);
    } else {
      setChecked((prev) => prev - 1);
    }
  };

  useEffect(() => {
    DrawerComponent.reinitialization();
  }, []);

  const TableComponent = ({ sharedWith, email, accessedTime, status }: any) => {
    const parseValues = (value: string) =>
      value.split(",").map((v) => v.trim());

    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            {/* <th>Shared With</th> */}
            <th>Email</th>
            <th>Accessed Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {parseValues(sharedWith).map((person, index) => (
            <tr key={`sharedwith-${index}`}>
              {/* <td>{person}</td> */}
              <td>{parseValues(email)[index] || "-"}</td>
              <td>{parseValues(accessedTime)[index] || "-"}</td>
              <td>
                {" "}
                <p
                  className={`mb-0 text-${parseValues(status)[index] === "Accessed" ? "success" : parseValues(status)[index] === "Link Expired" ? "danger" : "primary"}`}
                >
                  {parseValues(status)[index] || "-"}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {" "}
      <h4 className="mb-4">Document Room - Shared History</h4>{" "}
      <div className="card p-2">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center  position-relative my-1">
            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              data-kt-docs-table-filter="search"
              className="form-control p-3 border-secondary w-250px ps-15"
              placeholder="Search Files & Folders"
              // defaultValue={searchTerm}
              // onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div>
          <div className="d-flex flex-1 align-items-center p-3 gap-10 flex-end">
            {checked > 0 ? (
              <button
                className={`btn btn-danger fs-7 p-3 w-100px`}
                onClick={() =>
                  Swal.fire({
                    icon: "warning",
                    text: "Are you sure you want to delete the selected documents?",
                  })
                }
                //   id="kt_modal_toggle_domestic"
                //   disabled={payAmount < 1}
              >
                Delete ({checked})
              </button>
            ) : (
              <>
                {/* <Authorize hasAnyPermission={["MENU_DOMESTIC_VENDOR_PAY|ADD"]}> */}
                <div className="dropdown">
                  <button
                    className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                    style={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#ebe9eb",
                    }}
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-funnel text-dark fs-2 p-0 m-0"></i>
                  </button>

                  <ul
                    className="dropdown-menu dropdown-menu-end p-10 w-350px"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <p className="fs-4 ">Filter options</p>
                      <hr />
                    </li>
                    <li className="mb-3">
                      <label className="fs-5 mb-2">Item name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter folder name"
                      />
                    </li>
                    <li className="mb-3">
                      <label className="fs-5 mb-2">Shared with</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter name"
                      />
                    </li>
                    <li className="mb-3">
                      <label className="fs-5 mb-2">Item type</label>
                      <select name="" id="" className="form-select">
                        <option value="">Select</option>
                        <option value="1">System Generated</option>
                        <option value="2">User Generated</option>
                        <option value="3">Workspace</option>
                      </select>
                    </li>
                    <li className="mb-3">
                      <label className="fs-5 mb-2">Status</label>
                      <select name="" id="" className="form-select">
                        <option value="">Select</option>
                        <option value="1">Yet to access</option>
                        <option value="2">Accessed</option>
                        <option value="3">Link Expired</option>
                      </select>
                    </li>
                    <li className="mb-5">
                      <label className="fs-5 mb-2">Last modified</label>
                      <input type="date" className="form-control" />
                    </li>
                    <li>
                      <div className="d-flex justify-content-end">
                        {" "}
                        <button
                          className="btn btn-md"
                          type="button"
                          // onClick={() => setToggle(true)}
                        >
                          Apply
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* </Authorize> */}
                {/* <Authorize hasAnyPermission={["MENU_DOMESTIC_VENDOR_PAY|ADD"]}> */}
                {/* <button
                  className={`btn fs-7 p-3 w-100px`}
                  onClick={() => handleUpload()}
                >
                  Upload Files
                </button> */}
                {/* </Authorize> */}
              </>
            )}
          </div>
        </div>

        {array && array.length > 0 ? (
          <table
            className="table align-middle border rounded table-row-dashed fs-6 g-5"
            style={{
              height: "440px",
              overflowY: "scroll",
            }}
          >
            <thead className="bg-gray-200">
              <tr className="text-start text-dark-600 fw-bold fs-6 ">
                {/* <th className="px-3">
                  {" "}
                  <label className="form-check form-check-custom form-check-sm fs-7">
                    <input
                      className="form-check-input cursor-pointer border border-dark"
                      type="checkbox"
                      // checked={selectAll}
                      // onClick={handleSelectAll}
                    />{" "}
                  </label>
                </th> */}

                <th className="min-w-200px p-5">Item Name</th>
                <th className="min-w-200px p-5">Item Type</th>
                <th className="min-w-100px p-5">Shared with</th>
                <th className="min-w-100px p-5">Validity</th>
                {/* <th className="min-w-100px p-5">Status</th> */}
                <th className="min-w-100px p-5">Action</th>
              </tr>
            </thead>

            <tbody>
              {array && array.length > 0 ? (
                array.map((item, index) => (
                  <tr
                    //   key={index}
                    onMouseOver={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "#f1f1f2";
                    }}
                    onMouseOut={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "white";
                    }}
                  >
                    {/* <td className="px-3">
                      <label className="form-check form-check-custom form-check-sm fs-7">
                        <input
                          className="form-check-input cursor-pointer border border-dark"
                          type="checkbox"
                          // checked={invoiceGuid.includes(item.invoiceGuid)}
                          onClick={(e) => handleCheckClick(e)}
                        />{" "}
                      </label>
                    </td> */}

                    <td className="p-5 text-gray-600 cursor-pointer fw-hover-bold fs-6">
                      <p className="text-gray-600 text-hover-primary fw-hover-bold fs-6 mb-0">
                        {item.name}
                      </p>
                    </td>
                    <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                      <p className="mb-0"> {item.type}</p>
                    </td>
                    <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                      <p className="mb-0">
                        {item.email.length > 20 ? (
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={
                              <Popover
                                className="px-7 py-3 bg-light-secondary"
                                id="popover-trigger-focus"
                              >
                                {item.email.split(",").map((status, idx) => (
                                  <div key={idx}>{status.trim()}</div>
                                ))}
                              </Popover>
                            }
                          >
                            <div>
                              {item.email?.length > 20
                                ? `${item.email.slice(0, 20)}...`
                                : item.email}
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <p className="text-gray-600 text-hover-primary fw-hover-bold fs-6 mb-0">
                            {item.email}
                          </p>
                        )}
                      </p>
                    </td>
                    <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                      <p className="mb-0"> {item.expiryDate}</p>
                    </td>
                    {/* <td className={`p-5 text-gray-600 fs-6`}>
                      <p
                        className={`mb-0 text-${item.status === "Accessed" ? "success" : item.status === "Link Expired" ? "danger" : "primary"}`}
                      >
                        {" "}
                        {item.status.length > 20 ? (
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={
                              <Popover
                                className="p-4 bg-light-secondary"
                                id="popover-trigger-focus"
                              >
                                {item.status.split(",").map((status, idx) => (
                                  <div key={idx}>{status.trim()}</div>
                                ))}
                              </Popover>
                            }
                          >
                            <p
                              className={`mb-0 text-${item.status === "Accessed" ? "success" : item.status === "Link Expired" ? "danger" : "primary"}`}
                            >
                              {item.status?.length > 20
                                ? `${item.status.slice(0, 20)}...`
                                : item.status}
                            </p>
                          </OverlayTrigger>
                        ) : (
                          <p
                            className={`mb-0 text-${item.status === "Accessed" ? "success" : item.status === "Link Expired" ? "danger" : "primary"}`}
                          >
                            {item.status}
                          </p>
                        )}
                      </p>
                    </td> */}

                    <td className="p-5 text-gray-600 p-2">
                      <div className="d-flex gap-5 align-items-center">
                        {/* <button
                          className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "25%",
                            backgroundColor: "#ebe9eb",
                          }}
                          type="button"
                          onClick={() => handleOpenModal()}
                        >
                          
                        </button> */}

                        <div className="dropdown" style={{ width: "20px" }}>
                          <button
                            className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "25%",
                              backgroundColor: "#ebe9eb",
                            }}
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-three-dots text-dark fs-6 p-0 m-0"></i>
                          </button>

                          <ul
                            className="dropdown-menu dropdown-menu-end p-1"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                // onClick={() => setToggle(true)}
                              >
                                View
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                // onClick={() => setEditingId(item.fileGuid)}
                              >
                                Resend
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={handleOpenModal}
                              >
                                Change Expiry
                              </button>
                            </li>

                            {item?.status !== "Link Expired" && (
                              <li>
                                <button
                                  className="dropdown-item text-danger"
                                  type="button"
                                  onClick={handleOpenAccessModal}
                                >
                                  Manage access
                                </button>
                              </li>
                            )}
                          </ul>
                        </div>
                        {/* <div> */}
                        <i
                          className="bi bi-eye text-dark fs-3 p-0 m-0"
                          id="kt_share_modal_toggle"
                        ></i>
                        {/* </div> */}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <div className="text-center py-5">
            <p className="text-gray-600">No Data Found</p>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <div className="p-5">
            <ul className="pagination">
              <li
                className={`page-item double-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={0}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(1)}
                >
                  <i className="previous"></i>
                  <i className="previous"></i>
                </a>
              </li>
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={1}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <i className="previous"></i>
                </a>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item next ${
                  currentPage === Math.ceil(array?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={2}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <i className="next"></i>
                </a>
              </li>
              <li
                className={`page-item double-arrow ${
                  currentPage === Math.ceil(array?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={3}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() =>
                    handlePageChange(Math.ceil(array?.length / itemsPerPage))
                  }
                >
                  <i className="next"></i>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="p-5 d-flex align-items-center">
            <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
              Total: {array?.length}&nbsp;&nbsp;
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              //   onChange={handleItemsPerPageChange}
              style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      <div
        id="kt_share_modal"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_share_modal_toggle"
        data-kt-drawer-close="#kt_modal_share_close"
      >
        <div className="card shadow-none rounded-0 w-100 p-5">
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_share_close"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-dark p-0">Details</h5>
          </div>
          <div className="card-body p-0 mx-5 d-flex flex-column justify-content-between">
            <div>
              {/* {array.map((item, index) => ( */}
              <div>
                <h3>Details</h3>
                {Object.entries(array[0]).map(([key, value]) =>
                  ["sharedwith", "email", "accessedTime", "status"].includes(
                    key
                  ) ? (
                    key === "sharedwith" && (
                      <TableComponent
                        key="table"
                        sharedWith={array[0].sharedwith}
                        email={array[0].email}
                        accessedTime={array[0].accessedTime}
                        status={array[0].status}
                      />
                    )
                  ) : (
                    <React.Fragment key={key}>
                      <div className="d-flex card mb-5 justify-content-between">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <p className="m-0">{key}</p>
                          </div>
                          <div className="d-flex">
                            <p className="m-0 fw-bold">{String(value)}</p>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                )}
              </div>
              {/* ))} */}
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="change_exp_modal">
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          // style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              {" "}
              <h4 className="mb-0">Change Expiry Period</h4>
            </div>
            <div className="modal-body p-10">
              <div className="d-flex flex-column">
                <label htmlFor="language" className="fs-6  mb-3">
                  Expiry Period
                </label>
                <select
                  id="language"
                  name="language"
                  //   onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  //   value={formik.values.language}
                  className="form-select mb-5"
                >
                  <option value="">Select a Period</option>
                  <option value="1">1hr</option>
                  <option value="4">4hrs</option>
                  <option value="8">8hrs</option>
                  <option value="24">24hrs</option>
                  <option value="3">3 days</option>
                  <option value="7">7 days</option>
                  <option value="15">15 days</option>
                  <option value="30">30 days</option>
                  <option value="60">60 days</option>
                  <option value="90">90 days</option>
                </select>
                <button
                  type="button"
                  className={`w-100 rounded btn`}
                  style={{
                    // width: "150px",
                    color: "#ffff",
                  }}
                  onClick={() => {
                    // setCurrentPage(1);
                  }}
                  //   disabled={!toDate}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="access_modal">
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          // style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              {" "}
              <h4 className="mb-0">Update Link access</h4>
            </div>
            <div className="modal-body p-10">
              <div className="d-flex flex-column">
                <div className="card card-body max-h-300px overflow-scroll pt-0">
                  <div className="d-flex justify-content-between mb-3">
                    <p className="m-0 fw-bold fs-5">Shared with</p>
                    {/* <label className="form-check form-check-custom form-check-sm">
                      <input
                        className="form-check-input cursor-pointer border border-dark"
                        type="checkbox"
                        // checked
                        // onClick={(e) => handleCheckClick(e)}
                      />{" "}
                    </label> */}
                  </div>
                  <hr
                    className="mt-0"
                    style={{
                      borderStyle: "dashed",
                    }}
                  />
                  {items?.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between mb-3 align-items-center"
                    >
                      <p className="m-0">{item}</p>
                      <i className="bi bi-trash fs-6 text-danger cursor-pointer"></i>
                    </div>
                  ))}
                  {add && (
                    <div className="d-flex gap-3 align-items-center mb-3">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter email"
                      />
                      <i className="bi bi-check-circle fs-1"></i>
                    </div>
                  )}
                  <div>
                    {/* <button
                      type="button"
                      className="btn btn-sm"
                      onClick={() => setAdd(!add)}
                    >
                      Add
                    </button> */}
                    <p
                      className="text-decoration-underline text-primary cursor-pointer"
                      onClick={() => setAdd(!add)}
                    >
                      Add View user
                    </p>
                  </div>
                </div>

                <button
                  type="button"
                  className={`w-100 rounded btn`}
                  style={{
                    // width: "150px",
                    color: "#ffff",
                  }}
                  onClick={() => {
                    // setCurrentPage(1);
                  }}
                  //   disabled={!toDate}
                >
                  Update access
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { SharingHistory };
