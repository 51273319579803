import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

// Define the form values interface
interface PreferencesFormValues {
  language: string;
  dateFormat: string;
  automaticTimeZone: boolean;
  folderCompression: string;
  organizationPermissions: {
    read: boolean;
    write: boolean;
  };
  publicPermissions: {
    read: boolean;
    write: boolean;
  };
}

// Define the props interface
interface PreferencesFormProps {
  initialData?: PreferencesFormValues;
  onSubmit: (values: PreferencesFormValues) => void;
  isLoading?: boolean;
}

const validationSchema = Yup.object({
  language: Yup.string().required("Duration is required"),
  dateFormat: Yup.string().required("Date format is required"),
  automaticTimeZone: Yup.boolean(),
  folderCompression: Yup.string().required(
    "Folder compression type is required"
  ),
  organizationPermissions: Yup.object({
    read: Yup.boolean(),
    write: Yup.boolean(),
  }),
  publicPermissions: Yup.object({
    read: Yup.boolean(),
    write: Yup.boolean(),
  }),
});

const defaultValues: PreferencesFormValues = {
  language: "",
  dateFormat: "",
  automaticTimeZone: true,
  folderCompression: "",
  organizationPermissions: {
    read: false,
    write: false,
  },
  publicPermissions: {
    read: false,
    write: false,
  },
};

const Settings: React.FC = () => {
  //    const  initialData,
  // onSubmit,
  const isLoading = false;
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>();
  const formik = useFormik({
    // initialValues: initialData || defaultValues,
    initialValues: defaultValues,
    validationSchema,
    onSubmit: (values) => {
      //   onSubmit(values);
      console.log(values);
    },
  });

  const handleAddFile = (uploadedFile: File | null) => {
    if (uploadedFile) {
      setFile(uploadedFile);
    } else {
      console.error("No file selected.");
    }
  };

  const handleSwitchChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(field, event.target.checked);
    };

  return (
    <>
      <h4 className="mb-4">Document Room - Settings</h4>{" "}
      <div className="card bg-white p-10">
        <form onSubmit={formik.handleSubmit} className="">
          <div className="d-flex flex-column gap-10">
            <div className="d-flex align-items-center">
              <label htmlFor="language" className="fs-5 w-25">
                Expiry Period
              </label>
              <div className="w-75">
                <select
                  id="language"
                  name="language"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.language}
                  className="form-select  w-50"
                >
                  <option value="">Select a Period</option>
                  <option value="1">1hr</option>
                  <option value="4">4hrs</option>
                  <option value="8">8hrs</option>
                  <option value="24">24hrs</option>
                  <option value="3">3 days</option>
                  <option value="7">7 days</option>
                  <option value="15">15 days</option>
                  <option value="30">30 daysr</option>
                  <option value="60">60 days</option>
                  <option value="90">90 days</option>
                </select>
                {formik.touched.language && formik.errors.language && (
                  <div className="mt-1 text-danger">
                    {formik.errors.language}
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <label htmlFor="dateFormat" className="fs-5 w-25">
                Add Signature/Seal/others
              </label>
              <div className="d-flex w-75">
                <div
                  className="w-50"
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    marginRight: "50px",
                  }}
                >
                  <FileUploader
                    handleChange={handleAddFile}
                    name="file"
                    types={["xlsx", "csv"]}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px dashed #888",
                        borderRadius: "8px",
                        padding: "20px",
                        width: "395px",
                        height: "100px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCloudUploadAlt}
                        size="4x"
                        style={{ color: "#888" }}
                      />
                      <p
                        style={{
                          marginTop: "10px",
                          color: "#888",
                          margin: "0px",
                        }}
                      >
                        Drag & drop file here, or click to select file
                      </p>
                      {/* <p style={{ marginTop: "10px", color: "#888" }}>
                        Max file size is 1MB per file
                      </p> */}
                    </div>
                  </FileUploader>
                  {/* {!data.fileData.fileData && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      File is required
                    </div>
                  </div>
                )} */}
                </div>
                <div
                  className="p-5 d-flex flex-column justify-content-center align-items-center border border-dark border-1 rounded cursor-pointer bg-hover-light"
                  onClick={() => navigate("/documentRoom/signs")}
                >
                  <div className="">View Library</div>
                  <div className="d-flex ">
                    (<p className="m-0">12 items</p>)
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="d-flex align-items-center">
              <label htmlFor="automaticTimeZone" className="fs-5 w-25">
                Automatic time zone
              </label>
              <div className="form-check form-switch form-check-custom form-check-solid w-75">
                <input
                  className="form-check-input h-30px w-50px cursor-pointer"
                  type="checkbox"
                  id="automaticTimeZone"
                  style={{
                    backgroundColor: formik.values.automaticTimeZone
                      ? "#0000FF"
                      : "#dbdfe9",
                  }}
                  checked={formik.values.automaticTimeZone}
                  onChange={handleSwitchChange("automaticTimeZone")}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <label htmlFor="folderCompression" className="fs-5 w-25">
                Folder Compression
              </label>
              <div className="w-75">
                <select
                  id="folderCompression"
                  name="folderCompression"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.folderCompression}
                  className="form-select"
                >
                  <option value="">Select a Compressions type...</option>
                  <option value="zip">ZIP</option>
                  <option value="rar">RAR</option>
                  <option value="7z">7Z</option>
                </select>
                {formik.touched.folderCompression &&
                  formik.errors.folderCompression && (
                    <div className="mt-1 text-danger">
                      {formik.errors.folderCompression}
                    </div>
                  )}
              </div>
            </div>

            <div className="d-flex">
              <label className="fs-5 w-25">Sharing Permissions</label>
              <div className="w-75">
                <div className="d-flex flex-column gap-2 mb-5">
                  <label className="fs-5">Organisation</label>
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-2 align-items-center ">
                      <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input h-30px w-50px cursor-pointer"
                          type="checkbox"
                          id="orgRead"
                          style={{
                            backgroundColor: formik.values.automaticTimeZone
                              ? "#0000FF"
                              : "#dbdfe9",
                          }}
                          checked={formik.values.organizationPermissions.read}
                          onChange={handleSwitchChange(
                            "organizationPermissions.reade"
                          )}
                        />
                      </div>

                      <label htmlFor="orgRead" className="">
                        Read
                      </label>
                    </div>
                    <div className="d-flex gap-2 align-items-center ">
                      <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input h-30px w-50px cursor-pointer"
                          type="checkbox"
                          id="orgWrite"
                          style={{
                            backgroundColor: formik.values
                              .organizationPermissions.write
                              ? "#0000FF"
                              : "#dbdfe9",
                          }}
                          checked={formik.values.organizationPermissions.write}
                          onChange={handleSwitchChange(
                            "organizationPermissions.write"
                          )}
                        />
                      </div>
                      <label htmlFor="orgWrite" className="">
                        Write
                      </label>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column gap-2 mb-5">
                  <label className="fs-5">Public</label>
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-2 align-items-center">
                      <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input h-30px w-50px cursor-pointer"
                          type="checkbox"
                          id="publicRead"
                          style={{
                            backgroundColor: formik.values.publicPermissions
                              .read
                              ? "#0000FF"
                              : "#dbdfe9",
                          }}
                          checked={formik.values.publicPermissions.read}
                          onChange={handleSwitchChange(
                            "publicPermissions.read"
                          )}
                        />
                      </div>

                      <label htmlFor="publicRead" className="">
                        Read
                      </label>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input h-30px w-50px cursor-pointer"
                          type="checkbox"
                          id="publicWrite"
                          style={{
                            backgroundColor: formik.values.publicPermissions
                              .write
                              ? "#0000FF"
                              : "#dbdfe9",
                          }}
                          checked={formik.values.publicPermissions.write}
                          onChange={handleSwitchChange(
                            "publicPermissions.write"
                          )}
                        />
                      </div>

                      <label htmlFor="publicWrite" className="">
                        Write
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="d-flex justify-content-center gap-10 mt-10">
            <button
              type="button"
              onClick={() => formik.resetForm()}
              className="btn"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading || !formik.isValid}
              className="btn"
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Settings;
