/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import { App } from "../App";
import { InviteApplication } from "../modules/auth/components/InviteApplication";
import { InvitePassword } from "../modules/auth/components/InvitePassword";
import { InviteMPIN } from "../modules/auth/components/InviteMPIN";
import { ViewLinks } from "../pages/documentRoom/ViewLinks";
import { ViewListing } from "../pages/documentRoom/ViewListing";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
  const { token } = useAuth();

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          <Route index element={<Navigate to="/dashboard" />} />
          {token ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
          <Route path="/team/verify/*" element={<InviteApplication />} />
          <Route path="/new-password" element={<InvitePassword />} />
          <Route path="/new-mpin" element={<InviteMPIN />} />
          <Route path="/view-documents" element={<ViewLinks />} />
          <Route path="/view-documents/list" element={<ViewListing />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
