/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from "../IAppModels";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileUploader } from "react-drag-drop-files";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { PhoneInput } from "react-international-phone";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { enhancedFetch } from "../../../../modules/auth/core/_requests";
const fileTypes = ["xlsx"];

interface Customer {
  isfavourite: boolean;
  customerGuid: string;
  customerName: string;
  companyName: string;
  countryType: string;
  isdCode: number;
  phoneNumber: string;
  emailId: string;
  address: string;
  vatNumber: string;
  notes: string;
  bankName: string;
  bankAccountNo: string;
  bic: string;
}

export const dataOutcome = {
  category: "",
  name: "",
  description: "",
  uploadType: "Upload",
  fileUploadId: "",
  cancel: false,
  fileExist: false,
};

const Step2 = ({ data, hasError, updateData }: StepProps) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");
  const [file, setFile] = useState<File | null>(null);
  const [customer, setCustomer] = useState<Customer[]>([]);
  const [valid, setValid] = useState(false);
  const [dataList, setDataList] = useState([]);

  const navigate = useNavigate();

  const viewCustomer = async () => {
    try {
      const url = `${API_URL}/customer/view`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setCustomer(data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    viewCustomer();
  }, []);

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    let value = event.target.value;

    // Check if the value is a valid number using a regular expression
    if (/^\d{0,2}(\.\d{0,2})?$/.test(value)) {
      // If the value is a valid number, return it
      return value;
    }

    // If the value is not a valid number, do not update the state
    return data?.days?.days;
  };

  const validateForm = () => {
    console.log("validate called");

    const expiryDate = new Date(data.date.date);
    const today = new Date();
    const reminderFrequency = parseInt(data.days.days);

    if (!expiryDate || !reminderFrequency) {
      return false;
    }

    const differenceInTime = expiryDate.getTime() - today.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    console.log(differenceInDays, reminderFrequency);

    if (differenceInDays > reminderFrequency) {
      return false;
    }

    return true;
  };

  const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;

    if (selectedValue === "add-new-customer") {
      navigate("/invoice/customers", { state: { data: "nav" } });
    }

    if (selectedValue !== "add-new-customer") {
      // Fetch company details based on selectedValue (companyName or id) from API or data source
      const selectedCompanyDetails = customer?.find(
        (company) => company.customerName === selectedValue
      );

      if (selectedCompanyDetails) {
        console.log(
          selectedCompanyDetails.isdCode + selectedCompanyDetails?.phoneNumber
        );
        updateData({
          customer: {
            customer: event.target.value,
          },
          email: {
            email: selectedCompanyDetails?.emailId,
          },
          phone: {
            phone:
              selectedCompanyDetails.isdCode +
              " " +
              selectedCompanyDetails?.phoneNumber,
          },
        });
      }
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setValid(false);
    updateData({
      fileData: {
        fileData: "",
      },
    });
    dataOutcome.fileExist = false;
  };

  const handleChange = (uploadedFile: File | null) => {
    if (uploadedFile) {
      setFile(uploadedFile);
      const formData = new FormData();
      formData.append("bulkFile", uploadedFile);
      formData.append("channel", "WEB");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      // Create the POST request options
      const requestOptions = {
        method: "POST",
        headers: headers,
        body: formData,
      };
      const url = `${API_URL}/bulk/uploadAndValidatePaymentLinkFile`;
      // Send the POST request
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status?.statusCode === 0) {
            console.log(result.message);

            setValid(true);
            setDataList(result?.message.validationMsgList);
            dataOutcome.fileUploadId = result?.message?.fileUploadId;
            dataOutcome.fileExist = true;
          } 
          else {
            Swal.fire({
              icon: "error",
              title: result?.status?.errorTitle || "Error",
              html:
                `<strong style="display: block; margin-bottom: 10px;">${result?.status?.messageDescription || ""
                }</strong>` +
                (result?.status?.suggestion
                  ? `<span>${result.status.suggestion}</span><br>
                      <a href="${result.status?.refUrl}" target="_blank" rel="noopener noreferrer" style="display: block; margin-top: 10px;">
                        ${result?.status?.refUrl ? `Reference: ${result.status?.refUrl}` : ''}
                      </a>`
                  : result?.status?.refUrl
                    ? `<a href="${result.status?.refUrl}" target="_blank" rel="noopener noreferrer">${result.status?.refUrl}</a>`
                    : ""
                ), 
              allowEscapeKey: true,
              allowEnterKey: true,
            });
          }
        })
        .catch((error) => console.error("error", error));
    } else {
      console.error("No file selected.");
    }
  };

  return (
    <>
      {/* <style>
        {`
      .custom-input::-webkit-outer-spin-button,
      .custom-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `}
      </style> */}
      <div className="pb-5" data-kt-stepper-element="content">
        {data?.category?.category === "Manual" && (
          <div
            className="w-100 h-100"
            // style={{ overflow: "auto", maxHeight: "250px" }}
          >
            <div className="mb-5 position-relative">
              <label className="form-label">Enter Amount</label>

              <div className="">
                <span
                  className="fs-6 fw-bold text-gray-700"
                  style={{
                    position: "absolute",
                    marginLeft: "12px",
                    marginTop: "12px",
                  }}
                >
                  {baseCurrency}
                </span>
                <input
                  type="text"
                  className="form-control custom-input ps-15"
                  value={data.amount.amount}
                  onChange={(e) => {
                    const value = e.target.value;

                    // If the value is empty, set the previous value to an empty string
                    if (value === "" || value === "0") {
                      updateData({
                        amount: {
                          amount: value,
                        },
                      });
                      return;
                    }

                    // Check if the value is a valid number with up to 2 decimal places and a single decimal point
                    if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
                      updateData({
                        amount: {
                          amount: value,
                        },
                      });
                    }
                  }}
                />
              </div>

              {!data.amount.amount && hasError && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="amount"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Amount is required
                  </div>
                </div>
              )}
            </div>

            <div className="mb-5">
              <label className="form-label">Select Business</label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  handleCompanyChange(e);
                }}
                value={data.customer.customer}
              >
                <option selected>Select Business</option>
                <option value="add-new-customer">Add new business</option>
                <hr />
                {customer.map((item, index) => (
                  <option key={index} value={item.customerName}>
                    {item.companyName}
                  </option>
                ))}
              </select>

              {!data.customer.customer && hasError && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="amount"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Business is required
                  </div>
                </div>
              )}
            </div>
            <div className="mb-5">
              <label className="form-label">Enter Phone</label>
              <PhoneInput
                value={data.phone.phone}
                inputStyle={{ padding: "21px" }}
                countrySelectorStyleProps={{
                  buttonStyle: {
                    padding: "21px 5px",
                  },
                }}
                // forceDialCode={true}
                inputClassName="form-control"
                defaultCountry="in"
                // onChange={(phone) => {
                //   updateData({
                //     phone: {
                //       phone: phone
                //         .replace(/[\s+\-]/g, "")
                //         .replace(/^(\+91|0)/, ""),
                //     },
                //   });
                // }}
                disableDialCodePrefill={false}
              />

              {!data.phone.phone && hasError && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="phone"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Phone is required
                  </div>
                </div>
              )}
            </div>
            <div className="mb-5">
              <label className="form-label">Enter Email</label>
              <input
                readOnly
                type="email"
                className="form-control"
                placeholder="Enter Email"
                value={data?.email?.email}
                onChange={(e) =>
                  updateData({
                    email: {
                      email: e.target.value,
                    },
                  })
                }
              />
              {!data.email.email && hasError && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="email"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Email is required
                  </div>
                </div>
              )}
            </div>
            <div className="mb-5">
              <label className="form-label">Enter description</label>
              <textarea
                rows={3}
                style={{ resize: "none" }}
                className="form-control"
                placeholder="Description"
                value={data.description.description}
                onChange={(e) =>
                  updateData({
                    description: {
                      description: e.target.value,
                    },
                  })
                }
                maxLength={100}
              />
              {!data.description.description && hasError && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="description"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Description of the Payment Link is required
                  </div>
                </div>
              )}
            </div>
            <div className="mb-5">
              <label className="form-label">Enter Expiry Date</label>
              <input
                type="date"
                className="form-control"
                placeholder="Select Date"
                max="9999-12-31"
                min={
                  new Date(new Date().setDate(new Date().getDate() + 1))
                    .toISOString()
                    .split("T")[0]
                }
                value={
                  data.date.date
                    ? new Date(data.date.date).toISOString().split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  updateData({
                    date: {
                      date: new Date(e.target.value).toISOString(),
                    },
                  })
                }
              />
              {!data.date.date && hasError && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="date"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Expiry Date is required
                  </div>
                </div>
              )}
            </div>
            <div className="mb-5">
              <label className="form-label">
                Would you like to send a reminder?
              </label>
              <div className="mb-3 d-flex flex-row  gap-10">
                <div
                  className="form-check form-check-custom form-check-solid form-check-sm card shadow-sm  m-0 d-flex flex-row justify-content-around"
                  style={{
                    width: "120px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  <input
                    className="form-check-input"
                    name="rem"
                    type="radio"
                    value="Yes"
                    checked={data.reminder.reminder === "Yes"}
                    onChange={() =>
                      updateData({
                        reminder: {
                          reminder: "Yes",
                        },
                      })
                    }
                  />
                  <label
                    className="form-check-label text-dark"
                    htmlFor="employeePaymentRadio"
                  >
                    Yes
                  </label>
                </div>
                <div
                  className="form-check form-check-custom form-check-solid form-check-sm card shadow-sm  d-flex flex-row justify-content-around "
                  style={{
                    width: "120px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  <input
                    className="form-check-input"
                    name="rem"
                    type="radio"
                    value="No"
                    checked={data.reminder.reminder === "No"}
                    onChange={() =>
                      updateData({
                        reminder: {
                          reminder: "No",
                        },
                      })
                    }
                  />
                  <label
                    className="form-check-label text-dark"
                    htmlFor="vendorPaymentRadio"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            {data.reminder.reminder === "Yes" && (
              <div className="mb-5">
                <label className="form-label">
                  Set reminder frequency(In days)
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Set reminder frequency(In days)"
                  value={data.days.days}
                  onChange={(e) =>
                    updateData({
                      days: {
                        days: handleAmountChange(e),
                      },
                    })
                  }
                />
                {!data.days.days && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="description"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Reminder frequency required
                    </div>
                  </div>
                )}
                {data.days.days && validateForm() && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="description"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Reminder days should be less than Expiry date
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {data?.category?.category === "Batch" && (
          <div className="w-100  mb-20">
            <div className="d-flex flex-column ">
              <a
                href="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/link/payment_link.xlsx"
                className="p-2"
                download="Sample_File.xlsx"
              >
                Download Sample File
              </a>
              {file ? (
                <div>
                  <div className="d-flex flex-column gap-5">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px dashed #888",
                        borderRadius: "8px",
                        padding: "20px",
                      }}
                    >
                      <div
                        className="card p-5 bg-secondary d-flex flex-column align-items-center justify-content-center "
                        style={{ width: "250px" }}
                      >
                        <div className="d-flex flex-row">
                          <p className="fw-bold">{file.size}&nbsp;</p>
                          <span>KB</span>
                        </div>
                        <div className="text-container">
                          <p
                            className="text-truncate"
                            style={{ maxWidth: "15rem" }}
                          >
                            {file.name}
                          </p>
                        </div>
                        <button
                          className="btn btn-danger "
                          style={{
                            width: "100px",
                            outline: "none",
                            padding: "2px 2px",
                          }}
                          onClick={handleFileRemove}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px dashed #888",
                        borderRadius: "8px",
                        padding: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCloudUploadAlt}
                        size="4x"
                        style={{ color: "#888" }}
                      />
                      <p style={{ marginTop: "10px", color: "#888" }}>
                        Drag & drop files here, or click to select files
                      </p>
                    </div>
                  </FileUploader>

                  {!data.fileData.fileData && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        File is required
                      </div>
                    </div>
                  )}
                </>
              )}
              {valid && (
                <div className="p-5">
                  {dataList.map((item, index) => (
                    <div className="p-2" key={index}>
                      <div className="form-check form-check-custom form-check-solid form-check-sm">
                        <input
                          className="form-check-input bg-success"
                          type="checkbox"
                          value=""
                          id={`flexRadioLg${index}`} // Ensure a unique ID for each checkbox
                          checked={valid} // Update this based on the item's validity if needed
                          disabled={!valid} // Update this based on the item's validity if needed
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexRadioLg${index}`}
                        >
                          {item}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { Step2 };
