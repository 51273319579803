import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { HeaderWrapper } from "./components/header";
import { RightToolbar } from "../partials/layout/RightToolbar";
import { ScrollTop } from "./components/scroll-top";
import { Content } from "./components/content";
import { FooterWrapper } from "./components/footer";
import { Sidebar } from "./components/sidebar";
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from "../partials";
import { PageDataProvider } from "./core";
import { reInitMenu } from "../helpers";
import { ToolbarWrapper } from "./components/toolbar";
import { toast } from "react-toastify";
// Import SweetAlert2
import Swal from "sweetalert2";

const MasterLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    reInitMenu();
  }, [location.key]);

  useEffect(() => {
  
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        Swal.close(); 
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  function InternetStatusNotifier() {
    const [online, setOnline] = useState(navigator.onLine);

    useEffect(() => {
      const handleOnlineStatusChange = () => {
        setOnline(navigator.onLine);
        if (!navigator.onLine) {
          toast.error("Internet connection lost!");
          setTimeout(() => {
            navigate("/error/503");
          }, 300);
        }
      };

      window?.addEventListener("online", handleOnlineStatusChange);
      window?.addEventListener("offline", handleOnlineStatusChange);

      return () => {
        window?.removeEventListener("online", handleOnlineStatusChange);
        window?.removeEventListener("offline", handleOnlineStatusChange);
      };
    }, []);

    return null;
  }

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
          <div
            className="app-page flex-column flex-column-fluid"
            id="kt_app_page"
          >
            <HeaderWrapper />
            <div
              className="app-wrapper flex-column flex-row-fluid"
              id="kt_app_wrapper"
            >
              <Sidebar />
              <div
                className="app-main flex-column flex-row-fluid"
                id="kt_app_main"
              >
                <div className="d-flex flex-column flex-column-fluid">
                  <ToolbarWrapper />
                  <Content>
                    <InternetStatusNotifier />
                    <Outlet />
                  </Content>
                </div>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        <RightToolbar />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  );
};

export { MasterLayout };
