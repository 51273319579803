import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Swal from "sweetalert2";

// Define your API URL
const API_URL = process.env.REACT_APP_WEB_API_URL;

export interface RoleData {
    roleGuid: string;
    name: string;
    description: string;
    roleStatus: number;
    userCount: number;
    actorId?: string;  
    actorName?: string; 
    actorType?: string;
}

export interface User {
    userGuid: string;   
    name: string;      
    roleId: string;     
    roleName: string;   
    currency: string;  
    amount: string;   
    actorType?: string;
}

interface RoleState {
    roles: RoleData[];
    users: User[]; 
    ruleSettings: RuleSettingData[];
    foundRules: RuleSettingData[];
    loading: boolean;
    error: string | null;
    success: boolean;
}

const initialState: RoleState = {
    roles: [],
    users: [],
    ruleSettings: [],
    foundRules: [],
    loading: false,
    error: null,
    success: false,
};

export interface RuleSettingData {
    ruleName: string;
    fromAmount: number;
    toAmount: number;
    currency: string;
    levels: {
        index: number;
        actors: {
            actorType: string;
            actorId: string;
            actorName: string;
        }[];
    }[];
    ruleGuid: string;
    status: string;
    businessGuid: string;
}


export type RuleGuid = {
    ruleGuid: string;
};


const getToken = (): string | null => {
    const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
    const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
    return tokenData ? tokenData.token : null;
};

const apiCall = async (url: string, body: any) => {
    const token = getToken();
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    const options = {
        method: "POST",
        headers,
        body: JSON.stringify(body),
    };

    const response = await enhancedFetch(url, options);
    const data = await response.json();

    if (data.status.statusCode !== 0) {
        const errorMessage = data.status.messageDescription || data.message || "An error occurred.";
        
    //     // Display error using Swal
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Error',
    //         text: errorMessage,
    //     });
    
        throw new Error(errorMessage); // Throws the error to be caught by higher-level error handling
    }
    
    return data.results;
};


export const fetchRoles = createAsyncThunk<RoleData[], void, { rejectValue: string }>(
    "roles/fetch",
    async () => {
        const url = `${API_URL}/team/getRoles`;
        return await apiCall(url, {});
    }
);

export const fetchUsers = createAsyncThunk<User[], { currency: string; amount: number }, { rejectValue: string }>(
    "roles/fetchUsers",
    async ({ currency, amount }) => {
        const url = `${API_URL}/team/getApproverList`;
        return await apiCall(url, { currency, amount });
    }
);


export const findRule = createAsyncThunk<RuleSettingData[], { instanceGuid: string }, { rejectValue: string }>(
    "rules/find",
    async ({ instanceGuid }) => {
        const url = `${API_URL}/rule/findRule`;
        return await apiCall(url, {
             searchCriteriaList: [
        {
          propertyName: "instanceGuid",
          propertyValue: instanceGuid,
        },
      ],
        });
    }
);

export const submitRuleSetting = createAsyncThunk<RuleSettingData, RuleSettingData, { rejectValue: string }>(
    "rules/submit",
    async (data) => {
        const url = `${API_URL}/rule/createRule`;
        return await apiCall(url, data);
    }
);

export const editRuleSetting = createAsyncThunk<RuleSettingData, RuleSettingData, { rejectValue: string }>(
    "rules/edit",
    async (data) => {
        const url = `${API_URL}/rule/updateRule`;
        return await apiCall(url, data);
    }
);

export const deleteRuleSetting = createAsyncThunk<RuleSettingData, RuleGuid, { rejectValue: string }>(
    "rules/delete",
    async (data) => {
        const url = `${API_URL}/rule/deleteRule`;
        return await apiCall(url, data);
    }
);

const roleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.loading = false;
            })
            .addCase(fetchRoles.fulfilled, (state, action: PayloadAction<RoleData[]>) => {
                state.roles = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.error = action.payload || "An error occurred.";
                state.loading = false;
            })
            .addCase(submitRuleSetting.pending, (state) => {
                state.loading = false;
                state.success = false;
                state.error = null;
            })
            .addCase(submitRuleSetting.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(submitRuleSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "An error occurred.";
                state.success = false;
            })
            .addCase(findRule.pending, (state) => {
                state.loading = true;
            })
            .addCase(findRule.fulfilled, (state, action: PayloadAction<RuleSettingData[]>) => {
                state.foundRules = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(findRule.rejected, (state, action) => {
                state.error = action.payload || "An error occurred.";
                state.loading = false;
            })
            .addCase(editRuleSetting.pending, (state) => {
                state.loading = false;
            })
            .addCase(editRuleSetting.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(editRuleSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "An error occurred.";
                state.success = false;
            })
            .addCase(deleteRuleSetting.pending, (state) => {
                state.loading = false;
            })
            .addCase(deleteRuleSetting.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(deleteRuleSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "An error occurred.";
                state.success = false;
            })
            .addCase(fetchUsers.pending, (state) => {
                state.loading = false; // Set loading to true
            })
            .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
                state.users = action.payload; // Update users with fetched data
                state.loading = false;
                state.error = null; // Clear any previous errors
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.error = action.payload || "An error occurred."; // Handle error
                state.loading = false;
            })
    },
});

export default roleSlice.reducer;
