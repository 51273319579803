import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignaturePad from "signature_pad";
import folder from "../../../_metronic/assets/all-serviceIcons/folder.svg";
import * as pdfjsLib from "pdfjs-dist";

// Type definitions
export interface SignatureData {
  dataUrl: string;
  type: "draw" | "type" | "upload" | "saved";
}

interface SignatureModalProps {
  pdfElement: HTMLDivElement | null;
  onSignatureCreated: (data: SignatureData) => void;
}

interface SignatureCanvasProps {
  onSave: (dataUrl: string) => void;
}

interface SignaturePlacerProps {
  pdfElement: HTMLDivElement | null;
  signatureData: SignatureData | null;
  onPlacementComplete: () => void;
}

interface SignaturePlacement extends Position {
  id: number;
  width: number;
  height: number;
  pageNumber: number;
  signatureData: SignatureData;
}

interface ResizableImageProps {
  placement: SignaturePlacement;
  pdfElement: HTMLDivElement | null;
  removePlacement: (id: string) => void;
  handlePlaceSignature: (e: React.MouseEvent) => void;
  onUpdatePlacement: (id: number, placement: SignaturePlacement) => void;
}

interface LocationState {
  pdfUrl: string;
}

interface Position {
  x: number;
  y: number;
}

// SignatureModal Component
const SignatureModal: React.FC<SignatureModalProps> = ({
  pdfElement,
  onSignatureCreated,
}) => {
  const [selectedTab, setSelectedTab] = useState<
    "Draw" | "Type" | "Upload" | "Select"
  >("Draw");
  const [file, setFile] = useState<File | null>(null);
  const [text, setText] = useState<string>("");
  const [selectedFont, setSelectedFont] = useState<string>("Dancing Script");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const textContainerRef = useRef<HTMLDivElement>(null);

  const imgarray = [
    {
      name: "invoices.pdf",
      type: "pdf",
      size: "50kb",
      lastModified: "19 Aug 2024, 5:20 pm",
      fileGuid: "asdr23454esdf1",
    },
    {
      name: "payslips.pdf",
      type: "pdf",
      size: "112kb",
      lastModified: "22 Sep 2024, 10:30 am",
      fileGuid: "asdr23454esdf2",
    },
    {
      name: "AOA.docx",
      type: "docx",
      size: "79kb",
      lastModified: "24 Jun 2024, 11:30 am",
      fileGuid: "asdr23454esdf3",
    },
    {
      name: "License.jpeg",
      type: "jpeg",
      size: "33kb",
      lastModified: "21 Feb 2024, 2:40 pm",
      fileGuid: "asdr23454esdf4",
    },
    {
      name: "ID.jpeg",
      type: "jpeg",
      size: "50kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf5",
    },
    {
      name: "business_list.pdf",
      type: "pdf",
      size: "123kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf6",
    },
    {
      name: "vendor_list.docx",
      type: "docx",
      size: "250kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf7",
    },
    {
      name: "invoices.jpeg",
      type: "jpeg",
      size: "55kb",
      lastModified: "20 Jun 2024, 11:05 am",
      fileGuid: "asdr23454esdf8",
    },
  ];

  const handleTabClick = (tab: "Draw" | "Type" | "Upload" | "Select") => {
    setSelectedTab(tab);
  };

  const handleFontChange = (font: string) => {
    setSelectedFont(font);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleAddFile = (uploadedFile: File) => {
    setFile(uploadedFile);
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  const handleItemSelect = (fileGuid: string) => {
    setSelectedItems([fileGuid]);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          onSignatureCreated({
            dataUrl: reader.result.toString(),
            type: "saved",
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadSave = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          onSignatureCreated({
            dataUrl: reader.result.toString(),
            type: "upload",
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const saveTypeSignature = () => {
    if (!text || !selectedFont) return;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.font = `32px ${selectedFont}`;
    const textWidth = ctx.measureText(text).width;
    const padding = 20;

    canvas.width = textWidth + padding * 2;
    canvas.height = 60;

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.font = `32px ${selectedFont}`;
    ctx.fillStyle = "black";
    ctx.textBaseline = "middle";
    ctx.fillText(text, padding, canvas.height / 2);

    const dataUrl = canvas.toDataURL("image/png");
    onSignatureCreated({
      dataUrl,
      type: "type",
    });
  };

  return (
    <div className="modal fade" id="date_range" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered min-w-650px">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Add your signature</h4>
          </div>
          <div className="modal-body p-4 min-h-350px">
            <ul className="nav nav-tabs mb-4">
              {(["Draw", "Type", "Upload", "Select"] as const).map((tab) => (
                <li key={tab} className="nav-item">
                  <button
                    className={`nav-link ${selectedTab === tab ? "active" : ""}`}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab}
                  </button>
                </li>
              ))}
            </ul>

            <div className="tab-content">
              {selectedTab === "Draw" && (
                <SignatureCanvas
                  onSave={(dataUrl) =>
                    onSignatureCreated({ dataUrl, type: "draw" })
                  }
                />
              )}
              {selectedTab === "Type" && (
                <div className="type-signature">
                  <div
                    ref={textContainerRef}
                    className="d-flex align-items-end border border-gray-300  rounded w-100 mb-4"
                    style={{
                      // width: "500px",
                      height: "200px",
                    }}
                  >
                    <input
                      type="text"
                      value={text}
                      onChange={handleTextChange}
                      className="form-control fs-5x px-5 py-3 border border-0"
                      style={{ fontFamily: selectedFont, fontSize: "32px" }}
                      placeholder="Type your signature"
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div
                      className="d-flex align-items-center justify-content-end cursor-pointer border-bottom border-dark"
                      onClick={() => handleFontChange("Dancing Script")}
                    >
                      <i className="bi bi-shuffle text-dark fw-bold fs-5 me-3"></i>
                      <p className="m-0 fs-5">Change font</p>
                    </div>
                    <button
                      className="btn btn-sm"
                      onClick={saveTypeSignature}
                      disabled={!text}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
              {selectedTab === "Upload" && (
                <div className="upload-signature">
                  <form
                    id="kt_modal_upload_file_form"
                    className="form"
                    //   onSubmit={editFormik.handleSubmit}
                  >
                    <div className="d-flex flex-column scroll-y">
                      {file ? (
                        <div className="d-flex flex-column w-100 justify-content-center ">
                          <div className="d-flex justify-content-center">
                            <div
                              style={{
                                border: "1px dashed #888",
                                borderRadius: "8px",
                                padding: "20px",
                                width: "532px",
                                height: "200px",
                              }}
                            >
                              <div
                                className="card p-5 bg-secondary d-flex flex-column align-items-center justify-content-center "
                                style={{ width: "490px", height: "155px" }}
                              >
                                <div className="d-flex flex-row">
                                  <p className="fw-bold">{file.size}&nbsp;</p>
                                  <span>KB</span>
                                </div>
                                <div className="text-container my-2">
                                  <p className="text-truncate">{file.name}</p>
                                </div>
                                <p
                                  className="text-danger text-decoration-underline fw-bold cursor-pointer"
                                  onClick={handleFileRemove}
                                >
                                  Remove
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignSelf: "center",
                              width: "532px",
                              height: "200px",
                            }}
                          >
                            <FileUploader
                              handleChange={handleAddFile}
                              name="file"
                              types={["jpg", "jpeg", "png", "svg", "webp"]}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px dashed #888",
                                  borderRadius: "8px",
                                  padding: "20px",
                                  marginBottom: "10px",
                                  width: "532px",
                                  height: "200px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCloudUploadAlt}
                                  size="4x"
                                  style={{ color: "#888" }}
                                />
                                <p style={{ marginTop: "10px", color: "#888" }}>
                                  Drag & drop image here, or click to select
                                </p>
                                {/* <p style={{ marginTop: "10px", color: "#888" }}>
                                  Max file size: 1 MB
                                </p> */}
                              </div>
                            </FileUploader>
                            {/* {!data.fileData.fileData && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      File is required
                    </div>
                  </div>
                )} */}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="text-end pt-5">
                      <button
                        type="reset"
                        className="btn btn-light btn-sm me-3"
                        onClick={handleFileRemove}
                      >
                        Remove
                      </button>
                      <button
                        type="button"
                        // id="kt_modal_upload_file_submit"
                        className="btn btn-sm"
                        disabled={!file}
                        onClick={handleUploadSave}
                      >
                        <span className="indicator-label">Save</span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {selectedTab === "Select" && (
                <div className=" max-h-200px overflow-y-scroll">
                  <div
                    className="row g-2"
                    style={{
                      maxHeight: "250px",
                      overflow: "scroll",
                    }}
                  >
                    {imgarray.map((item, index) => (
                      <div className="col-lg-4 col-md-4" key={index}>
                        <div
                          className={`border rounded p-4 h-100 cursor-pointer ${
                            selectedItems.includes(item.fileGuid)
                              ? "bg-light-primary border-primary"
                              : "bg-light bg-hover-secondary"
                          }`}
                          onClick={() => handleItemSelect(item.fileGuid)}
                          onMouseOver={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "#f1f1f2";
                          }}
                          onMouseOut={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "white";
                          }}
                        >
                          <div className="text-center mb-3">
                            {/* <i className="bi bi-folder2-open fs-1 text-primary"></i> */}
                            <img
                              src={folder}
                              alt=""
                              style={{ width: "100px", height: "80px" }}
                              // onMouseDown={handleStartDragging}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="text-end pt-5">
                    <button
                      type="reset"
                      className="btn btn-light btn-sm me-3"
                      onClick={handleFileRemove}
                    >
                      Delete
                    </button>

                    <button
                      type="submit"
                      id="kt_modal_upload_file_submit"
                      className="btn btn-sm"
                      // disabled={!file}
                      onClick={handleUploadSave}
                    >
                      <span className="indicator-label">Insert</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// SignatureCanvas Component
const SignatureCanvas: React.FC<SignatureCanvasProps> = ({ onSave }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      signaturePadRef.current = new SignaturePad(canvasRef.current, {
        backgroundColor: "rgb(255, 255, 255)",
      });
    }

    return () => {
      if (signaturePadRef.current) {
        signaturePadRef.current.off();
      }
    };
  }, []);

  const clearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const saveSignature = () => {
    if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
      const dataURL = signaturePadRef.current.toDataURL();
      onSave(dataURL);
    }
  };

  return (
    <div>
      <canvas
        ref={canvasRef}
        className="border rounded"
        width={600}
        height={200}
      />
      <div className="text-end mt-4">
        <button onClick={clearSignature} className="btn btn-light btn-sm me-2">
          Clear
        </button>
        <button onClick={saveSignature} className="btn btn-sm">
          Save
        </button>
      </div>
    </div>
  );
};

const ResizableImage: React.FC<ResizableImageProps> = ({
  placement,
  removePlacement,
  handlePlaceSignature,
  onUpdatePlacement,
  pdfElement,
}) => {
  const [size, setSize] = useState({
    width: placement.width,
    height: placement.height,
  });
  const [position, setPosition] = useState({ x: placement.x, y: placement.y });
  const [isDragging, setIsDragging] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(placement.pageNumber);

  const calculateCurrentPage = (yPosition: number) => {
    if (!pdfElement) return 1;
    const pageHeight = pdfElement.clientHeight;
    return Math.floor(yPosition / pageHeight) + 1;
  };

  // Update page number when position changes
  useEffect(() => {
    const newPage = calculateCurrentPage(position.y);
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
      onUpdatePlacement(placement.id, {
        ...placement,
        x: position.x,
        y: position.y,
        pageNumber: newPage,
      });
    }
  }, [position.y, currentPage, placement, onUpdatePlacement]);

  const handleSignatureClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsActive(true);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest(`[data-signature-id="${placement.id}"]`)) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [placement.id]);

  const startResizing = (
    mouseDownEvent: React.MouseEvent<HTMLDivElement>,
    corner: string
  ) => {
    if (!isActive) return;
    mouseDownEvent.stopPropagation();
    const startSize = size;
    const startPosition = {
      x: mouseDownEvent.pageX,
      y: mouseDownEvent.pageY,
    };
    const startImagePosition = { ...position };

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      mouseMoveEvent.preventDefault();
      const deltaX = mouseMoveEvent.pageX - startPosition.x;
      const deltaY = mouseMoveEvent.pageY - startPosition.y;

      let newWidth = startSize.width;
      let newHeight = startSize.height;
      let newX = startImagePosition.x;
      let newY = startImagePosition.y;

      switch (corner) {
        case "top-left":
          newWidth = Math.max(50, startSize.width - deltaX);
          newHeight = Math.max(50, startSize.height - deltaY);
          newX = startImagePosition.x + startSize.width - newWidth;
          newY = startImagePosition.y + startSize.height - newHeight;
          break;
        case "top-right":
          newWidth = Math.max(50, startSize.width + deltaX);
          newHeight = Math.max(50, startSize.height - deltaY);
          newY = startImagePosition.y + startSize.height - newHeight;
          break;
        case "bottom-left":
          newWidth = Math.max(50, startSize.width - deltaX);
          newHeight = Math.max(50, startSize.height + deltaY);
          newX = startImagePosition.x + startSize.width - newWidth;
          break;
        case "bottom-right":
          newWidth = Math.max(50, startSize.width + deltaX);
          newHeight = Math.max(50, startSize.height + deltaY);
          break;
      }

      setSize({ width: newWidth, height: newHeight });
      setPosition({ x: newX, y: newY });

      const newPage = calculateCurrentPage(newY);
      onUpdatePlacement(placement.id, {
        ...placement,
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight,
        pageNumber: newPage,
      });
    }

    function onMouseUp() {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const startDragging = (e: React.MouseEvent) => {
    if (!isActive) return;
    e.stopPropagation();
    setIsDragging(true);
    const startPosition = { x: e.pageX - position.x, y: e.pageY - position.y };

    const onMouseMove = (moveEvent: MouseEvent) => {
      const newX = moveEvent.pageX - startPosition.x;
      const newY = moveEvent.pageY - startPosition.y;
      setPosition({ x: newX, y: newY });

      const newPage = calculateCurrentPage(newY);
      onUpdatePlacement(placement.id, {
        ...placement,
        x: newX,
        y: newY,
        pageNumber: newPage,
      });
      handlePlaceSignature(e);
    };

    const onMouseUp = () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const resizeHandleStyle = {
    position: "absolute",
    width: "10px",
    height: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    border: "1px solid #666",
    zIndex: 2,
    display: isActive ? "block" : "none",
  } as const;

  return (
    <div
      data-signature-id={placement.id}
      className={`position-absolute ${isActive ? "signature-active" : ""}`}
      style={{
        left: position.x,
        top: position.y,
        width: size.width,
        height: size.height,
        cursor: isDragging ? "grabbing" : isActive ? "grab" : "pointer",
        border: isActive ? "2px solid #007bff" : "none",
      }}
      onClick={handleSignatureClick}
      onMouseDown={startDragging}
    >
      <img
        src={placement.signatureData.dataUrl}
        alt="Placed Signature"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "fill",
          pointerEvents: "none",
        }}
      />

      {/* Resize handles */}
      <div
        style={{
          ...resizeHandleStyle,
          top: "-5px",
          left: "-5px",
          cursor: "nw-resize",
        }}
        onMouseDown={(e) => startResizing(e, "top-left")}
      />
      <div
        style={{
          ...resizeHandleStyle,
          top: "-5px",
          right: "-5px",
          cursor: "ne-resize",
        }}
        onMouseDown={(e) => startResizing(e, "top-right")}
      />
      <div
        style={{
          ...resizeHandleStyle,
          bottom: "-5px",
          left: "-5px",
          cursor: "sw-resize",
        }}
        onMouseDown={(e) => startResizing(e, "bottom-left")}
      />
      <div
        style={{
          ...resizeHandleStyle,
          bottom: "-5px",
          right: "-5px",
          cursor: "se-resize",
        }}
        onMouseDown={(e) => startResizing(e, "bottom-right")}
      />

      {isActive && (
        <>
          <button
            onClick={(e) => {
              e.stopPropagation();
              removePlacement(placement.id.toString());
            }}
            className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger"
            style={{ cursor: "pointer", zIndex: 2 }}
          >
            ×
          </button>

          <div className="position-absolute bottom-0 start-0 badge bg-primary">
            Page {currentPage} | Size: {Math.round(size.width)}x
            {Math.round(size.height)} | Pos: ({Math.round(position.x)},{" "}
            {Math.round(position.y)})
          </div>
        </>
      )}
    </div>
  );
};

// SignaturePlacer Component
const SignaturePlacer: React.FC<SignaturePlacerProps> = ({
  pdfElement,
  signatureData,
  onPlacementComplete,
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [placements, setPlacements] = useState<SignaturePlacement[]>([]);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const floatingSignatureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (pdfElement) {
        const scrollTop = pdfElement.scrollTop;
        const pageHeight = pdfElement.clientHeight;
        const currentPage = Math.floor(scrollTop / pageHeight) + 1;
        setCurrentPage(currentPage);
      }
    };

    pdfElement?.addEventListener("scroll", handleScroll);
    return () => pdfElement?.removeEventListener("scroll", handleScroll);
  }, [pdfElement]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        setCursorPosition({ x: e.clientX, y: e.clientY });
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, [isDragging]);

  useEffect(() => {
    if (signatureData) {
      setIsDragging(true);
    }
  }, [signatureData]);

  const handlePlaceSignature = (e: React.MouseEvent) => {
    if (!isDragging || !pdfElement || !signatureData) return;

    const pdfRect = pdfElement.getBoundingClientRect();
    const scrollTop = pdfElement.scrollTop;
    const pageHeight = pdfElement.clientHeight;

    const x = e.clientX - pdfRect.left - 75;
    const y = e.clientY - pdfRect.top + scrollTop - 30;

    // Calculate current page based on scroll position
    const pageNumber = Math.floor(scrollTop / pageHeight) + 1;

    const newPlacement: SignaturePlacement = {
      id: Date.now(),
      x,
      y,
      signatureData,
      width: 150,
      height: 60,
      pageNumber,
    };

    setPlacements([...placements, newPlacement]);
    setIsDragging(false);
    onPlacementComplete();
  };

  const updatePlacement = (
    id: number,
    updatedPlacement: SignaturePlacement
  ) => {
    setPlacements(placements.map((p) => (p.id === id ? updatedPlacement : p)));
  };

  const removePlacement = (id: string) => {
    setPlacements(placements.filter((p) => p.id.toString() !== id));
  };

  return (
    <div
      className="position-absolute top-0 start-0 bottom-0 end-0"
      style={{ pointerEvents: isDragging ? "all" : "none" }}
      onClick={handlePlaceSignature}
    >
      {isDragging && signatureData && (
        <div
          ref={floatingSignatureRef}
          className="position-fixed"
          style={{
            left: cursorPosition.x,
            top: cursorPosition.y,
            transform: "translate(-50%, -50%)",
            pointerEvents: "none",
            // zIndex: 1050,
          }}
        >
          <img
            src={signatureData.dataUrl}
            alt="Signature"
            style={{ maxWidth: "150px", maxHeight: "60px" }}
          />
        </div>
      )}

      <div style={{ pointerEvents: "all" }}>
        {placements.map((placement) => (
          <ResizableImage
            key={placement.id}
            placement={placement}
            removePlacement={removePlacement}
            handlePlaceSignature={handlePlaceSignature}
            onUpdatePlacement={updatePlacement}
            pdfElement={pdfElement} // Add this prop
          />
        ))}
      </div>
    </div>
  );
};

// Main PdfPreview Component
const PdfPreview: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [url, setUrl] = useState<string>("");
  const [error, setError] = useState<string>("");
  const pdfViewerRef = useRef<HTMLEmbedElement>(null);
  const [signatureData, setSignatureData] = useState<SignatureData | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const state = location.state as LocationState;
    if (!state?.pdfUrl) {
      setError("No PDF URL provided");
      return;
    }
    setUrl(state.pdfUrl);
    handleOpenModal();
  }, [location]);

  const handleOpenModal = () => {
    const modalElement = document.getElementById("date_range");
    if (modalElement) {
      const modal = new Modal(modalElement);
      modal.show();
      setIsModalOpen(true);

      modalElement.addEventListener(
        "hidden.bs.modal",
        () => {
          setIsModalOpen(false);
        },
        { once: true }
      );
    }
  };

  const handleSignatureCreated = (data: SignatureData) => {
    setSignatureData(data);
    const modalElement = document.getElementById("date_range");
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      if (modal) {
        modal.hide();
      }
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    // Load the PDF and get total page count
    const loadPDF = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument(url);
        const pdf = await loadingTask.promise;
        setTotalPages(pdf.numPages);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    loadPDF();
  }, [url]);

  const handleScroll = (e: any) => {
    // This is a rough approximation and may not be 100% accurate
    const element = e.target;
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight;
    const clientHeight = element.clientHeight;

    // Calculate approximate page number based on scroll position
    const approximatePageNumber = Math.ceil(
      (scrollTop / (scrollHeight - clientHeight)) * totalPages
    );

    setCurrentPage(approximatePageNumber);
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (error) {
    return (
      <div className="p-4">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
        <button className="btn btn-primary mt-3" onClick={handleBack}>
          Go Back
        </button>
      </div>
    );
  }

  return (
    <>
      <h4>PDF Preview</h4>
      <div className="card p-2">
        <div className="d-flex justify-content-end mb-2 gap-2">
          <button className="btn btn-primary btn-sm">Save</button>
          <button className="btn btn-primary btn-sm" onClick={handleOpenModal}>
            Sign
          </button>
        </div>
        <div className="position-relative vh-100">
          {/* <div className="position-absolute top-0 start-0 bottom-0 end-0"> */}
          {url && (
            <>
              <embed
                ref={pdfViewerRef}
                className="w-100 h-100"
                title="PDF Preview"
                src={`${url}#toolbar=0`}
                type="application/pdf"
                onScroll={handleScroll}
                onClick={(e) => console.log(e)}
              />
              {!isModalOpen && signatureData && (
                <SignaturePlacer
                  pdfElement={pdfViewerRef.current}
                  signatureData={signatureData}
                  onPlacementComplete={() => setSignatureData(signatureData)}
                />
              )}
            </>
          )}
          {/* </div> */}
          <SignatureModal
            pdfElement={pdfViewerRef.current}
            onSignatureCreated={handleSignatureCreated}
          />
        </div>
      </div>
    </>
  );
};

export default PdfPreview;
