import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { UpdatePasswordResponse, VerifyOtpResponse } from "../../../interface/Interface";
import { UpdatePasswordModall } from "./UpdatePasswordSuccess";
import { enhancedFetch, signOut } from "../core/_requests";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import { useAuth } from "../core/Auth";

export function NewPassword() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const navigate = useNavigate();
  const location = useLocation();
  const responseData: VerifyOtpResponse = (location.state as { responseData: VerifyOtpResponse })?.responseData;

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .max(20, "Password must be at most 20 characters long")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(/[!@#$%^&*]/, "Password must contain at least one special character")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), undefined], "Passwords must match")
      .required("Please confirm your password"),
    terms: Yup.boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("You must accept the terms and conditions")
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      terms: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await updatePassword(values.password, responseData.token);
        if (response && response.statusCode === 0) {
          handleUpdatePasswordSuccess();
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleUpdatePasswordSuccess = () => {
    const modalElement = document.getElementById("password_success_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const updatePassword = async (
    password: string,
    token: string
  ): Promise<UpdatePasswordResponse> => {
    try {
      const url = `${API_URL}/updatePassword`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${responseData.token}`,
      };

      const body = JSON.stringify({ password });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      if (data.status.statusCode === 0) {
        navigate("/auth");

        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "New password has been set. Please use the new password to login.",
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }

      return data?.status as UpdatePasswordResponse;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      const confirmLeave = window.confirm("Are you sure you want to leave?");

      navigate("/auth/forgot-password")
      // Prevent navigation by pushing the current state again
      window.history.pushState(null, "", window.location.pathname);
    };

    // Add an initial state to handle the back button
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-10">
        <h1 className="text-dark fw-bolder mb-3">Setup New Password</h1>
      </div>

      <div className="fv-row mb-8">
        <div className="mb-1">
          <div className="position-relative mb-3">
            <input
              className={`form-control bg-transparent ${formik.touched.password && formik.errors.password ? "is-invalid" : ""
                }`}
              type={showPassword ? "text" : "password"}
              placeholder="Password"

              autoComplete="off"
              {...formik.getFieldProps("password")}
            />
            <div
              className="position-absolute end-0 top-50 translate-middle-y me-5"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeSlash /> : <Eye />}
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <p className="text-danger">{formik.errors.password}</p>
          ) : null}
        </div>
      </div>

      <div className="fv-row mb-8">
        <div className="position-relative mb-2">
          <input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Repeat Password"

            autoComplete="off"
            className={`form-control bg-transparent ${formik.touched.confirmPassword && formik.errors.confirmPassword ? "is-invalid" : ""
              }`}
            {...formik.getFieldProps("confirmPassword")}
          />
          <div
            className="position-absolute end-0 top-50 translate-middle-y me-5"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? <EyeSlash /> : <Eye />}
          </div>
        </div>
        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
          <p className="text-danger">{formik.errors.confirmPassword}</p>
        ) : null}
      </div>

      <div className="fv-row mb-8">
        <label className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"

            {...formik.getFieldProps("terms")}
          />
          <span className="form-check-label fw-semibold text-gray-700 fs-6 ms-1">
            I Agree &amp;
            <a href="https://www.xfin.digital/terms-conditions" target="_blank" className="ms-1 link-primary">
              Terms and conditions
            </a>

            .
          </span>
        </label>
        {formik.touched.terms && formik.errors.terms ? (
          <p className="text-danger">{formik.errors.terms}</p>
        ) : null}
      </div>

      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_new_password_submit"
          className="btn"
          disabled={!formik.isValid || formik.isSubmitting}
        >
          <span className="indicator-label">Submit</span>
          <span className="indicator-progress">
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </button>
      </div>
    </form>
  );
}
