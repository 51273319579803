import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  deleteRuleSetting,
  editRuleSetting,
  fetchRoles,
  fetchUsers,
  findRule,
  RoleData,
  submitRuleSetting,
  User,
} from "./RuleSettingsSlice";
import { RootState } from "../../store";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { MutatingDots } from "react-loader-spinner";
import Loader from "../components/Loader";

interface LevelError {
  actors?: string; // Error message for actors
}

interface FormErrors {
  levels?: LevelError[];
}

const RuleSetting: React.FC = () => {
  const [amountRange, setAmountRange] = useState({ min: 0, max: Infinity });
  const [approvalLevels, setApprovalLevels] = useState<
    Array<(RoleData | User)[]>
  >([[]]);
  const [active, setActive] = useState(false);
  const [editingRuleIndex, setEditingRuleIndex] = useState<number | null>(null);
  const { roles, foundRules, loading, users } = useSelector(
    (state: RootState) => state.role
  );


  function isRoleData(approver: RoleData | User): approver is RoleData {
    return (approver as RoleData).roleGuid !== undefined;
  }


  function isUser(approver: RoleData | User): approver is User {
    return (approver as User).userGuid !== undefined;
  }
  const getAvailableApprovers = (index: number) => {
    const allSelectedGuids = approvalLevels
      .flatMap((level, levelIndex) =>
        levelIndex === index
          ? [] // Ignore selections from the same index
          : level?.map((approver) => {
            if (isRoleData(approver)) {
              return { type: "role", id: approver.roleGuid };
            } else if (isUser(approver)) {
              return { type: "user", id: approver.userGuid, roleId: approver.roleId };
            }
            return null;
          })
      )
      .filter(Boolean);

    const selectedRoleIds = allSelectedGuids
      .filter((entry) => entry?.type === "user")
      .map((entry) => entry?.roleId); // All role IDs from selected users

    const selectedUserIds = allSelectedGuids
      .filter((entry) => entry?.type === "role")
      .map((entry) => entry?.id);

    const selectedGuids = allSelectedGuids.map((entry) => entry?.id);

    // Filter out roles that are already selected
    const availableRoles = roles.filter((role) => {
      const isRoleSelected = selectedGuids.includes(String(role.roleGuid).trim());
      const isUserRoleSelected = selectedRoleIds.includes(role.roleGuid);
      return !isRoleSelected && !isUserRoleSelected;
    });

    // Filter out users that are already selected or whose role is already selected
    const availableUsers = users.filter((user) => {
      const isUserSelected = selectedGuids.includes(String(user.userGuid).trim());
      const isUserRoleSelected = selectedRoleIds.includes(user.roleId);
      const isUsersRoleSelected = selectedUserIds.includes(user.roleId);
      return !isUserSelected && !isUserRoleSelected && !isUsersRoleSelected;
    });

    return [
      {
        label: "Roles",
        options: availableRoles?.map((role) => ({
          label: role.name,
          value: role.roleGuid,
          ...role,
        })),
      },
      {
        label: "Users",
        options: availableUsers?.map((user) => ({
          label: `${user.name} (${user.roleName})`,
          value: user.userGuid,
          ...user,
        })),
      },
    ];
  };





  const location = useLocation();
  const data = location.state?.data;
  const ruleValues = location.state?.values;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRoles() as any);
    dispatch(findRule({ instanceGuid: data?.instanceGuid }) as any);
  }, [dispatch]);

  const validationSchema = Yup.object({
    currency: Yup.string().required("Currency is required"),
    fromAmount: Yup.number()
      .typeError("From amount must be a number")
      .required("From amount is required")
      .min(0, "From amount must be at least 0"),
    toAmount: Yup.number()
      .typeError("To amount must be a number")
      .required("To amount is required")
      .min(0, "To amount must be at least 0")
      .test(
        "is-greater-than-fromAmount",
        "To amount must be greater than from amount",
        function (value) {
          const { fromAmount } = this.parent;
          return value > fromAmount;
        }
      ),
    levels: Yup.array()
      .of(
        Yup.object().shape({
          actors: Yup.array()
            .of(
              Yup.object().shape({
                actorId: Yup.string().required("Approver ID is required"),
                actorName: Yup.string().required("Approver name is required"),
              })
            )
            .min(1, "At least one approver is required"), // Actor-level validation
        })
      )
      .min(1, "At least one approval level is required"),
  });



  const formik = useFormik({
    initialValues: {
      ruleGuid: "",
      currency: "ZAR",
      fromAmount: "",
      toAmount: "",
      levels: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const submitData = {
          category: "TRANSACTION",
          fromAmount: Number(values.fromAmount),
          toAmount: Number(values.toAmount),
          currency: values.currency,
          instanceGuid: data?.instanceGuid,
          levels: values.levels,
          ...(values.ruleGuid && { ruleGuid: values.ruleGuid }),
        };


        if (values.ruleGuid) {
          const result = await dispatch(
            editRuleSetting(submitData as any) as any
          );
          if (result?.meta?.requestStatus === "fulfilled") {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Rule updated successfully!",
            });
          }
        } else {
          const result = await dispatch(
            submitRuleSetting(submitData as any) as any
          );
          if (result?.meta?.requestStatus === "fulfilled") {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Rule created successfully!",
            });
          }
        }


        await dispatch(findRule({ instanceGuid: data?.instanceGuid }) as any);
        handleCancel();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to save rule. Please try again.",
        });
      }
    },
  });

  console.log(formik.errors);


  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(
        fetchUsers({
          currency: formik.values.currency,
          amount: Number(formik.values.toAmount),
        }) as any
      );
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [formik.values.toAmount, formik.values.currency, dispatch]);

  const handleAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: "min" | "max"
  ) => {
    let value = e.target.value;


    if (!/^\d*\.?\d*$/.test(value)) return;


    const updatedValue = value === "" ? "" : value.replace(/^0+(\d)/, "$1");

    setAmountRange({
      ...amountRange,
      [key]: updatedValue ? Number(updatedValue) : 0,
    });


    formik.setFieldValue(
      key === "min" ? "fromAmount" : "toAmount",
      updatedValue
    );
  };

  const handleApproverChange = (
    levelIndex: number,
    selectedOptions: (RoleData | User)[]
  ) => {
    // Clone the approval levels and update the specific level
    const updatedLevels = [...approvalLevels];
    updatedLevels[levelIndex] = selectedOptions;

    // // If the level is empty and it's not the first level (index 0), remove it
    // if (levelIndex !== 0 && updatedLevels[levelIndex].length === 0) {
    //   updatedLevels.splice(levelIndex, 1);
    // }

    // Update the approval levels state
    setApprovalLevels(updatedLevels);

    // Format the updated levels to match the required structure
    const formattedLevels = updatedLevels?.map((level, idx) => ({
      index: idx + 1,
      actors: level?.map((approver, actorIndex) => {
        if ("roleGuid" in approver && approver.roleGuid) {
          return {
            actorType: "ROLE",
            actorId: approver.roleGuid,
            actorName: approver.name,
            index: actorIndex + 1,
          };
        } else if ("userGuid" in approver) {
          return {
            actorType: "USER",
            actorId: approver.userGuid,
            actorName: approver.name,
            index: actorIndex + 1,
          };
        }
        return null;
      }).filter(Boolean),
    }));

    // Set the formatted levels to the form field
    formik.setFieldValue("levels", formattedLevels);
  };




  const addApprovalLevel = () => {
    const lastLevel = approvalLevels[approvalLevels.length - 1];
    if (lastLevel && lastLevel.length > 0) {
      setApprovalLevels([...approvalLevels, []]);
    } else {
      toast.error(
        "Please add at least one approver to the current level before adding a new level"
      );
    }
  };
  const removeApprovalLevel = (levelIndex: number) => {
    console.log(levelIndex);

    const updatedLevels = approvalLevels.filter((_, idx) => idx !== levelIndex);
    setApprovalLevels(updatedLevels);
    formik.setFieldValue(
      "levels",
      updatedLevels?.map((level, idx) => ({
        index: idx + 1,
        actors: level?.map((approver, actorIndex) => {
          if ("roleGuid" in approver && approver.roleGuid !== "") {
            return {
              actorType: "ROLE",
              actorId: approver.roleGuid,
              actorName: approver.name,
              index: actorIndex + 1,
            };
          } else if ("userGuid" in approver) {
            return {
              actorType: "USER",
              actorId: approver.userGuid,
              actorName: approver.name,
              index: actorIndex + 1,
            };
          } else {
            return null;
          }
        }).filter(Boolean),
      }))
    );
  };


  const handleCancel = () => {
    formik.resetForm();
    setAmountRange({ min: 0, max: Infinity });
    setApprovalLevels([[]]);
    setActive(false);
    setEditingRuleIndex(null);
  };

  const handleEditRule = (rule: any, index: number) => {
    setEditingRuleIndex(index);

    const updatedLevels = rule.levels.map((level: any, levelIndex: number) => ({
      ...level,
      actors: level.actors.map((actor: any, actorIndex: number) => ({
        ...actor,
        index: actorIndex + 1, // Add index for each actor
      })),
    }));
    const formattedLevels = rule?.levels?.map((level: any) =>
      level?.actors?.map((actor: any, actorIndex: number) => {
        if (actor.actorType === 'ROLE') {
          return {
            roleGuid: actor.actorId,
            name: actor.actorName,
            actorType: 'ROLE',
            index: actorIndex + 1,
          };
        } else if (actor.actorType === 'USER') {
          return {
            userGuid: actor.actorId,
            name: actor.actorName,
            actorType: 'USER',
            index: actorIndex + 1,
          };
        }
        return null;
      }).filter(Boolean)
    );

    setApprovalLevels(formattedLevels);

    formik.setValues({
      ruleGuid: rule.ruleGuid,
      currency: rule.currency,
      fromAmount: rule.fromAmount,
      toAmount: rule.toAmount,
      levels: updatedLevels,
    });

    setAmountRange({
      min: rule.fromAmount,
      max: rule.toAmount,
    });
  };


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="container mt-4">
            <h4 className="mb-3">Rule Settings</h4>
            {foundRules?.map((rule, index) => (
              <div
                key={index}
                className="card shadows-sm p-10 mb-5"
                style={{ border: "1px solid #ebeef2" }}
              >
                {editingRuleIndex === index ? (
                  <>
                    <div className="d-flex flex-row align-items-center mb-5">
                      <label className="form-label text-nowrap">
                        <i className="bi bi-circle-fill fs-7 text-danger me-5"></i>
                        If amount is greater or equal to&nbsp;
                      </label>
                      <div className="mb-2">
                        <div
                          className="position-relative"
                          style={{ maxWidth: "200px" }}
                        >
                          <input
                            type="text"
                            value={amountRange.min}
                            onChange={(e) => handleAmountChange(e, "min")}
                            placeholder="0.00"
                            className="form-control"
                            style={{ paddingLeft: "60px", height: "33px" }}
                            maxLength={12}
                          />
                          <select
                            name="currency"
                            value={formik.values.currency}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="position-absolute"
                            style={{
                              top: "50%",
                              left: "5px",
                              transform: "translateY(-50%)",
                              border: "none",
                              background: "transparent",
                              color: "#6c757d",
                              height: "33px",
                              outline: "none",
                            }}
                          >
                            <option selected value="ZAR">
                              ZAR
                            </option>
                            <option value="USD">USD</option>
                          </select>
                        </div>
                        {formik.touched.fromAmount &&
                          formik.errors.fromAmount ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.fromAmount}
                          </div>
                        ) : null}
                      </div>

                      <label className="form-label">
                        &nbsp;and less than &nbsp;
                      </label>
                      <div className="mb-2">
                        <div
                          className="position-relative"
                          style={{ maxWidth: "200px" }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "10px",
                              transform: "translateY(-50%)",
                              color: "#6c757d",
                            }}
                          >
                            {formik.values.currency}
                          </span>
                          <input
                            type="text"
                            value={
                              amountRange.max === Infinity
                                ? ""
                                : amountRange.max
                            }
                            onChange={(e) => handleAmountChange(e, "max")}
                            placeholder="No limit"
                            className="form-control"
                            style={{ paddingLeft: "50px", height: "33px" }}
                            maxLength={12}
                          />
                        </div>
                        {formik.touched.toAmount && formik.errors.toAmount ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.toAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {approvalLevels?.map((level, levelIndex) => (
                      <>
                        <div
                          className="form-group  d-flex flex-row align-items-center gap-5 ms-10"
                          key={levelIndex}
                        >
                          <label className="form-label">
                            <i
                              className="bi bi-arrow-return-right me-3 "
                              style={{ WebkitTextStroke: "1px" }}
                            ></i>{" "}
                            {levelIndex === 0 ? "Require" : "Then require"}
                          </label>
                          <div className="d-flex flex-grow-1 align-items-center mt-3">
                            <Select
                              isDisabled={formik.values.toAmount === ""}
                              isMulti
                              options={getAvailableApprovers(levelIndex)}
                              value={approvalLevels[levelIndex]}
                              onChange={(selectedOptions) => {
                                if (selectedOptions.length <= 5) {
                                  handleApproverChange(
                                    levelIndex,
                                    selectedOptions as (RoleData | User)[]
                                  );
                                } else {

                                  toast.error('You can only select up to 5 approvers on each level.', { theme: "colored" });
                                }
                              }}

                              className="basic-multi-select w-100"
                              classNamePrefix="select"
                              placeholder="Select approvers for this level"
                              // getOptionLabel={(option) => option.name}
                              getOptionValue={(option) =>
                                "roleGuid" in option
                                  ? option.roleGuid
                                  : option.userGuid
                              }
                              components={{
                                MultiValue: ({ data, index, innerProps, removeProps }) => (
                                  <>
                                    {index > 0 && <span>&nbsp; or &nbsp;</span>}
                                    <div {...innerProps} className="bg-gray-600 p-1 mt-2 mb-2 ps-2 pe-2 rounded bg-opacity-10">
                                      {data.name}
                                      <span className="fs-4 text-hover-danger ms-2" {...removeProps}>&times;</span>
                                    </div>
                                  </>
                                ),
                              }}
                            />

                            {approvalLevels.length > 1 && (
                              <span
                                className="fs-2x ms-2 mb-2 text-gray-600 cursor-pointer"
                                onClick={() => removeApprovalLevel(levelIndex)}
                              >
                                &times;
                              </span>
                            )}
                          </div>


                        </div>
                        {
                          formik.touched.levels &&
                            formik.errors.levels ? (
                            typeof formik.errors.levels === "string" ? (
                              // Handle case where errors.levels is a string
                              <div className="text-danger">{formik.errors.levels}</div>
                            ) : Array.isArray(formik.errors.levels) &&
                              (formik.errors.levels as LevelError[])[levelIndex]?.actors ? (
                              // Handle case where errors.levels is an array and has specific actor errors
                              <div className="text-danger ms-10">
                                Level {levelIndex + 1}: {(formik.errors.levels as LevelError[])[levelIndex].actors}
                              </div>
                            ) : null
                          ) : null
                        }

                      </>

                    ))}

                    <span
                      className=" mb-4 text-start text-decoration-underline cursor-pointer ms-10"
                      onClick={addApprovalLevel}
                    >
                      Add another approver
                    </span>

                    <div className="form-group ms-10 d-flex flex-end">
                      <button
                        type="button"
                        className="btn btn-secondary me-2 p-2 fs-7"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn  p-2 fs-7">
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="">
                      <div className="d-flex flex-row  justify-content-between">
                        <div>
                          {" "}
                          <label className="form-label text-nowrap">
                            <i
                              className="bi bi-circle-fill fs-7 me-5"
                              style={{
                                color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                              }}
                            ></i>{" "}
                            If amount is greater or equal to&nbsp;
                            {rule?.currency}&nbsp;
                            <span className="fw-bold">{rule?.fromAmount}</span>
                            &nbsp;and less than&nbsp;{rule?.currency}&nbsp;
                            <span className="fw-bold">{rule?.toAmount}</span>
                          </label>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="dropdown">
                            <button
                              className="btn btn-secondary m-0 p-0 d-flex align-items-center justify-content-center"
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                              }}
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="bi bi-three-dots p-0 m-0" onClick={() => {
                                setActive(false);
                                formik.resetForm();
                                setAmountRange({ min: 0, max: Infinity });
                                setApprovalLevels([[]]);
                                setEditingRuleIndex(null);
                              }}></i>
                            </button>

                            <ul
                              className="dropdown-menu dropdown-menu-end p-1"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => handleEditRule(rule, index)}
                                >
                                  <i className="bi bi-pencil me-3"></i>Edit rule
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    Swal.fire({
                                      title:
                                        "Are you sure you want to delete this rule?",
                                      showCancelButton: true,
                                      confirmButtonText: "Yes, delete it!",
                                      cancelButtonText: "Cancel",
                                    }).then(async (result) => {
                                      if (result.isConfirmed) {
                                        await dispatch(
                                          deleteRuleSetting({
                                            ruleGuid: rule?.ruleGuid,
                                          }) as any
                                        ).then((res: any) => {
                                          if (
                                            res?.meta?.requestStatus ===
                                            "fulfilled"
                                          ) {
                                            Swal.fire({
                                              icon: "success",
                                              title: "Rule setting deleted!",
                                            });
                                            dispatch(
                                              findRule({
                                                instanceGuid:
                                                  data?.instanceGuid,
                                              }) as any
                                            );
                                          }
                                        });
                                      }
                                    });
                                  }}
                                >
                                  <i className="bi bi-trash3 me-3"></i>Delete
                                  rule
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5">
                      {rule?.levels?.map((level, index) => (
                        <p className="ms-10" key={index}>
                          <span className="fw-bold">
                            <i
                              className="bi bi-arrow-return-right me-3"
                              style={{ WebkitTextStroke: "1px" }}
                            ></i>
                          </span>
                          {index === 0 ? "Require" : "Then require"}{" "}
                          <span className="fw-bold">
                            {level.actors
                              ?.map((actor) => actor.actorName)
                              .join(" (or) ")}
                          </span>
                        </p>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))}

            <div
              className="card shadow-sm p-10 border-0 mt-5"
              style={{ border: "1px solid #ebeef2" }}
            >
              <div className="form-group mb-4 d-flex flex-column">
                {!active ? (
                  <>
                    <div className="d-flex flex-row justify-content-between w-100">
                      <div>
                        {" "}
                        <i className="bi bi-circle-fill fs-7 text-danger me-5"></i>{" "}
                        Add another payment approval rule
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-secondary p-2 fs-7 m-0 p-0"
                          onClick={() => {
                            setActive(true);
                            formik.resetForm();
                            setAmountRange({ min: 0, max: Infinity });
                            setApprovalLevels([[]]);
                            setEditingRuleIndex(null);
                          }}
                        >
                          {" "}
                          <i
                            className="bi bi-plus-lg"
                            style={{ WebkitTextStroke: "0.5px" }}
                          ></i>
                          Add Rule
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-row align-items-center mb-5">
                      <label className="form-label text-nowrap">
                        <i className="bi bi-circle-fill fs-7 text-danger me-5"></i>
                        If amount is greater or equal to&nbsp;
                      </label>
                      <div className="mb-2">
                        <div
                          className="position-relative"
                          style={{ maxWidth: "200px" }}
                        >
                          <input
                            type="text"
                            value={amountRange.min}
                            onChange={(e) => handleAmountChange(e, "min")}
                            placeholder="0.00"
                            className="form-control"
                            style={{ paddingLeft: "60px", height: "33px" }}
                            maxLength={12}
                          />
                          <select
                            name="currency"
                            value={formik.values.currency}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="position-absolute"
                            style={{
                              top: "50%",
                              left: "5px",
                              transform: "translateY(-50%)",
                              border: "none",
                              background: "transparent",
                              color: "#6c757d",
                              height: "33px",
                              outline: "none",
                            }}
                          >
                            <option selected value="ZAR">
                              ZAR
                            </option>
                            <option value="USD">USD</option>
                          </select>
                        </div>
                        {formik.touched.fromAmount &&
                          formik.errors.fromAmount ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.fromAmount}
                          </div>
                        ) : null}
                      </div>

                      <label className="form-label">
                        &nbsp;and less than &nbsp;
                      </label>
                      <div className="mb-2">
                        <div
                          className="position-relative"
                          style={{ maxWidth: "200px" }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "10px",
                              transform: "translateY(-50%)",
                              color: "#6c757d",
                            }}
                          >
                            {formik.values.currency}
                          </span>
                          <input
                            type="text"
                            value={
                              amountRange.max === Infinity
                                ? ""
                                : amountRange.max
                            }
                            onChange={(e) => handleAmountChange(e, "max")}
                            placeholder="No limit"
                            className="form-control"
                            style={{ paddingLeft: "50px", height: "33px" }}
                            maxLength={12}
                          />
                        </div>
                        {formik.touched.toAmount && formik.errors.toAmount ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.toAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {approvalLevels?.map((level, index) => (
                      <>
                        <div
                          className="form-group d-flex flex-row align-items-center gap-5 ms-10"
                          key={index}
                        >
                          <label className="form-label">
                            <i
                              className="bi bi-arrow-return-right me-3 "
                              style={{ WebkitTextStroke: "1px" }}
                            ></i>{" "}
                            {index === 0 ? "Require" : "Then require"}
                          </label>
                          <div className="d-flex flex-grow-1 align-items-center mt-3">
                            <Select
                              isMulti
                              options={getAvailableApprovers(index)}
                              value={approvalLevels[index]}
                              onChange={(selectedOptions) => {
                                if (selectedOptions.length <= 5) {
                                  handleApproverChange(
                                    index,
                                    selectedOptions as (RoleData | User)[]
                                  );
                                } else {

                                  toast.error('You can only select up to 5 approvers on each level.', { theme: "colored" });
                                }
                              }}
                              className="basic-multi-select w-100"
                              classNamePrefix="select"
                              placeholder="Select approvers for this level"
                              // getOptionLabel={(option) => option.name}
                              getOptionValue={(option) =>
                                "roleGuid" in option ? option.roleGuid : option.userGuid
                              }
                              components={{
                                MultiValue: ({ data, index, innerProps, removeProps }) => (
                                  <>
                                    {index > 0 && <span>&nbsp; or &nbsp;</span>}
                                    <div {...innerProps} className="bg-gray-600 p-1 ps-2 pe-2 mt-2 mb-2 rounded bg-opacity-10">
                                      {data.name}
                                      <span className="fs-4 text-hover-danger ms-2" {...removeProps}>&times;</span>
                                    </div>
                                  </>
                                ),
                              }}
                              isDisabled={formik.values.toAmount === ""}

                            />



                            {approvalLevels.length > 1 && (
                              <span
                                className="fs-2x ms-2 mb-2 text-gray-600 cursor-pointer"
                                onClick={() => removeApprovalLevel(index)}
                              >
                                &times;
                              </span>
                            )}
                          </div>

                        </div>
                        {
                          formik.touched.levels &&
                            formik.errors.levels ? (
                            typeof formik.errors.levels === "string" ? (
                              // Handle case where errors.levels is a string
                              <div className="text-danger">{formik.errors.levels}</div>
                            ) : Array.isArray(formik.errors.levels) &&
                              (formik.errors.levels as LevelError[])[index]?.actors ? (
                              // Handle case where errors.levels is an array and has specific actor errors
                              <div className="text-danger ms-10">
                                Level {index + 1}: {(formik.errors.levels as LevelError[])[index].actors}
                              </div>
                            ) : null
                          ) : null
                        }

                      </>
                    ))}

                    <span
                      className=" mb-4 text-start text-decoration-underline cursor-pointer ms-10"
                      onClick={addApprovalLevel}
                    >
                      Add another approver
                    </span>

                    <div className="form-group ms-10 d-flex flex-end">
                      <button
                        type="button"
                        className="btn btn-secondary me-2 p-2 fs-7 "
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn p-2 fs-7">
                        Save
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default RuleSetting;
