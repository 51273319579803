/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef, FormEvent } from "react";
import { StepProps } from "./AppModel";
import { sameBank, wallet } from "./Data";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { parseCountry, PhoneInput } from "react-international-phone";
import { User } from "../../TransferMoney/TransferModal";
import {
  CountryIso2,
  ParsedCountry,
} from "react-international-phone/build/types";

export const ApiReset = {
  api: (): void => {},
};

export const SteppperModal1 = ({
  data,
  payeeData,
  setPayeeData,
  updateData,
  hasError,
}: StepProps) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const baseCurrency = sessionStorage.getItem("currency");

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const [button, setButton] = useState("Submit");

  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [cus, setCus] = useState("");
  const [show, setShow] = useState(false);
  // const [payeeData, setPayeeData] = useState<PayeeInData[] | null>([]);

  sameBank.payeeFirstName = data?.firstname.firstname || "";
  sameBank.payeeLastName = data?.lastname.lastname || "";
  sameBank.payeeAccountNumber = data?.accNumber.accNumber || "";
  sameBank.currency = data?.currency.currency || "";
  sameBank.payeeAlias = data.nickname.nickname || "";
  sameBank.payeeLimit = data.payeelimit.payeelimit || "";
  sameBank.payeeCategory = data.accType.acctype || "";
  sameBank.payeeType = "BANK";

  wallet.payeeFirstName = data?.walletFirstName.walletfirstname || "";
  wallet.payeeLastName = data?.walletLastName.walletlastname || "";
  wallet.currency = data?.walletCurrency.walletCurrency || "";
  wallet.payeeAlias = data.walletNickName.walletNickName || "";
  wallet.payeeLimit = data.walletPayeeLimit.walletPayeeLimit || "";
  wallet.payeeType = "WALLET";
  wallet.payeeMobile = data.walletPhone.walletPhone;
  wallet.payeeCountry = data.walletCountry.walletCountry || "";

  let payeeType = "";
  if (data.appBasic.appType === "Same Bank") {
    payeeType = "BANK";
  }

  if (data.appBasic.appType === "Wallet") {
    payeeType = "WALLET";
  }

  const DEBOUNCE_DELAY = 300;

  useEffect(() => {
    const shouldUpdate = phone.toString().length > 9 || cus;

    if (shouldUpdate) {
      const handler = setTimeout(() => {
        setShow(true);
        updateData({
          cusId: { cusId: cus },
          phone: { phone: phone },
          country: { country: country }, // Use the first part of the split phone
        });
      }, DEBOUNCE_DELAY);

      // Clear the timeout if the effect re-runs before the delay ends
      return () => clearTimeout(handler);
    }
  }, [phone, cus]);

  const resetPhoneCus = () => {
    setCus("");
    setPhone("");
  };

  ApiReset.api = resetPhoneCus;
  const handleSearchOrSubmit = () => {
    // if (!selectedRadio) {
    //   setRadioError(true);
    //   return;
    // }
    getData();
  };

  const getData = async () => {
    try {
      const url = `${API_URL}/payee/fetchPayeeDetail`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...(cus
          ? { customerId: cus }
          : { payeeCountry: country, payeeMobile: phone }),
        payeeType: payeeType,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.results?.length > 0) {
        // setShow(false)
      }

      setPayeeData(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Clear any auto-filled value when the component mounts
    const input = document.querySelector<HTMLInputElement>(
      'input[name="cusId"]'
    );
    if (input) {
      input.value = "";
    }
  }, []);

  const typing = useRef(false);
  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault(); // Prevent form submission
  };
  return (
    <div className="pb-5 card shadow-sm p-10" data-kt-stepper-element="content">
      <div className="w-100">
        {data.appBasic.appType === "Same Bank" && (
          <>
            <div className="mb-3 d-flex flex-row justify-content-between">
              <div className="form-check form-check-custom form-check-solid form-check-sm">
                <input
                  className="form-check-input"
                  name="accType"
                  type="radio"
                  value="2"
                  id="sameBankRadio"
                  checked={data.accType.acctype === "BUSINESS"}
                  onChange={() =>
                    updateData({
                      accType: {
                        acctype: "BUSINESS",
                      },
                    })
                  }
                />
                <label className="form-check-label" htmlFor="sameBankRadio">
                  Business
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid form-check-sm">
                <input
                  className="form-check-input"
                  name="accType"
                  type="radio"
                  value="1"
                  id="sameBankRadio"
                  checked={data.accType.acctype === "RETAIL"}
                  onChange={() =>
                    updateData({
                      accType: {
                        acctype: "RETAIL",
                      },
                    })
                  }
                />
                <label className="form-check-label" htmlFor="sameBankRadio">
                  Individual
                </label>
              </div>
            </div>
            {!data.accType.acctype && hasError && (
              <div className="fv-plugins-message-container mb-5">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Select Payee Category
                </div>
              </div>
            )}
            <div className="mb-10 mt-10">
              <div className="mb-5">
                <form autoComplete="off" onSubmit={handleSubmit} noValidate>
                  <input
                    type="text"
                    name="cusId"
                    id="cusId-new"
                    className="form-control"
                    placeholder="Customer id"
                    // value={cus}
                    maxLength={8}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    data-form-type="other"
                    aria-autocomplete="none"
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );

                      console.log("change called", numericValue);

                      setPayeeData([]);
                      setCus(numericValue);
                      setPhone("");
                    }}
                    disabled={phone ? true : false}
                  />
                </form>
              </div>
              <div className="d-flex align-items-center mb-5">
                <hr className="flex-grow-1 ms-20" />
                <span className="mx-2">OR</span>
                <hr className="flex-grow-1 me-20" />
              </div>
              <div className="mb-5">
                <PhoneInput
                  inputStyle={{ padding: "21px" }}
                  countrySelectorStyleProps={{
                    buttonStyle: {
                      padding: "21px 5px",
                    },
                  }}
                  charAfterDialCode={" "}
                  // disableFormatting={true}
                  forceDialCode={true}
                  inputClassName="form-control"
                  defaultCountry="in"
                  onChange={(phone: string, country: CountryIso2) => {
                    // Extract the phone number without the country code, remove dashes, and join the parts
                    const phoneWithoutCountryCode =
                      phone.split(" ")?.[1]?.split("-").join("") || "";

                    // Set country as the first part of the phone split by space
                    const extractedCountryCode =
                      phone.split(" ")?.[0]?.replace("+", "") || "";

                    // Set your data based on the extracted values
                    setPayeeData([]);
                    setCountry(extractedCountryCode);
                    setPhone(phoneWithoutCountryCode);
                    setCus("");
                  }}
                  disableDialCodePrefill={false}
                  disabled={cus ? true : false}
                />
              </div>
              {show && (data?.phone?.phone?.length >= 9 || cus?.length > 2) && (
                <div className="d-flex justify-content-between mt-2">
                  <div></div>
                  <div
                    className="btn  p-2"
                    onClick={() => {
                      // setSearch(true);
                      handleSearchOrSubmit();
                    }}
                  >
                    Search
                  </div>
                </div>
              )}

              {/* {!data.phone.phone && hasError && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="appname"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Phone Or Account Number is Required
                  </div>
                </div>
              )} */}
              {payeeData && payeeData.length > 0 && (
                <>
                  <div className="fw-bold mb-2">
                    Account Holder:{"  "}
                    {payeeData[0].payeeFirstName}
                  </div>
                  <div>User's Accounts</div>
                  {payeeData
                    ?.filter((payee) => payee?.currency === baseCurrency)
                    ?.map((item, index) => (
                      <div className="mb-5" key={index}>
                        <div className="card d-flex flex-row py-5">
                          <div className="form-check form-check-custom form-check-solid me-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              value={index}
                              id={`radio-${index}`}
                              name="selectedPayee"
                              onChange={() => {
                                updateData({
                                  firstname: {
                                    firstname: item.payeeFirstName,
                                  },
                                  lastname: {
                                    lastname: item.payeeLastName,
                                  },
                                  accNumber: {
                                    accNumber: item.payeeAccountNumber,
                                  },
                                  currency: {
                                    currency: item.currency,
                                  },
                                });
                              }}
                            />
                          </div>

                          <div className="d-flex gap-10 justify-content-around">
                            <div className="fw-bold">
                              {" "}
                              {item.payeeAccountNumber}
                            </div>
                            <div className="fw-bold"> ({item.currency})</div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
              {!data.firstname.firstname && hasError && !phone && !cus && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="appname"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Please enter Customer ID/Phone number to search
                  </div>
                </div>
              )}
              {payeeData &&
                !data.firstname.firstname &&
                hasError &&
                payeeData?.length > 0 && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Please select an account
                    </div>
                  </div>
                )}
              {/* {phone && phone.length! < 10 && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="appname"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Phone Number should 10 digits
                  </div>
                </div>
              )} */}
            </div>
          </>
        )}
        {data.appBasic.appType === "Different Bank" && (
          <>
            <div>
              <div className="mb-7 d-flex flex-row justify-content-between">
                <div className="form-check form-check-custom form-check-solid form-check-sm">
                  <input
                    className="form-check-input"
                    name="diffaccType"
                    type="radio"
                    value="2"
                    id="diffBankRadio"
                    checked={data.diffAccType.diffaccType === "BUSINESS"}
                    onChange={() =>
                      updateData({
                        diffAccType: {
                          diffaccType: "BUSINESS",
                        },
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="diffBankRadio">
                    Business
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid form-check-sm">
                  <input
                    className="form-check-input"
                    name="diffaccType"
                    type="radio"
                    value="1"
                    id="diffBankRadio"
                    checked={data.diffAccType.diffaccType === "RETAIL"}
                    onChange={() =>
                      updateData({
                        diffAccType: {
                          diffaccType: "RETAIL",
                        },
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="diffBankRadio">
                    Individual
                  </label>
                </div>
              </div>
              {!data.diffAccType.diffaccType && hasError && (
                <div className="d-flex fv-plugins-message-container">
                  <div
                    data-field="appname"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Select Payee Category
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {data.appBasic.appType === "International" && (
          <>
            <div className="mb-3 d-flex flex-row justify-content-between">
              <div className="form-check form-check-custom form-check-solid form-check-sm">
                <input
                  className="form-check-input"
                  name="walletaccType"
                  type="radio"
                  value="2"
                  id="walletRadio"
                  checked={data.intAccType.intaccType === "BUSINESS"}
                  onChange={() =>
                    updateData({
                      intAccType: {
                        intaccType: "BUSINESS",
                      },
                    })
                  }
                  disabled={true}
                />
                <label
                  className="form-check-label text-dark"
                  htmlFor="walletRadio"
                >
                  Business
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid form-check-sm">
                <input
                  className="form-check-input"
                  name="walletaccType"
                  type="radio"
                  value="1"
                  id="walletRadio"
                  checked={data.intAccType.intaccType === "RETAIL"}
                  onChange={() =>
                    updateData({
                      intAccType: {
                        intaccType: "RETAIL",
                      },
                    })
                  }
                />
                <label
                  className="form-check-label text-dark"
                  htmlFor="walletRadio"
                >
                  Individual
                </label>
              </div>
            </div>
            {!data.intAccType.intaccType && hasError && (
              <div className="fv-plugins-message-container mb-5">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Select Payee Category
                </div>
              </div>
            )}
          </>
        )}

        {data.appBasic.appType === "Wallet" && (
          <>
            <div className="mb-10">
              <div className="d-flex flex-row justify-content-between">
                <div className="form-check form-check-custom form-check-solid form-check-sm">
                  <input
                    className="form-check-input"
                    name="walletaccType"
                    type="radio"
                    value="2"
                    id="walletRadio"
                    checked={data.walletAccType.walletaccType === "RETAIL"}
                    onChange={() =>
                      updateData({
                        walletAccType: {
                          walletaccType: "BUSINESS",
                        },
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="walletRadio">
                    Business
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid form-check-sm">
                  <input
                    className="form-check-input"
                    name="walletaccType"
                    type="radio"
                    value="1"
                    id="walletRadio"
                    checked={data.walletAccType.walletaccType === "RETAIL"}
                    onChange={() =>
                      updateData({
                        walletAccType: {
                          walletaccType: "RETAIL",
                        },
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="walletRadio">
                    Individual
                  </label>
                </div>
              </div>
              {!data.walletAccType.walletaccType && hasError && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="appname"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Select Payee Category
                  </div>
                </div>
              )}
            </div>
            <div className="mb-10">
              <PhoneInput
                // value={
                //   (companyDetails?.isdCode || "") +
                //   (companyDetails?.phoneNumber || "")
                // }
                inputStyle={{ padding: "21px" }}
                countrySelectorStyleProps={{
                  buttonStyle: {
                    padding: "21px 5px",
                  },
                }}
                forceDialCode={true}
                inputClassName="form-control"
                defaultCountry="in"
                onChange={(phone) => {
                  console.log(phone);

                  // Extracting country code and phone number
                  const countryCodeRegex = /^\+(\d{1,3})\s?/; // Updated regex to properly capture the country code without the space
                  const phoneWithoutCountryCode = phone
                    .replace(countryCodeRegex, "")
                    .replace(/[\s+\-]/g, ""); // Remove the country code and non-digit characters from the phone number
                  const extractedCountryCode =
                    (phone.match(countryCodeRegex) || [])[1] || ""; // Extract the country code from the phone number

                  // Calling updateData with separated data
                  updateData({
                    walletPhone: {
                      walletPhone: phoneWithoutCountryCode,
                    },
                    walletCountry: {
                      walletCountry: extractedCountryCode,
                    },
                  });
                  setCountry(extractedCountryCode);
                  setPhone(phoneWithoutCountryCode);
                }}
                disableDialCodePrefill={false}
              />
              {/* <input
                type="text"
                className="form-control"
                placeholder="Enter Phone number"
                value={data.walletPhone.walletPhone}
                onChange={(e) => {
                  updateData({
                    walletPhone: {
                      walletPhone: e.target.value,
                    },
                  });
                  setPhone(e.target.value);
                }}
              /> */}
              {show && data?.walletPhone?.walletPhone?.length >= 9 && (
                <div className="d-flex justify-content-between mt-2">
                  <div></div>
                  <div
                    className="btn  p-2"
                    onClick={() => {
                      // setSearch(true);
                      handleSearchOrSubmit();
                    }}
                  >
                    Search
                  </div>
                </div>
              )}

              {!data.walletPhone.walletPhone && hasError && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="appname"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Phone Or Account Number is Required
                  </div>
                </div>
              )}
              {payeeData && payeeData.length > 0 && (
                <>
                  <div className="fw-bold mb-2">
                    Account Holder:{"  "}
                    {payeeData[0].payeeFirstName}
                  </div>
                  <div>User's Accounts</div>
                  {payeeData?.map((item, index) => (
                    <div className="mb-5" key={index}>
                      <div className="card d-flex flex-row py-5">
                        <div className="form-check form-check-custom form-check-solid me-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={index}
                            id={`radio-${index}`}
                            name="selectedPayee"
                            onChange={() => {
                              updateData({
                                walletFirstName: {
                                  walletfirstname: item.payeeFirstName,
                                },
                                walletLastName: {
                                  walletlastname: item.payeeLastName,
                                },
                                walletCurrency: {
                                  walletCurrency: item.currency,
                                },
                              });
                            }}
                          />
                        </div>
                        <div className="d-flex gap-10 justify-content-around">
                          <div className="fw-bold">
                            {" "}
                            {item.payeeAccountNumber}
                          </div>
                          <div className="fw-bold"> ({item.currency})</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
