/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { Statistics } from "../components/Statistics";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "bootstrap";
import { TransferModal } from "./AddMoney";

import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { Api } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuMain";
import { CurrencyConversion } from "./CurrencyConversion";
import Authorize from "../../modules/auth/components/Authorize";
import { ApproverTxn } from "../TeamBanking/Approvals";
import { DrawerComponent } from "../../../_metronic/assets/ts/components";
import { MutatingDots } from "react-loader-spinner";
import Loader from "../components/Loader";

interface Transaction {
  amount: number;
  txnId: string;
  currency: string;
  txnTypeName: string;
  dateTime: string;
  txnType: string;
  txnStatus: Number;
}

interface Accounts {
  accountNumber: string;
  accountType: string;
  balance: number;
  currency: string;
  functionCode: string;
  primaryFundId: string;
  primaryRefId: string;
  primaryRefType: number;
  tenantId: string;
  transactions: [];
  totalCredit: number;
  totalDebit: number;
}

export interface menu {
  menuCode: string;
  menuName: string;
  menuIcon: string;
  menuIndex: number;
  categoryIndex: number;
  menuIconLink: string;
  isDashboardIcon: boolean;
}

export interface Menus {
  menuCategory: string;
  catIconLink: string;
  categoryIndex: number;
  menus: menu[];
}

interface accDetails {
  currency: string;
  iconCode: string;
  goal: string;
  goalLimit: number;
  creditCondition: string;
  creditType: string;
  creditValue: number;
  creditFrequency: string;
  subAccountType: string;
  subAccountName: string;
  subAccountNumber: string;
  subAccountStatus: number;
  balance: number;
  createdDate: string;
}

type MenuItem = {
  path: string;
};

type UrlType = {
  [key: string]: MenuItem;
};

export const Url: UrlType = {
  MENU_CARDS: { path: "/my-card" },
  MENU_BUSINESS: { path: "/invoice/customers" },
  MENU_VL: { path: "/helpdesk/video-library" },
  MENU_SERVICEREQUEST: { path: "/helpdesk/service-ticket" },
  MENU_STATISTICS: { path: "/analytics" },
  MENU_TEAM_MEM: { path: "/team-banking/team-listing" },
  MENU_PAYROLL_DASHBOARD: {
    path: "/payrollemp/payroll-dashboard",
  },
  MENU_PAYROLL_PAY_SALARY: {
    path: "payroll-paysalary",
  },
  MENU_TEAM_ROLE: { path: "/team-banking/roles" },
  MENU_LOAN: { path: "/loan" },
  MENU_TEAM_APPVORALS: {
    path: "/team-banking/approvals",
  },
  MENU_QR_CODE: { path: "/qr/qr-dashboard" },
  MENU_TRF: { path: "/money-movement/transfer-money" },
  MENU_IT: { path: "#" },
  MENU_INVENTRY: { path: "/invoice/inventory" },
  MENU_ALL_TXN: {
    path: "/money-movement/transaction-dashboard",
  },
  MENU_LIVCHT: { path: "#" },
  MENU_TEAM_INITIATION: {
    path: "/team-banking/initiations",
  },
  MENU_INVOICE: { path: "/invoice/invoice-dashboard" },
  MENU_PAY_LINK: {
    path: "/payment-link/payment-dashboard",
  },
  MENU_VAT: { path: "#" },
  MENU_STMNT: { path: "/statements" },
  MENU_PAYEE: { path: "/money-movement/payee-management" },
  MENU_SECURITY: { path: "/security" },
  MENU_FAQ: { path: "/helpdesk/faq" },
  MENU_SUB_ACCOUNTS: { path: "/subaccount-listing" },
  MENU_SUBSCRIPTION: { path: "/subscription" },
  MENU_BILLPAY: { path: "/paybill" },
  MENU_BULK_PAYMENT: {
    path: "/bulk-payment",
  },
  MENU_COMM_CENTER: {
    path: "/communication-center",
  },
  MENU_CURR_CONV: {
    path: "#",
  },
  MENU_CARDLESS_WITHDRAWAL: {
    path: "/money-movement/cardless-withdrawal",
  },
  MENU_QUOTATIONS: {
    path: "/invoice/quotations",
  },
  MENU_ACCOUNT_PROFILE: {
    path: "profile/details",
  },
  MENU_ACCOUNT_DETAILS: {
    path: "profile/accountDetails",
  },
  MENU_VENDOR: {
    path: "/invoice/vendorList",
  },
  MENU_DOMESTIC_VENDOR_PAY: {
    path: "/invoice/domesticVendor",
  },
  MENU_INTL_VENDOR_PAY: {
    path: "/invoice/internationalVendor",
  },
  MENU_RULE_SETTINGS: {
    path: "/ruleSettings/Rule",
  },
};

export const apiCall = {
  submit: () => {
    // Empty function by default
  },
};

const DashboardPage: FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [currency, setCurrency] = useState("");
  const [menu, setMenu] = useState<Menus[]>([]);
  const [quickLinks, setQuickLinks] = useState<menu[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [account, setAccount] = useState("");
  const [loading, setLoading] = useState(false);

  const [stmntDuration, setStmntDuration] = useState("1");
  const [AccSub, setAccSub] = useState<Accounts>(accounts[0]);
  const [clicked, setClicked] = useState(false);
  const [subAccounts, setSubAccounts] = useState<accDetails[]>([]);
  const [approvalTxns, setApprovalTxns] = useState<ApproverTxn[]>([]);

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const navigate = useNavigate();

  useEffect(() => {
    // Early return if menu is not available
    if (!menu?.length) return;

    const dashboardIconItems = menu.flatMap(
      (menuItems) =>
        menuItems?.menus?.filter((item) => item.isDashboardIcon) || []
    );

    if (dashboardIconItems.length) {
      setQuickLinks(dashboardIconItems);
    }
  }, [menu]);

  useEffect(() => {
    DrawerComponent.reinitialization();
  }, [quickLinks]);

  const handleDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStmntDuration(e.target.value);
  };

  const handleSelect = (selectedIndex: number) => {
    setSelectedIndex(selectedIndex);
    setAccount(accounts[selectedIndex]?.accountNumber);
    setCurrency(accounts[selectedIndex]?.currency);
    setTransactions(accounts[selectedIndex]?.transactions);
  };

  useEffect(() => {
    setTransactions(accounts[selectedIndex]?.transactions);
    setAccount(accounts[selectedIndex]?.accountNumber);
    setCurrency(accounts[selectedIndex]?.currency);
    setAccSub(accounts[selectedIndex]);

    // setLifetime({accounts[selectedIndex].totalDebit})
  }, [handleSelect]);

  useEffect(() => {
    setLoading(true);
  }, []);

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const tooltipAddMoney = <Tooltip id="tooltip-add-money">Add Money</Tooltip>;
  const tooltipCurConv = (
    <Tooltip id="tooltip-arrow-right">Currency Conversion</Tooltip>
  );
  const tooltipCount = (
    <Tooltip id="tooltip-count">Sub Account: {subAccounts?.length}</Tooltip>
  );

  const handleTransfer = () => {
    const modalElement = document.getElementById("transfer_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const Dashboard = async () => {
    try {
      const url = `${API_URL}/dashboard/getDashboard`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      sessionStorage.setItem(
        "currency",
        data?.message?.primaryCurrency.toString()
      );
      setAccounts(data?.message.accontList);
      setAccSub(data?.message.accontList[0]);
      setMenu(data?.message.menuCategoryList);
      sessionStorage?.setItem("profile", data?.message?.userProfileUrl);

      if (Api.setMenus) {
        Api?.setMenus(data?.message.menuCategoryList);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getApproverTxns = async () => {
    try {
      const url = `${API_URL}/team/getApproverTxns`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        searchCriteriaList: [
          {
            propertyName: "currency",
            propertyValue: "ZAR",
          },
        ],
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovalTxns(data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Dashboard();
    getApproverTxns();
  }, []);

  apiCall.submit = Dashboard;

  const SubAccounts = async () => {
    try {
      const url = `${API_URL}/subAccount/getSubAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: AccSub?.accountNumber,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setSubAccounts(data?.message?.subAccounts);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (AccSub?.accountNumber) {
      SubAccounts();
    }
  }, [AccSub]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <style>
            {`
    #shadow-hover {
      // transition: box-shadow 0.3s;
      box-shadow: 4px 4px 10px #d1d1d1;
    }

    #shadow-hover:hover {
      box-shadow: 2px 2px 5px #71FF99;
    }
//     .carousel-control-prev-icon,

// .carousel-indicators {
//   display: none !important;
// }
.black-icon {
  color: black;
}
.btn:hover{
backgroundColor:#ffff
color:"red"
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
  background-size: 20px 20px;
}
  `}
          </style>

          <div
            className={`d-flex gap-5 ${
              window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
            }`}
          >
            <div className="card  d-flex flex-column flex-grow-1">
              <div
                className={`d-flex justify-content-between gap-5 flex-nowrap
              } `}
                style={{
                  paddingTop: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <Carousel
                  activeIndex={selectedIndex}
                  onSelect={handleSelect}
                  indicators={false}
                  style={{
                    width: window.innerWidth < 11 * 96 ? "100%" : "800px",
                  }}
                  interval={null}
                  className="card shadow-sm text-center bg-secondary"
                >
                  {accounts.map((account, index) => (
                    <Carousel.Item key={index}>
                      <div className=" d-flex flex-grow-1 ">
                        <div
                          className="d-flex flex-grow-1 p-10 ms-10 "
                          // style={{ marginLeft: "20px" }}
                        >
                          <div className="d-flex flex-column gap-5 ">
                            <div>
                              <p
                                className="p-0 m-0 text-dark mb-5 text-start"
                                style={{ fontWeight: "bold" }}
                              >
                                A/C:{" "}
                                <span
                                  className="text-dark-200"
                                  style={{ fontWeight: "normal" }}
                                >
                                  {account.accountNumber}
                                </span>
                              </p>
                              <h4
                                className="p-0 m-0 text-dark mb-2"
                                style={{ fontSize: "35px" }}
                              >
                                <span
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {account.currency}{" "}
                                </span>
                                {/* {account.balance.toString().split(".")[0]} */}

                                {account.balance
                                  .toString()
                                  .split(".")[0]
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                <span
                                  style={{
                                    fontSize: "15px",
                                    position: "absolute",
                                    fontWeight: "normal",
                                  }}
                                >
                                  .
                                  {(
                                    (account.balance.toString().split(".")[1] ||
                                      "00") + "00"
                                  ).slice(0, 2)}
                                </span>
                              </h4>
                            </div>
                            {account?.accountNumber.startsWith(
                              "1010"
                            ) ? null : (
                              <div className="d-flex gap-5">
                                <Authorize hasAnyPermission={["MENU_TRF|ADD"]}>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipAddMoney}
                                  >
                                    <button
                                      className=" bg-dark bg-hover-light text-hover-dark"
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        outline: "none",
                                        borderRadius: "5px",
                                        border: "0px",
                                      }}
                                      onClick={() => {
                                        handleTransfer();
                                        setClicked(true);
                                      }}
                                    >
                                      <span className="svg-icon svg-icon-1">
                                        <i className="bi bi-plus-lg text-light text-hover-light"></i>
                                      </span>
                                    </button>
                                  </OverlayTrigger>
                                </Authorize>
                                <Authorize
                                  hasAnyPermission={["MENU_SUB_ACCOUNTS|VIEW"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipCount}
                                  >
                                    <button
                                      className="bg-dark bg-hover-light text-hover-dark"
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        outline: "none",
                                        borderRadius: "5px",
                                        border: "0px",
                                      }}
                                      onClick={() => {
                                        navigate("/subaccount-listing", {
                                          state: { data: account },
                                        });
                                      }}
                                    >
                                      <span className="svg-icon svg-icon-1 ">
                                        <i className="bi bi-bank2 text-light text-hover-light"></i>
                                      </span>
                                    </button>
                                  </OverlayTrigger>
                                </Authorize>
                                <Authorize
                                  hasAnyPermission={["MENU_CURR_CONV|ADD"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipCurConv}
                                  >
                                    <button
                                      className=" bg-dark bg-hover-light text-hover-dark"
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        outline: "none",
                                        borderRadius: "5px",
                                        border: "0px",
                                      }}
                                      id="kt_modal_toggle_conversion"
                                    >
                                      <span className="svg-icon svg-icon-1">
                                        <i className="bi bi-arrow-repeat text-light text-hover-light"></i>
                                      </span>
                                    </button>
                                  </OverlayTrigger>
                                </Authorize>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>

                <CurrencyConversion Data={AccSub} />

                <div
                  className="card shadow-sm d-flex  flex-grow-1 "
                  style={{ width: "100%" }}
                >
                  <div className="card-body p-4 flex-grow-1 p-0 m-0">
                    <div className="d-flex justify-content-between mb-3 flex-grow-1">
                      <h4 className="p-0 m-0">Account Life Time</h4>
                    </div>

                    <div className="card shadow-sm flex-grow-1 ">
                      <div
                        className="card-body flex-grow-1 p-3"
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                      >
                        <div className="d-flex flex-row  justify-content-between">
                          <div className="d-flex flex-grow-1">
                            <div>
                              <h4 className="flex-grow-1 text-gray-800">
                                Total In
                              </h4>
                              {/* <p className='text-nowrap'>Monthly Income 10%</p> */}
                            </div>
                          </div>
                          <div>
                            <h4 className="text-gray-800">
                              {" "}
                              {currency}{" "}
                              {accounts[
                                selectedIndex
                              ]?.totalCredit?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </h4>
                          </div>
                        </div>

                        <div className="progress mt-2 mb-2">
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{
                              width: "100%",
                            }}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="card shadow-sm mt-5">
                      <div
                        className="card-body p-3"
                        style={{ marginRight: "10px", marginLeft: "10px" }}
                      >
                        <div className="d-flex flex-row gap-10 justify-content-between">
                          <div className="d-flex  gap-5">
                            {/* <div className=' d-flex align-items-center p-3' style={{ height: "35px", borderRadius: "10px", border: "1px solid grey", marginTop: "5px" }} ><i className="bi bi-currency-dollar"></i></div> */}
                            <div>
                              <h4 className="flex-grow-1 text-gray-800">
                                {" "}
                                Total Out
                              </h4>
                              {/* <p>Monthly Income 5%</p> */}
                            </div>
                          </div>
                          <div>
                            <h4 className="text-gray-800">
                              {currency}{" "}
                              {accounts[
                                selectedIndex
                              ]?.totalDebit.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </h4>
                          </div>
                        </div>

                        <div className="progress mt-2 mb-2">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{
                              width: `${
                                (accounts[selectedIndex]?.totalDebit /
                                  accounts[selectedIndex]?.totalCredit) *
                                100
                              }%`,
                            }}
                            aria-valuenow={accounts[selectedIndex]?.totalCredit}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-5">
                {/* <div className="card shadow-sm d-flex flex-grow-1 mt-5 ">
              <div className='p-5'>
                <div className='' >
                  <LineChart />
                </div>

              </div>

            </div> */}
                <div className="d-flex gap-2 flex-grow-1 flex-nowrap"></div>
                <div>
                  <br />
                  <div className="card shadow">
                    <div className=" d-flex flex-row justify-content-between p-5">
                      <h5 className="m-0 p-0">Recent Transactions</h5>
                      {/* <Link to="/money-movement/dashboard" className="link-primary"> */}
                      <p
                        className=" m-0 cursor-pointer"
                        onClick={() => {
                          navigate("/money-movement/transaction-dashboard", {
                            state: { accountDetails: accounts[selectedIndex] },
                          });
                        }}
                        style={{ color: "#0000FF" }}
                      >
                        View more
                      </p>
                      {/* </Link> */}
                    </div>
                    <div className="p-5 d-flex flex-column gap-4">
                      {transactions?.slice(0, 10).map((transaction, index) => (
                        <div
                          className="card shadow p-0 d-flex flex-column gap-3 "
                          key={index}
                        >
                          <div className="card-body p-5 ">
                            <div className="d-flex justify-content-between">
                            <h5 className="card-title text-truncate" style={{maxWidth:"400px"}}>
                                {transaction.txnTypeName}
                              </h5>
                              <h6
                                className={`card-text `}
                                style={{
                                  color:
                                    transaction.txnType === "C"
                                      ? "#0000ff"
                                      : "#FF0000",
                                }}
                              >
                                {transaction.currency}{" "}
                                {transaction.amount.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </h6>
                            </div>
                            <div className="d-flex justify-content-between p-0">
                              <div className="d-flex p-0 gap-3">
                                {/* <p className="text-muted p-0 m-0" style={{ fontSize: '12px' }}>
                              {new Date(transaction.dateTime).toLocaleDateString()}
                            </p>
                            <span className="text-muted">&nbsp;&#124;&nbsp;</span>
                            <p className="text-muted p-0 m-0" style={{ fontSize: '12px' }}>
                              {new Date(transaction.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                            </p> */}
                                <p
                                  className="text-muted p-0 m-0"
                                  style={{ fontSize: "12px" }}
                                >
                                  {transaction.dateTime}
                                </p>
                                |
                                <p
                                  className={`m-0 text-${
                                    transaction?.txnStatus === 1
                                      ? "success"
                                      : "danger"
                                  }`}
                                  style={{ fontSize: "12px" }}
                                >
                                  {transaction?.txnStatus === 1
                                    ? "Success"
                                    : "Failed"}
                                </p>
                              </div>
                              <div className="d-flex gap-3">
                                <div>
                                  <i
                                    className={`bi ${
                                      transaction.txnType === "D"
                                        ? "bi-arrow-up-square "
                                        : "bi-arrow-down-square "
                                    }`}
                                    style={{
                                      color:
                                        transaction.txnType === "C"
                                          ? "#0000ff"
                                          : "#FF0000",
                                    }}
                                  ></i>
                                </div>

                                <p
                                  className={`card-text`}
                                  // style={{  }}
                                  style={{
                                    fontSize: "12px",
                                    color:
                                      transaction.txnType === "C"
                                        ? "#0000ff"
                                        : "#FF0000",
                                  }}
                                >
                                  {transaction.txnType === "D"
                                    ? "Debit"
                                    : "Credit"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <br />
                  <Authorize hasAnyPermission={["MENU_STATISTICS|VIEW"]}>
                    <div className="card shadow-sm mt-5 d-flex flex-grow-1  ">
                      <div className="card-body p-5 d-flex flex-column">
                        <div className="d-flex justify-content-between mb-5 p-0 m-0">
                          <div className="d-flex p-0 m-0">
                            <h4 className="p-0 m-0">Recent History</h4>
                          </div>
                          <div className="">
                            <select
                              className="shadow d-flex flex-row  form-select cursor-pointer "
                              data-control="select2"
                              style={{
                                outline: "none",
                                border: "none",
                                borderRadius: "5px",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                              }}
                              onChange={(e) => handleDateRangeChange(e)}
                            >
                              <option value="1" onSelect={() => {}}>
                                This Week
                              </option>
                              <option value="2" onSelect={() => {}}>
                                Last Week
                              </option>
                              <option value="3" onSelect={() => {}}>
                                Last 3 Months
                              </option>
                              <option value="4" onSelect={() => {}}>
                                Last 6 Months
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="card shadow-sm d-flex flex-grow-1  ">
                          <div className="p-10">
                            <div className="">
                              <Statistics
                                account={account}
                                currency={currency}
                                stmntDuration={stmntDuration}
                              />
                            </div>
                          </div>
                        </div>

                        {/* </div> */}
                      </div>
                    </div>
                  </Authorize>
                </div>
              </div>
            </div>
            <div
              className="card shadow-sm d-flex flex-grow-1"
              style={{ paddingTop: "15px", minWidth: "300px" }}
            >
              <div className="">
                <div className="card shadow p-5 mt-4 ms-4 me-4">
                  <div className="d-flex flex-column ">
                    <div>
                      {" "}
                      <h5 className="p-0 " style={{ color: "#0000FF" }}>
                        Quick Links
                      </h5>
                    </div>
                    {quickLinks?.map((icon, index) => (
                      <div
                        className="d-flex flex-row justify-content-between w-100 gap-5 p-2"
                        style={{ width: "16.666%" }}
                        key={index}
                      >
                        <div className="d-flex flex-row gap-5">
                          <Link to={Url[icon.menuCode]?.path || ""}>
                            <div
                              // className="btn btn-icon btn-light card"
                              className="d-flex"
                              // id="shadow-hover"
                              style={
                                {
                                  // backgroundColor: QuickLinksData?.[index]?.color,
                                }
                              }
                            >
                              <img
                                src={icon?.menuIcon}
                                alt=""
                                style={{ height: "18px", width: "18px" }}
                              />
                            </div>
                          </Link>
                          <label
                            htmlFor=""
                            className="text-nowrap "
                            style={{ fontSize: "13px" }}
                          >
                            <Link
                              className="text-dark"
                              to={Url[icon.menuCode]?.path || ""}
                            >
                              {icon?.menuName}
                            </Link>{" "}
                            {icon?.menuName === "Approvals" ? (
                              approvalTxns?.filter((app) => app.status === 1)
                                .length > 0 ? (
                                <span
                                  className="bullet bullet-dot bg-danger h-6px w-6px"
                                  style={{
                                    marginBottom: "1px",
                                  }}
                                />
                              ) : null
                            ) : null}
                          </label>
                        </div>
                        <div>
                          <Link
                            className="text-dark"
                            to={Url[icon.menuCode]?.path || ""}
                          >
                            <div>
                              <i className="bi bi-arrow-right-short fs-5"></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <h5 className="mt-8 mb-8 ms-5">Announcement</h5>
            <div className="p-0  d-flex flex-column  gap-10">
              <div className=" d-flex justify-content-center align-items-center flex-grow-1 gap-10">
                <img
                  src={Service}
                  alt=""
                  style={{ maxWidth: "300px", maxHeight: "220px" }}
                />
              </div>
              <div className=" d-flex justify-content-center align-items-center flex-grow-1">
                <img
                  src={Offer}
                  alt=""
                  style={{ maxWidth: "300px", maxHeight: "220px" }}
                />
              </div>
            </div> */}
              </div>
            </div>
          </div>
          <TransferModal AccData={AccSub} clicked={clicked} />
        </>
      )}
    </div>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export default DashboardWrapper;
